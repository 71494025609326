import { useParams } from 'react-router-dom';
import {PageTitle, LoadingSK, NotFound} from '../../components/utility/utility';
import { Link } from 'react-router-dom';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import Form from '../../components/form/form';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import { jwtDecode } from "jwt-decode";

export default function Content(props) { 
const {gid, title, id, menuid} = useParams();
const details = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:String(gid), arg2: parseInt(id) , arg3:'getcontent'}, fetchPolicy: "cache-and-network" }); 
let content = <NotFound />;
//let abuse = '';
let curUser = null;
if(sessionStorage.getItem("signIn")){
curUser = jwtDecode(sessionStorage.getItem("signIn"));}

if(details.error){content = <section className="alert alert-warning offline" role="alert">Connection Error! {details.error.message}</section>;} 
if(details.loading){content = <section className='mt-3 mb-4 defMrg'><LoadingSK /></section>;} 
if(details.data){
if(details.data.getSiteList.length > 0){
content = <section className='mt-3 mb-4 defMrg'>
<section dangerouslySetInnerHTML={{__html: details.data.getSiteList[0].string3}}></section></section>;} 
}


let teaser = '';
if(parseInt(id) === 140){teaser = (<Card className='mb-5'>
<CardActionArea>
<CardContent>
<Typography gutterBottom variant="h5" component="div" className='fw-bold'>Need assistance with our products or have questions before making a purchase?</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Our Sales Support team is here to help!</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}> Fill out the form, and we’ll provide you with the information and guidance you need to make the best choice</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We look forward to assisting you!</Typography>

</CardContent>
</CardActionArea>
</Card>);}



if(parseInt(id) === 136){teaser = (<Card className='mb-5'>
<CardActionArea>
<CardContent>
<Typography gutterBottom variant="h5" component="div" className='fw-bold'>Have questions, feedback, or need assistance? </Typography>
<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We’re here to help! Fill out the form, and a member of our team will get back to you shortly.
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Whether you're inquiring about our services, need support, or just want to connect
</Typography>

<Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>We look forward to hearing from you!
</Typography>

</CardContent>
</CardActionArea>
</Card>);}

    
if(parseInt(id) === 139){teaser = (<Card className='mb-5'>
    <CardActionArea>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div" className='fw-bold'>Feedback & Share Your Ideas </Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Your insights and ideas help us improve! We’d love to hear your feedback on our products and services, as well as any suggestions you have for new features or improvements.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Fill out the form to share your thoughts, and together, we can make our offerings even better.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Thank you for helping us grow!
    </Typography>
    
    </CardContent>
    </CardActionArea>
    </Card>);}
    
    
    
    if(parseInt(id) === 138){teaser = (<Card className='mb-5'>
    <CardActionArea>
    <CardContent>
    <Typography gutterBottom variant="h5" component="div" className='fw-bold'>Help us maintain a safe and respectful environment</Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}> If you've encountered inappropriate behavior, content, or misuse of our platform, please fill out the form to report it.
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Our team will review your submission and take appropriate action to address the issue promptly. 
    </Typography>
    
    <Typography variant="body2" sx={{ color: 'text.secondary', marginBottom:2, }}>Thank you for helping us protect our system
    </Typography>
    
    </CardContent>
    </CardActionArea>
    </Card>);}
    

let processContentld = [136, 138, 139, 140]; // support
if(processContentld.includes(parseInt(id))){ 
content = (
<section className='mt-3 mb-4 defMrg'>
<section>{teaser}</section>
<section className='m-5'>
{curUser && curUser.email ? <Form fullname={curUser && curUser.agentName ? curUser.agentName : ''} type="form" viewid={138} tbl={95} formmode={1} address={curUser && curUser.email ? curUser.email : ''} /> : <section className='mx-5'><Typography>Please <Link to={{ pathname: "/acct/109/Login"}} className='btnLinkBlack mx-2'>Login</Link> to continue or send us email at <a href="mailto:info@dredge.com.ng">info@dredge.com.ng</a></Typography>
</section>}
</section>
</section>);
}

return (
<section className='mt-3 mb-4 defMrg'>
<section><NotFound /></section>
</section>);}
