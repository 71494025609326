/* eslint-disable */ 
import React, { useState, useContext } from 'react';
import NFImg from '../../assets/notfound.png';
import LogInBG from '../../assets/loginbg.png';
import { useParams, Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HostFunction, {DBFns, USRFns} from '../../components/utility/domainfunctions';
import Alert from '@mui/material/Alert';
import FileMgr from '../../components/utility/filemgr';
import AddIcon from '@mui/icons-material/Add';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { jwtDecode } from "jwt-decode";
//import HomeIcon from '@mui/icons-material/Home';
//import Skeleton from '@mui/material/Skeleton';
import { Country } from '../../components/context/context';
import LogIn from '../../components/account/account';
import Payment from '../../components/account/payment';
import PaySuccess from '../../assets/paysuccess.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
//import SettingsIcon from '@mui/icons-material/Settings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
//import CircleIcon from '@mui/icons-material/Circle';


import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import Form from '../../components/form/form';
import Slide from '@mui/material/Slide';
import SyncIcon from '@mui/icons-material/Sync';


import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import SettingsIcon from '@mui/icons-material/Settings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


let curUser = null;
//var token = "none";
//var apiToken = "none";
if(sessionStorage.getItem("signIn")){
//token = sessionStorage.getItem("signIn");
//apiToken = sessionStorage.getItem("apiToken");
curUser = jwtDecode(sessionStorage.getItem("signIn"));
} 

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function SDate1(props){
const d = new Date(parseInt(props.item));
return d.toDateString();}


function SDate2(props){
const date = new Date(props.item);
const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' };
// Format as "Thu, Feb 6, 2025"
return date.toLocaleDateString("en-US", options);}


function getFeatureValue(data,productid,id) {
  // id = -1 for server location, -2 = server i.p address
const numbersDetails = data.filter(function(currItem) { return parseInt(currItem.itemId) === parseInt(productid) || parseInt(productid) === parseInt(currItem.inid); });

switch(parseInt(id) ? parseInt(id) : id) {
case 2: 
return (numbersDetails[0].string3 == 0 || numbersDetails[0].string3 == -1 ? 'Unlimited' : `${numbersDetails[0].string3} TB`);
case 8: 
return (numbersDetails[0].string4+' GB');
case 3: 
case 4:
case 5:  
return ('Unlimited');
case 1: 
return (numbersDetails[0].string1+' GB');
case -2: 
return (numbersDetails[0].address);
case 10:
return (numbersDetails[0].string7);
case 13:
return  <GetDropDownValue id={numbersDetails[0].gender} />
case 6:
return (numbersDetails[0].string5);
case 9: 
return (numbersDetails[0].string6);
case 7:  
return (numbersDetails[0].string2);
case -1: 
return <GetRegion id={numbersDetails[0].zip} />
case 'ip': 
return (numbersDetails[0].phone == '' || numbersDetails[0].phone == null ? 'Pending' : numbersDetails[0].phone);
case 'password': 
return (numbersDetails[0].password == '' || numbersDetails[0].password == null ? 'Pending' : numbersDetails[0].password);
case 'username': 
return (numbersDetails[0].username == '' || numbersDetails[0].username == null ? 'Pending' : <GetUOM id={2} arg1='UOM' arg2={numbersDetails[0].username} />);
default:
return null
}}
function GetUOM(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:props.arg1 ? String(props.arg1) : 'uom', arg2:props.arg2 ? parseInt(props.arg2) : null }, fetchPolicy: "cache-first"});
if (loading) {return <LoadingSK />;}
if (error) {return `Error! ${error.message}`; }
if (data && data.getSiteList.length > 0) {  return ( data.getSiteList[0].string1); }}

function GetDropDownValue(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'11', arg3:'getdropdownvalue', arg2:props.id ? Number(props.id) : 0 }, fetchPolicy: "cache-first"});
if (loading) {return <LoadingSK />;}
if (error) {return `Error! ${error.message}`; }
if (data && data.getSiteList.length > 0) {  return ( data.getSiteList[0].string1); }}
  
function DataTable(props) { 
let { itemId, id } = useParams();
//let load = <LoadingSK />;
const { loading, error, data, refetch } = useQuery(DEF_SITE_LIST, { variables: { listid: 1, arg1:String(props.tbl) , arg2:parseInt(id)}, fetchPolicy: "cache-and-network"});
//if (loading) {return <LoadingSK />;}
if (error) {return `Error! ${error.message}`; } 

const getData = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:String(itemId), arg2:Number(itemId) }, fetchPolicy: "cache-and-network" }); 
const updateDisplay = props.updateDisplay;
const tbl = props.tbl;

let headerForManage;
let dataForManage;
let headerForList=[];
let dataForList=[];
let useKeys1=[];
let dataRow=[];

if(data){
const numbersCol = data.getSiteList;
headerForManage = ( 
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Title</th>
<th>Description</th>
</tr>
</thead>);
  
dataForManage= numbersCol.map(number =>  
<tr className="p-2 pointer" key={`dataForManage${number.id}`}>
<td className='editCol px-1 pointer' onClick={(e) => { window.modifyState(`/acct/114/editfield/${number.inid == 0 ? number.itemId : number.inid}/${number.tbl}`,'') }}>&#9998;</td><td>{number.title} here {number.inid == 0 ? number.itemId : number.inid}</td><td>{number.description}</td></tr> );
for (let i = 0; i < data.getSiteList.length; i++) {
let element= {address:data.getSiteList[i].address, title:data.getSiteList[i].title};
headerForList.push(element); }}
for (let i = 0; i < headerForList.length; i++) {   useKeys1.push(headerForList[i].address);}
if(getData.data){
for (let i = 0; i < getData.data.getSiteList.length; i++) { 
let element = {};
for (const [key, value] of Object.entries(getData.data.getSiteList[i])) {
if(useKeys1.includes(key)){ element[key] = value; }}
dataForList.push(element); }

if(getData.data.getSiteList.length > 0){
for (let i = 0; i < getData.data.getSiteList.length; i++) { 
let tmpRow='' ;
if(useKeys1.length > 0){ 
for (let a = 0; a < useKeys1.length; a++) {
let tmpAdd = useKeys1[a];
tmpRow=`${tmpRow}<td>${removeHTMLTags(getData.data.getSiteList[i][tmpAdd]).substring(0,50)} </td>`
}} 
dataRow.push(`<tr><td class='editCol pointer' onClick="window.modifyState('/acct/114/editdata/${getData.data.getSiteList[i].inid == 0 ? getData.data.getSiteList[i].itemId : getData.data.getSiteList[i].inid}/${getData.data.getSiteList[i].tbl}','')">&#9998; ${getData.data.getSiteList[i].inid == 0 ? getData.data.getSiteList[i].itemId : getData.data.getSiteList[i].inid}</td>${tmpRow}</tr>`); 
}} 

for (let i = 0; i < getData.data.getSiteList.length; i++) {
let tmpData = {};
for (let a = 0; a < headerForList.length; a++) {
let objRef = headerForList[a];
}  
dataForList.push(tmpData); 
}}

let curHeader;
let curData='';
let genListHeader='';
if(props.field != 'undefined' && props.field == true){
curHeader =headerForManage; curData= <tbody>{dataForManage}</tbody>; } else {
if(headerForList.length > 0){
for (let i = 0; i < headerForList.length; i++) {
//if (i === 3) break;
genListHeader += `<th>${headerForList[i].title}</th>`;}}
genListHeader= `<th class='editCol'>&nbsp;</th>${genListHeader}`;
curHeader = (<thead><tr dangerouslySetInnerHTML={{__html: genListHeader}}></tr></thead>);
curData=(<tbody dangerouslySetInnerHTML={{__html: dataRow}}></tbody>);
//curData=dataForList;
}
  
function removeHTMLTags(htmlString) {
// Create a new DOMParser instance
const parser = new DOMParser();
// Parse the HTML string
const doc = parser.parseFromString(htmlString, 'text/html');
// Extract text content
const textContent = doc.body.textContent || "";
// Trim whitespace
return textContent.trim();
}

return <> 
{itemId} here {id}
{parseInt(itemId) === 137 ? <Customers /> : null }
{parseInt(itemId) !== 137 ? <section className='tableCon'>
<table className="customers">
{curHeader}
{curData}
</table>
</section> : null }
</>;
}


function NotFound(props) {  
return ( 
<section className='defMrg8'>
<section className='mx-5 m-2 mb-4 px-3'>
<section className='mt-5 mb-5 notfound pt-5 pb-5'>
<section className='d-block d-lg-none p-5 text-center'><img src={NFImg} className='img-fluid' /></section>


<section className="d-flex justify-content-center pt-2 p-2">
<section className='d-flex flex-column justify-content-center'>
<section className='mx-2 p-1 px-4 p-3'>
<h3>Something&apos; s wrong here ...</h3>
<p>We can&apos;t find the page you&apos;re looking for. Head back home</p>
<p>
<span className='m-1 mt-1 mb-1'>
<Button sx={{ fontSize: 11, m:1, color: '#000000', backgroundColor:'#ffffff', fontWeight:'bold',  }} variant="contained" color="primary" size="small"><Link onClick={ () => window.topTop() } to={{ pathname: "/"}} className='btnLinkBlack'>Home</Link></Button></span>
</p>
</section>
</section>


<section>
<section className="flex-grow-1 d-flex flex-column d-none d-lg-block justify-content-start align-items-start align-self-start">
<img src={NFImg} className='img-fluid' />
</section>
</section>
</section>
</section>
</section> 
</section> 
);
}


function SocialConnect(props) { 
return (<>
<Tooltip title="Face book" arrow={true}><span className='mx-2 pointer'><FacebookIcon sx={{ fontSize: 20, }} /></span></Tooltip>
<Tooltip title="LinkedIn" arrow={true}><Link to='https://www.linkedin.com/in/
dredgeresources' target='_blank' rel='noopener' className='mx-2 pointer' style={{color: '#ffffff', textDecoration: 'none'}}><LinkedInIcon sx={{ fontSize: 20, }} /></Link></Tooltip>
<Tooltip title="X" arrow={true}><Link to='https://x.com/dredgetech' target='_blank' rel='noopener' className='mx-2 pointer'  style={{color: '#ffffff', textDecoration: 'none'}}><XIcon sx={{ fontSize: 20, }} /></Link></Tooltip>
</>);}




function SideMenu() {
const [selectedIndex, setSelectedIndex] = React.useState(1);
const handleListItemClick = (event, index) => { setSelectedIndex(index); };
const sideMenu = useQuery(DEF_SITE_LIST, { variables: { listid: 7 }, fetchPolicy: "cache-and-network" });
let listItems = '';
if(sideMenu.data){
let numbers = sideMenu.data.getSiteList;  
listItems = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={'listItems'+number.id}>
<Typography variant='div'><Link to={'/'+(number.inid == 0 ? number.itemId : number.inid)+'/'+(number.title)} state={{ pageTitle : number.title, addnew:number.addnew, moduleid:(number.inid == 0 ? number.itemId : number.inid), }} className="sideMenu d-block pb-1 mt-2">{number.title}</Link>
</Typography><Divider /></section>);}
return (<section className='mb-3 mt-2'>{listItems}</section>);}

function maskEmail(email) {
  // Split the email into the local part and the domain part
  const [localPart, domainPart] = email.split('@');

  // Define how many characters you want to keep visible in the local part
  const visibleCharacters = Math.floor((localPart.length)/2);

  // Mask the rest of the local part
  const maskedLocalPart = localPart
      .slice(0, visibleCharacters)
      .padEnd(localPart.length, '*');

  // Reassemble the email
  return `${maskedLocalPart}@${domainPart}`;
}

function Customers() {
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getcustomers' }, fetchPolicy: "cache-and-network" }); 

const [formState, setFormState] = useState({ displayProductItems: '', });
function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }

let listItems = '';
if(htfile.data){
let numbers = htfile.data.getSiteList;  
listItems = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<tbody key={`customers${number.id}`}><tr className="p-2 pointer">
<td className='editCol px-1 pointer'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`ticketdetailsCon${number.id}`)}} className='pointer' /></Tooltip></td><td>{maskEmail(number.username)} {number.itemId}</td><td>{number.fullname}</td><td><SDate1 item={number.date} /></td><td>products</td></tr> 
{formState.displayProductItems === `ticketdetailsCon${number.id}` ? <tr><td colSpan={5}>
<section>details
</section>
</td>
</tr>
: null}
</tbody>
 );}
return (<section className='mb-3 mt-2'>
<section className=''><table className='customers'>
<thead><tr><th className='editCol'>&nbsp;</th>
<th>Username</th><th>Full name</th><th>Created</th><th>Products</th></tr>
</thead>{listItems}</table></section></section>);}

function DropMenu(props){
const menuList = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == props.groupId; });

const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = React.useState(null);
const open = Boolean(anchorEl);
const handleClickListItem = (event) => {
setAnchorEl(event.currentTarget);
};

const handleMenuItemClick = (event, index) => { setSelectedIndex(index);  setAnchorEl(null);  };

let optionsList = [];
for (let i = 0; i < menuList.length; i++) {  
    optionsList.push(<Link key={menuList[i].id+'optionsList'} onClick={ () => { window.topTop(); props.handleDrawerClose ? props.handleDrawerClose() : null; }} to={{ pathname: `/cat/${props.groupId}/${menuList[i].inid == 0 ? menuList[i].itemId : menuList[i].inid}/${menuList[i].string1}` }}  state={{ pageTitle : `${menuList[i].string1}`, }} className="primaryMenu">{menuList[i].string1}</Link>); }
const handleClose = () => { setAnchorEl(null); };

return (
<div>
<List
component="nav"
aria-label=""
sx={{ backgroundColor:'#18AB0D', color:'#ffffff', margin:0, padding:0, }}
>
<ListItemButton
id="lock-button"
aria-haspopup="listbox"
aria-controls="lock-menu"
aria-label="primary menu"
aria-expanded={open ? 'true' : undefined}
onClick={handleClickListItem}
sx={{ margin:0, paddingTop:0.5, paddingBottom:0.5,  paddingLeft:2, paddingRight:2, }}

>
<ListItemText
disableTypography
primary={<Typography variant="body2" style={{ color: '#FFFFFF', fontSize:12, textTransform:'uppercase', }}>{props.title ? props.title : null}</Typography>}

/> <ArrowDropDownIcon />
</ListItemButton>
</List>
<Menu
id="lock-menu"
anchorEl={anchorEl}
open={open}
onClose={handleClose}
MenuListProps={{
'aria-labelledby': 'lock-button',
role: 'listbox',
}}
>
{optionsList.map((option, index) => (
<MenuItem
key={window.uuidv4()}
selected={index === selectedIndex}
onClick={(event) => handleMenuItemClick(event, index)}
>
{option}

</MenuItem>
))}
</Menu>
</div>
);
}


function Currency(props) {
//const getCurrency = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'6' }, fetchPolicy: "cache-and-network" }); 
const currencies = [];
const flags = [];

const [formState, setFormState] = useState({
curIndex: ((typeof props.curIndex !== 'undefined') ? props.curIndex : 130),
});



const [anchorEl, setAnchorEl] = React.useState(null);
const [selectedIndex, setSelectedIndex] = React.useState((typeof props.curIndex !== 'undefined') ? props.curIndex : 130);
const open = Boolean(anchorEl);
const handleClickListItem = (event) => {
setAnchorEl(event.currentTarget);
};

const handleMenuItemClick = (event, index) => {
setSelectedIndex(index);
setAnchorEl(null);
setFormState({ ...formState, curIndex: index  });
props.setCountry(index);
};


if(props.currency){
const numbers = props.currency.getSiteList;
for (let i = 0; i < numbers.length; i++) {   
currencies[(numbers[i].inid == 0) ? numbers[i].itemId : numbers[i].inid] = numbers[i].string1;
flags[(numbers[i].inid == 0) ? numbers[i].itemId : numbers[i].inid] = numbers[i].string2;
}
}

const options = currencies;
const handleClose = () => {
setAnchorEl(null);
};

return (
<div className='secondaryMenu'>
  <List 
	component="nav"
	aria-label=""
    sx={{ backgroundColor:'#0080C0', minWidth:100, color:'#ffffff', margin:0, padding:0, fontSize:50, }}
  >
	<ListItemButton
	  id="lock-button"
	  aria-haspopup="listbox"
	  aria-controls="lock-menu"
	  className='currency'
	  aria-label="currency"
	  aria-expanded={open ? 'true' : undefined}
	  onClick={handleClickListItem}
	  sx={{ color:'#ffffff', margin:0, paddingTop:0, paddingBottom:0,  paddingLeft:2, paddingRight:2, }}
	>
	  <ListItemText
	//	secondary={options[selectedIndex]}
		disableTypography
  //      primary={<Typography variant="body2" style={{ color: '#FFFFFF' }}>MyTitle</Typography>}
        secondary={<Typography variant="body2" style={{ color: '#FFFFFF' }}>
		<span className='px-1'><img src={`${window.apiServer}/resource/${flags[formState.curIndex]}`} className='currencyImg mx-1' /></span><span dangerouslySetInnerHTML={{__html: options[selectedIndex]}}></span>
		</Typography>}
/><ArrowDropDownIcon />
	</ListItemButton>
  </List>
  <Menu
	id="lock-menu"
	anchorEl={anchorEl}
	open={open}
	onClose={handleClose}
	MenuListProps={{
	  'aria-labelledby': 'lock-button',
	  role: 'listbox',
	}}
 >
	{options.map((option, index) => (
	  <MenuItem
		key={option}
		className='currency'
		selected={index === selectedIndex}
		onClick={(event) => handleMenuItemClick(event, index)}
 >
	<span className='px-1'><img src={`${window.apiServer}/resource/${flags[index]}`} className='currencyImg mx-1' /></span><span dangerouslySetInnerHTML={{__html: option}}></span>
	  </MenuItem>
	))}
  </Menu>
</div>
);
}

function curDate(){
const date = new Date();
const day = date.getDate();
const month = date.getMonth() + 1;
const year = date.getFullYear();
return (`${day}/${month}/${year}`);
}


function CarTTable(props) {
const [formState, setFormState] = useState({
paymentStatus:false, bankTransfer:false,
});
let totalAmount =0;
let listCartItems;
function hideElem(x){ if(document.getElementById(x)){document.getElementById(x).style.display = 'none'}}
function showBank(){setFormState({ ...formState, bankTransfer: !formState.bankTransfer });}
if(props.cartItems && props.cartItems.length > 0){
let cartItems = props.cartItems;
for (let i = 0; i < props.cartItems.length; i++) { totalAmount = totalAmount+(Number(cartItems[i].total))*(Number(cartItems[i].description));  }


listCartItems = cartItems.map((number, index) => 
<tr key={'cartitems'+number.id} id={'cartitems'+number.id}>
  <td className='editcol'>
<span className="pointer" onClick={() => { hideElem('cartitems'+number.id)}}>
<Form type="form" rowid={number.inid == 0 ? number.itemId : number.inid} viewid={29}  updateCart={props.updateCart} />
</span></td>
  <td>  {number.province}  </td>
  <td>
  
  <Form type="form" total={number.total} rowid={number.inid == 0 ? number.itemId : number.inid} updateCart={props.updateCart} viewid={18} typeid={number.planid} formmode={1} />
  
  </td>
  <td><GetUOM id={2} arg1='UOM' arg2={number.planid} /></td>
  <td><AddToCart type='server' currency={props.currency}  price={Number(number.description)} /></td>
  <td><AddToCart type='server' currency={props.currency}  price={(Number(number.total))*(Number(number.description))} /></td>
</tr>
);}

function updatePayment(){
if(formState.paymentStatus != true){setFormState({ ...formState, paymentStatus: true });
}}

let userEmail='';
if(sessionStorage.getItem("signIn")){
let decoded = jwtDecode(sessionStorage.getItem("signIn"));
userEmail=decoded.email;} 


let userId = 0;
if(sessionStorage.getItem("signIn")){
let decoded = jwtDecode(sessionStorage.getItem("signIn"));
userId=decoded.userId;
}
const year = new Date().getFullYear().toString().substr(-2);
const rand4 = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
let useInvoice = (`${year}${userId}${rand4}`);

return ( 
<section className='mb-5'> 
  
{formState.paymentStatus == false ? 
<><section className='pageTitle pb-1'>
<section className="d-flex align-items-end">
<h2 className=''>
<span className="mx-2"><AddShoppingCartIcon sx={{ marginRight:2, fontSize:35, }} />Cart Items 
</span>
</h2>
<section className="flex-grow-1 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse align-items-center">
{Number(totalAmount) > 0 ? <section className=''>
  
  
  
    
<section className="flex-grow-1 d-flex flex-column justify-content-start">
<section className="flex-fill d-flex flex-row-reverse align-items-start fw-bold">
<section>

<section className="d-flex transDetail pb-1 mb-1">
<section className="">Invoice ID:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">{useInvoice}</section>
</section>



<section className="d-flex transDetail pb-1 mb-1">
<section className="">Amount:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end"><AddToCart  type='server' currency={props.currency}  price={totalAmount} /></section>
</section>


<section className="d-flex transDetail pb-1 mb-1">
<section className="">VAT:</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">0.00</section>
</section>

<section className="d-flex transDetail pb-1 mb-1">
<section className="">Total</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end"><AddToCart reverse type='server' currency={props.currency}  price={totalAmount} /></section>
</section>



<section className="d-flex">
<section className="">Date</section>
<section className="flex-fill d-flex flex-row-reverse align-items-end">{curDate()}</section>
</section>
</section>
</section>
</section>
</section> : null}
</section>
</section>
</section>
</section>



<table className="customers mt-2">
<thead>
  <tr>
    <th className='editCol'>&nbsp;</th>
    <th>Item</th>
    <th>Qty</th>
    <th>UOM</th>
    <th>Rate</th>
    <th>Amount</th>
  </tr>
</thead>
<tbody>{(typeof listCartItems !== 'undefined' && listCartItems !== null) ? listCartItems : null}</tbody>
</table>

<section className="d-flex flex-row-reverse mt-3 fw-bold consultancy p-1 pt-2 pb-2">
<span className="px-2"><AddToCart type='server' currency={props.currency}  price={totalAmount} /></span>
<span className="px-2 text-center">Total</span>
</section>



{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? <center className='mt-5 mb-5  justify-content-center align-items-center'><Link onClick={ () => window.topTop() } to={{ pathname: `/acct/109/Login`, }}  state={{ pageTitle : 'Sign in' }}><button type="button" className="btn btn-primary mt-4 mb-4 btn-sm centerText">Login or Create account to make payment</button></Link></center> : null}




{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? null : 

<>{Number(totalAmount) > 0 ? <section className='mt-5 mb-5'>
<section className='row'>
<section className='col-md-12 px-3 text-center'>
<Typography variant='div' className='mb-3 fw-bold'>Pay with PayPal, Venmo or Credit Card</Typography>
<div className='d-flex align-items-center justify-content-center' style={{minWidth:'40%'}}><Payment menurefetch={props.menurefetch} ownDataRefetch={props.ownDataRefetch} updateCart={props.updateCart} closePayment={updatePayment} invdate={curDate()} invoice={useInvoice} amount={totalAmount} />
</div>
</section>
<section className='col-md-12 px-3 mt-5'>
<section className="p-3 d-flex flex-column justify-content-center align-items-center">
  <Button onClick={() => { showBank()}} sx={{ fontSize: 14, p:1, pl:2, pr:2, fontWeight:'bold', }} variant="outlined" size="small">Pay by Bank Transfer (Nigeria Customer Only)</Button>

{formState.bankTransfer == true ? <><Typography className='mb-3 mt-3'>You can make payments via bank transfer</Typography>
<Typography className='mb-3'>Ensure to transfer exact amount and quote the invoice number</Typography>
<section className='fw-bold mt-3'>
<Typography>Bank name: Access Bank PLC</Typography>
<Typography>Account number: 0000971807</Typography>
<Typography>Account name: Dredge Resources Limited</Typography>
<Typography variant='div'>Payer: {userEmail} ({useInvoice})</Typography>
</section></> : null}
</section>


</section>
</section>   
</section> : null}</>}



</> : null}
{formState.paymentStatus == true ? 
  <section>
    <section className='mx-5 mb-3 text-center'><center><img loading='lazy' src={PaySuccess} className="img-fluid checkImg" /></center></section>
    <h5 className='mb-3 text-center'>Payment Confirmation - Thank You!</h5>
    <Typography className='mb-3 text-center'>Thank you for your payment! We’re pleased to confirm that your transaction is successful.</Typography>
    <Typography className='mb-3 text-center'>You will receive a conformation email with details on how to access your product(s) <br /> For support reach out to our support team at support@dredge.com.ng.</Typography>
    <section className="mt-5 text-center fw-bold mb-5 mx-5 alert alert-info" role="alert">It might take some minutes for your purchase(s) to be ready</section>
    
    <Typography className='mb-3 text-center fw-bold'>

Best regards,  <br />
DTech Services<br />
sales@dredge.com.ng  
</Typography>
   </section> : null }
</section>
);
}

function PageTitle(props){ return <h3 className='pageTitle m-0 p-0 pb-2'>{props.title}</h3>	}
function CustomTabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other}
>
{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
</div>
);
}

CustomTabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `simple-tab-${index}`,
'aria-controls': `simple-tabpanel-${index}`,
};}

function Restricted(){
return ( 
<section className='defMrg8'>
<section className='defMrg8 m-2 mb-4 px-3'>
<section className='row'>
<section className='col-md-6 notfound p-4 mb-4'>
<section className='mt-5 mb-5 notfound pt-5 pb-5'>
<section className="d-flex align-items-center justify-content-center pt-2 p-2">
<section className=' d-flex flex-column mx-2 p-1 px-4 p-3'>
<h3>Login Required!</h3>
<p>Please login to access the requested service</p>
</section>


<section className="flex-grow-1 d-flex flex-column justify-content-start align-items-start align-self-start logoTopCon">
<img src={LogInBG} className='img-fluid' />
</section>
</section>
</section>
</section>

<section className='col-md-6 mb-4'><LogIn /></section>
</section>
</section> 
</section> 
);
}

function GetPageTitles(props) {
let listItems='';
const numbers = props.pages.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == (props.menuId ? props.menuId : 0); });
const numbersProducts = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117 || parseInt(currItem.string5) == 118; });

const numbersServices = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 120; });

if(parseInt(props.menuId) != 133){
listItems = numbers.map(number =><Link key={`listItems12${number.id}`} onClick={ () => window.topTop() } to={{ pathname: `/con/${number.inid == 0 ? number.itemId: number.inid}/${number.tbl}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : number.string1, string2:number.string2 }} className={props.classes}>{number.string1}</Link>);
}
if(parseInt(props.menuId) == 133){
listItems = numbersProducts.map(number =><Link key={number.id+'listfootproducts'} onClick={ () => window.topTop() } to={{ pathname: `/cat/${number.string5}/${number.inid == 0 ? number.itemId: number.inid}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : `${number.string1}`, string2:number.string2 }} className={props.classes}>{number.string1}</Link>);
}



let listServices= numbersServices.map(number =><Link key={number.id+'listfootserverivces'} onClick={ () => window.topTop() } to={{ pathname: `/cat/${number.string5}/${number.inid == 0 ? number.itemId: number.inid}/${number.string1}/${props.menuId ? props.menuId : ''}`, }}  state={{ pageTitle : number.string1, string2:number.string2 }} className={props.classes}>{number.string1}</Link>);


return <>{props.menuId && props.menuId == 125 ? listServices : null}{listItems}</>;
}


function AccountSecurity() {
const [formState, setFormState] = useState({
status:0, recoveryEmail:'', supportPIN:'', data:'', });
const [expanded, setExpanded] = React.useState(null);
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};
const getNotificationStatus = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'notificationstatus' }, fetchPolicy: "cache-first"});
let notificationStatus = <LoadingSK />;


 if(getNotificationStatus.data){
  notificationStatus=<Form type="form" refetch={getNotificationStatus.refetch} itemstatus={getNotificationStatus.data.getSiteList.length > 0 ? 1 : 2} status={getNotificationStatus && getNotificationStatus.data.getSiteList.length > 0 ? 1 : 2} formmode={2} viewid={103} tbl={16} />
 } 

const { loading, error, data, refetch } = useQuery(DEF_SITE_LIST, { variables: { listid: 34, arg1:'34' }, fetchPolicy: "cache-first"});

if (data) {
if(data.getSiteList.length > 0 && formState.data != data.getSiteList){
setFormState({ ...formState, 
//status:data.getSiteList[0].status && data.getSiteList[0].status == 1 ? 1 : 2,
status:parseInt(data.getSiteList[0].status) == 1 ? 1 : 2,
recoveryEmail:data.getSiteList[0].username ? data.getSiteList[0].username : '',
supportPIN:data.getSiteList[0].total ? data.getSiteList[0].total : null,
data:data.getSiteList,});}}

 const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
return (
<div style={{maxWidth:'100%',}}>
 
<Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Change Password</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Form viewid={33} formmode={2} type='form' />
</AccordionDetails>
</Accordion> 


<Accordion expanded={expanded === 'panel1A'} onChange={handleChange('panel1A')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1Abh-content"
id="panel1Abh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Support PIN</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Form viewid={37} total={formState.supportPIN} refetch={refetch} rowid={-2} formmode={2} type='form' />
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel3bh-content"
id="panel3bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Account Recovery Email</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Form type="form" refetch={refetch} formmode={2} username={formState.recoveryEmail} tbl={95} address={curUser.email} rowid={-2} viewid={35} /></AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel4bh-content"
id="panel4bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Notifications</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>
<Alert className='mb-3' variant="filled" severity="info">This will disable non-account related notifications. <br />Account and Product related notifications such expiry, failed billing etc will remain active except for terminated accounts</Alert> 
<span className='px-3'>Disable Notifications:</span>{notificationStatus}
</AccordionDetails>
</Accordion>

<Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel5bh-content"
id="panel5bh-header"
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
><Typography className='fw-bold px-3' sx={{ width: '100%', }}>Cancel Account</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
><Form type="form" formmode={2} rowid={-2} viewid={36} /></AccordionDetails>
</Accordion>

</div>);}


function ContactInfo() {
const [formState, setFormState] = useState({ status:0, });
const [expanded, setExpanded] = React.useState(null);
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};

const contactInfo = useQuery(DEF_SITE_LIST, { variables: { listid: 114, arg1:'contactInfo', arg2:5 }, fetchPolicy: "cache-first"});
const orgInfo = useQuery(DEF_SITE_LIST, { variables: { listid: 114, arg1:'contactInfo', arg2:136 }, fetchPolicy: "cache-first"});


const Accordion = styled((props) => (
<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
border: `1px solid ${theme.palette.divider}`,
'&:not(:last-child)': {
borderBottom: 0,
},
'&:before': {
display: 'none',
},
}));

const AccordionSummary = styled((props) => (
<MuiAccordionSummary
expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
{...props}
/>
))(({ theme }) => ({
backgroundColor:
theme.palette.mode === 'dark'
? 'rgba(255, 255, 255, .05)'
: 'rgba(0, 0, 0, .03)',
flexDirection: 'row-reverse',
'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
transform: 'rotate(90deg)',
},
'& .MuiAccordionSummary-content': {
marginLeft: theme.spacing(1),
},
}));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

let orgInfoDetails=(<Form viewid={136} formmode={2} type='form'  />);
if(orgInfo.data && parseInt(orgInfo.data.getSiteList.length) > 0){
  orgInfoDetails=(<Form viewid={136} formmode={2} type='form' 
    string1={orgInfo.data && orgInfo.data.getSiteList.length > 0 ? orgInfo.data.getSiteList[0].string1 : ''}
    string2={orgInfo.data && orgInfo.data.getSiteList.length > 0 ? orgInfo.data.getSiteList[0].string2 : ''}
    string3={orgInfo.data && orgInfo.data.getSiteList.length > 0 ? orgInfo.data.getSiteList[0].string3 : ''}
    string4={orgInfo.data && orgInfo.data.getSiteList.length > 0 ? orgInfo.data.getSiteList[0].string4 : ''} />);
}
 let contactInfoDetails=(<Form viewid={5} formmode={2} type='form' refetch={contactInfo.refetch}  />);
 if(contactInfo.data && contactInfo.data.getSiteList.length > 0){
  contactInfoDetails=(<Form viewid={5} formmode={2} type='form' 
    refetch={contactInfo.refetch}
    string1={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string1 : ''}
    string2={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string2 : ''}
    string3={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string3 : ''}
    string4={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string4 : ''}
    string5={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string5 : ''}
    string6={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string6 : ''}
    string7={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string7 : ''}
    string8={contactInfo.data.getSiteList.length > 0 ? contactInfo.data.getSiteList[0].string8 : ''} />);
 }
  return (
  <div style={{maxWidth:'100%',}}>
   
  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel1bh-content"
  id="panel1bh-header"
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  onClick={() => { contactInfo.refetch()}}
  >
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>Contact Person</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>{contactInfoDetails}
</AccordionDetails>
</Accordion> 
  
  
  <Accordion expanded={expanded === 'panel1A'} onChange={handleChange('panel1A')}>
  <AccordionSummary
  expandIcon={<ExpandMoreIcon />}
  aria-controls="panel1Abh-content"
  id="panel1Abh-header"
  sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
  onClick={() => { orgInfo.refetch()}}
  >
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>Organization Details</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:0, p:2, }}
>{orgInfoDetails}</AccordionDetails>
</Accordion>
</div>);}

function LoadingSK() {
return (<div className="spinner-border spinner-border-sm" role="status"></div>);
}
  
function AddToCart(props) {
const country = useContext(Country);
let itemPrice = <LoadingSK />;

function formater(x=0){
const options = {
style: 'decimal',  // Other options: 'currency', 'percent', etc.
minimumFractionDigits: 2,
maximumFractionDigits: 2,
};
let formattedWithOptions = x.toLocaleString('en-US', options);
return (formattedWithOptions);
};
const curSym = props.currency.getSiteList.filter(function(currItem) { return parseInt(currItem.itemId) == country || parseInt(currItem.inid) == country; });

if(props.type && props.type == 'domain'){
if(props.cart){
itemPrice = <section className='text-center fw-bold pointer'>
<h4 className='mb-4'>
<span dangerouslySetInnerHTML={{__html: curSym[0].string4}}></span><span>
{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))} / <GetUOM id={2} arg1='UOM' arg2={props.uom} /></span></h4>
<section className='mt-3'>
<Form type="form" tbl={props.groupid} city={window.cartCookie} rowid={props.rowid} title={props.itemId} total={1} province={props.prdname} viewid={28} description={props.price} gender={props.gender ? props.gender : 0} formmode={1} updateCart={props.updateCart} planid={props.uom} />
</section>
</section>;}

if(!props.cart){
itemPrice = <><span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>
{props.price ? (formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))) : null} / <GetUOM id={2} arg1='UOM' arg2={props.uom ? props.uom : null} /></span></>;;
}}


if(props.type && (props.type == 'hosting' || props.type == 'server')){
if(props.cart){
itemPrice = <section className='text-center fw-bold pointer'>
<h4 className='mb-4'>
<span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>
{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))} / <GetUOM id={2} arg1='UOM' arg2={props.uom} /></span></h4>
<section className='mt-3'>
<Form type="form" tbl={props.groupid} city={window.cartCookie} rowid={props.rowid} title={props.itemId} total={1} province={props.prdname} viewid={28} description={props.price} formmode={1} updateCart={props.updateCart} planid={props.uom} string1={props.string1} string2={props.string2} string3={props.string3} string4={props.string4} string5={props.string5} string6={props.string6} string7={props.string7} />
</section>
</section>;
}
if(!props.cart){
if(!props.reverse){
itemPrice = (<><span dangerouslySetInnerHTML={{__html: (curSym.length > 0 ? curSym[0].string4 : '')}}></span><span>{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))}</span></>);
}
if(props.reverse){
itemPrice = (<><span>{formater(props.price*(curSym.length > 0 ? curSym[0].string3 : 0))}</span><span dangerouslySetInnerHTML={{__html: curSym[0].string4}}></span></>);
}}} return (itemPrice); }
  
  
  
function ProductFeatures(props) {
const features = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:String(23) }, fetchPolicy: "cache-and-network" }); 
let pFeatures = <LoadingSK />;
let col = '';
if(props.col){col='col-12 col-md-6 col-lg-3 px-3 mb-3';}

if(features.error){pFeatures = <section className="alert alert-warning offline" role="alert">Connection Error! {features.error.message}</section>;} 
if(features.data){
let numbers = features.data.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == props.gId; });  
pFeatures = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={number.id} className={col}>
<Typography className="mb-3 fw-bold">{number.string1}</Typography>
<section className="mb-1" dangerouslySetInnerHTML={{__html: number.string5}}></section> 
<Divider className="mb-2" />
</section> 
);}
return ( pFeatures );
}


function TransferStatus(props) {
const features = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'transferstatus', arg2:props.id }, fetchPolicy: "cache-and-network" });  
if(features.error){
return (<section className="alert alert-warning offline" role="alert">Connection Error! {features.error.message}</section>);} 
if(features.data && features.data.getSiteList.length > 0){
if(features.data.getSiteList[0].broken==0){
return (
<Alert className='mt-2 mb-2' variant="filled" severity="info">Transfer request processing</Alert>);}} 
return (''); 
}

  
function Settings(props){ 
const [formState, setFormState] = useState({ formMode: 1, });
const getSettings = props.settings.getSiteList.filter(function(currItem) { return parseInt(currItem.groupid) === parseInt(props.featureid); });
//function updateMode(x){}
//if(getSettings && getSettings.length > 0){updateMode(2);}
return (<> 
<Form type="form" title={props.type} planid={props.productid} label={props.label ? props.label : ''} formmode={formState.formMode} tbl={parseInt(props.featureid)} viewid={props.featureid} refetch = {props.refetch ? props.refetch : null} displayDomain={props.domainDisplay} 
inid={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].inid : -1 } 
description={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].description : '' } 
fullname={props.settings !== false &&  getSettings.length > 0 && props.title === 'undefined' ? getSettings[0].description : props.title } 
itemId={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].itemId : -1 } 
status={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].status : 0 }  
password={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].password : '' } 
zip={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].zip : '' } 
phone={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].phone : '' }  
address={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].address : '' } 

rowid={props.settings !== false &&  getSettings.length > 0 ? (parseInt(getSettings[0].inid) === 0 ?getSettings[0].itemId : getSettings[0].inid) : 0 } 
total={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].total : 0 } 
country={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].country : '' } 
province={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].province : '' } 
date={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].date : '' } 
domain={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].domain : '' } 
username={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].username : '' } 
city={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].city : '' } 
addnew={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].addnew : '' } 
itemstatus={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].broken : 0 }  
gender={props.productid} 
groupid={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].groupid : 0 } 
string1={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string1 : '' } 
string2={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string2 : '' } 
string3={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string3 : '' } 
string4={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string4 : '' } 
string5={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string5 : '' } 
string6={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string6 : '' } 
string7={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string7 : '' } 
string8={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string8 : '' } 
string9={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string9 : '' } 
string10={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string10 : '' } 

string11={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string11 : '' } 
string12={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string12 : '' } 
string13={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string13 : '' } 
string14={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string14 : '' } 
string15={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string15 : '' } 
string16={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string16 : '' } 
string17={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string17 : '' } 
string18={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string18 : '' } 
string19={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string19 : '' } 
string20={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string20 : '' } 
string21={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string21 : '' } 
string22={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string22 : '' } 
string23={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string23 : '' } 
string24={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string24 : '' } 
string25={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string25 : '' } 
string26={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string26 : '' } 
string27={props.settings !== false &&  getSettings.length > 0 ? getSettings[0].string27 : '' } 
 /></>);
}

function GETERPCode(props){
const getSettings = useQuery(DEF_SITE_LIST, {variables: { listid: 53, arg1:'domain', arg2:Number(props.id) }, fetchPolicy: "cache-and-network" }); 
if(getSettings.data){
if(getSettings.data.getSiteList.length > 0){
if(getSettings.data.getSiteList[0].zip != '' && getSettings.data.getSiteList[0].zip != null){
return (<><section className='mb-2 mt-2'>
  <Alert variant="filled" severity="success">EPP code: {getSettings.data.getSiteList[0].zip}</Alert></section></>)
} else {return <Alert variant="filled" severity="info">EPP code: Pending</Alert>;}
} else return <Alert variant="filled" severity="info">EPP code: Pending</Alert>; }}

function DomainMgt(props){
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu;
const getSettings = useQuery(DEF_SITE_LIST, {variables: { listid: 51, arg1:'domain', arg2:Number(props.id) }, fetchPolicy: "cache-and-network" }); 

let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 116 || parseInt(currItem.total) === -1; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`domainmgt${number.id}`}>
<AccordionSummary 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section>
 {parseInt(number.inid) === 53 || parseInt(number.itemId) === 53 ? <GETERPCode id={props.id} /> : <Settings refetch={getSettings.refetch} type='domain' settings={getSettings.data ? getSettings.data : false} productid={props.id} domainDisplay={props.title} title={props.title} featureid={parseInt(number.inid) === 0 ? number.itemId : number.inid} />}
</section>
</AccordionDetails></Accordion>
);}

if(getSettings.data){
return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section>{menu}</section>);
}}


function ServerMgt(props){
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu = '';
let features = '';
let numbersFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === 0 || parseInt(currItem.planid) === 118; });
if(numbersFeatures.length > 0){
features = numbersFeatures.map((number, index) => <tr key={`servermgtS${number.id}`}>
<td>{number.title}</td>
<td>{getFeatureValue(props.productsDetails,props.id,(number.inid == 0 ? number.itemId : number.inid))}</td>
</tr>);}

let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 118 || parseInt(currItem.total) === -1 || parseInt(currItem.total) === -4 || parseInt(currItem.total) === -3; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`servermgt${number.id}`}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} main {number.itemId}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section className=''>details</section></AccordionDetails></Accordion>
);
}


function copyText(containerid) {
  if (document.selection) { // IE
    var range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  document.execCommand("copy");
  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  document.execCommand("copy");
  }}

return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section>
<Accordion expanded={expanded === 'serverDetails'} onChange={handleChange('serverDetails')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"panelserverDetailsbh-content"}
id={"panelserverDetailsbh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
Server Details 
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'><table className='customers'><tbody>{features}
<tr>
<td>Server Location</td>
<td>{getFeatureValue(props.productsDetails,props.id,-1)}</td>
</tr>
</tbody></table></section>
</AccordionDetails></Accordion>



<Accordion expanded={expanded === 'serverDetails1'} onChange={handleChange('serverDetails1')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"panelserverDetails1bh-content"}
id={"panelserverDetails1bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
onClick={(e) => { props.ownDataRefetch() }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
Connection Info
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className=''><table className='customers'><tbody>
<tr>
<td>IP Address</td>
<td>{getFeatureValue(props.productsDetails,props.id,'ip')}</td>
</tr>
<tr>
<td>SSH Username</td>
<td>{getFeatureValue(props.productsDetails,props.id,'username')}</td>
</tr>
<tr>
<td>Password</td>
<td><section className='mb-2'>
<span id={`serverPass${props.id}`}> {getFeatureValue(props.productsDetails,props.id,'password')}</span><span className='mx-2 pointer' onClick={(e) => { copyText(`serverPass${props.id}`); }}><Tooltip title="Click to copy password" arrow={true}><ContentCopyIcon /></Tooltip></span></section>
</td>
</tr>
<tr><td colSpan={2}>Change your password immediately after logging into the server. You can no longer use this password after changing it on the server;   Make sure to keep your new password in a safe place in case you forget it</td></tr>
</tbody></table>
 </section></AccordionDetails></Accordion>
</section>);
}
function HostingMgt(props){ 
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);};
let menu = ''; let features = '';
const getOwnDomains = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'mydomains' , arg2:Number(props.id), arg3:String(63)}, fetchPolicy: "cache-and-network" }); 
const getUserAct = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'mydomains' , arg2:Number(props.id), arg3:String(64)}, fetchPolicy: "cache-and-network" }); 
const getDatabase = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'mydomains' , arg2:Number(props.id), arg3:String(65)}, fetchPolicy: "cache-and-network" });
const rootDir = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'listDir',arg2:0,arg3:`hst${props.id}`}, fetchPolicy: "cache-and-network" });
const [formState, setFormState] = useState({
  domainDisplay:'', displayProductItems: '', curFTPDisplay: '/', dirList:[], curFTPTitle:'',
  curFTPID:'', curFTPSUBID:'', selectedItems:[], realAddress:'', reload:'',
});

function updateInner(x){ setFormState({ ...formState, domainDisplay: x }); }

function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }

let myDomains='';
let userActs='';
let databases='';
if(getOwnDomains.data && getOwnDomains.data.getSiteList.length > 0){
myDomains = getOwnDomains.data.getSiteList.map((number, index) => <tbody key={`mydomainpdb5${number.id}myHf`}>
<><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`mydomainDBCon${number.id}`)}} className='pointer' /></Tooltip></td><td><a href={`https://${(number.fullname).toLowerCase()}`} target='_blank' rel='noopener'>{`${(number.fullname).toLowerCase()}`}</a></td><td><SDate1 item={number.date} /> </td><td>{(number.title).toUpperCase()}</td><td>/{(number.fullname).toLowerCase()}/public</td></tr>
 {formState.displayProductItems == `mydomainDBCon${number.id}` ? <tr><td colSpan={5}><section>
 {Number(number.isready) === 0 ? <Alert className='m-2 mb-4' variant="filled" severity="info">Pending</Alert> : null}
 {parseInt(number.remove)==1 ? <Alert className='m-2 mb-4' variant="filled" severity="warning">Pending Removal</Alert> : null}
 {parseInt(number.remove)!=1 && Number(number.isready)==1 ? <section className='mb-5'><HostFunction rowid={parseInt(number.inid) === 0 ? number.itemId : number.inid} webserver={number.title} productid={props.id} title={number.fullname} menu={props.menu} refetch={getOwnDomains.refetch} /></section> : null}</section></td></tr> : null}</> 
</tbody>);}

let numbersFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === 0 || parseInt(currItem.planid) === 117; });
if(numbersFeatures.length > 0){
features = numbersFeatures.map((number, index) => <tr key={`hostlistS${number.id}`}>
<td>
{(number.inid == 0 ? number.itemId : number.inid)==13 ? 'Platform' : null} 
{(number.inid == 0 ? number.itemId : number.inid)!=13 ? number.title : null} 
</td>
<td>
{(number.inid == 0 ? number.itemId : number.inid)==13 ? 'Windows' : null} 
{(number.inid == 0 ? number.itemId : number.inid)!=13 ?<>{getFeatureValue(props.productsDetails,props.id,(number.inid == 0 ? number.itemId : number.inid))}</> : null} 
</td>
</tr>
);
}


let numbers = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.total) === 117 || parseInt(currItem.total) === -1 || parseInt(currItem.total) === -2 || parseInt(currItem.total) === -4; });
if(numbers.length > 0){
menu = numbers.map((number, index) => <Accordion expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} key={`hostingmgt${number.id}`}>
<AccordionSummary 
aria-controls={"panel"+index+"bh-content"}
id={"panel"+index+"bh-header"}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title} main {number.itemId}
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'>{number.description}</section>
<section className=''>details</section></AccordionDetails></Accordion>
);}


if(getUserAct.data && getUserAct.data.getSiteList.length > 0){
  userActs = getUserAct.data.getSiteList.map((number, index) => <tbody key={`mydomainprod5${number.id}myHf`}><tr>
  <td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`mydomainCon${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.fullname}
  </td><td><SDate1 item={number.date} /></td></tr>
  {formState.displayProductItems == `mydomainCon${number.id}` ? <tr><td colSpan={5}><section>


{Number(number.isready) == 0 && Number(number.remove) == 0 ? 
<Alert variant="filled" severity="info">Pending</Alert> : null}

{Number(number.isready) == 1 && Number(number.remove) == 0 ? 
<USRFns refetch={getUserAct.refetch} userid={parseInt(number.inid) === 0 ? number.itemId : number.inid} title={number.fullname} rowid={number.inid == 0 ? number.itemId : number.inid} address={number.address} id={number.inid == 0 ? number.itemId : number.inid} planid={props.id} /> : null}


{Number(number.remove) == 1 ? 
<Alert variant="filled" severity="warning">Pending Removal</Alert> : null}

</section></td></tr> : null}
</tbody>);}

  

if(getDatabase.data && getDatabase.data.getSiteList.length > 0){
databases = getDatabase.data.getSiteList.map((number, index) => <tbody key={`mydomainprod5${number.id}myHf`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`mydomainCon${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.fullname}
</td><td><SDate1 item={number.date} /></td><td>{(number.zip).toUpperCase()}</td><td>{parseInt(number.description) ? (parseFloat(number.description)/1024).toFixed(2) : 0} MB</td></tr>
{formState.displayProductItems == `mydomainCon${number.id}` ? <tr><td colSpan={5}><section>{Number(number.isready) == 0 && Number(number.remove) == 0 ? 
<Alert variant="filled" severity="info">Pending</Alert> : null}

{Number(number.isready) == 1 && Number(number.remove) == 0 ? 
<DBFns productid={parseInt(number.inid) === 0 ? number.itemId : number.inid} planid={116} type={number.zip} title={number.fullname} menu={props.menu} refetch={getDatabase.refetch} /> : null}


{Number(number.remove) == 1 ? 
<Alert variant="filled" severity="warning">Pending Removal</Alert> : null}
  
  </section></td></tr> : null}
</tbody>);}

return (
<section className='mx-5'>
<section className='pageTitle1 pb-2'>Manage {props.title}</section><section>
<Accordion expanded={expanded === 'hostDetails1'} onChange={handleChange('hostDetails1')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"hostDetails1-content"}
id={"hostDetails1-header"}
onClick={() => { props.featuresRefetch()}}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
Package Details
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section className='mb-4'><table className='customers'>
  <tbody>{features}
<tr>
<td>Server Location</td>
<td>{getFeatureValue(props.productsDetails,props.id,-1)}</td>
</tr>

<tr>
<td>Server I.P Address</td>
<td>{getFeatureValue(props.productsDetails,props.id,-2)}</td>
</tr>

<tr>
<td>PostgreSQL PgAdmin4 Connection</td>
<td>{`${getFeatureValue(props.productsDetails,props.id,-2)}:5443`}</td>
</tr>

<tr>
<td>SQL Server Management Studio Connection</td>
<td>{`${getFeatureValue(props.productsDetails,props.id,-2)}:1443`}</td>
</tr>

<tr>
<td>MySQL Work Bench Connection </td>
<td>{`${getFeatureValue(props.productsDetails,props.id,-2)}:3306`}</td>
</tr>
<tr>
<td>FTP Connection</td>
<td>{`${getFeatureValue(props.productsDetails,props.id,-2)}:21`}</td>
</tr></tbody></table></section>
</AccordionDetails></Accordion>


<Accordion expanded={expanded === 'dirfilemgt'} onChange={handleChange('dirfilemgt')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"dirfilemgt-content"}
id={"dirfilemgt-header"}
onClick={() => { rootDir.refetch()}}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
Directory / File Management
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>
<section>
{formState.domainDisplay === '' ? 
<section>
<section><FileMgr id={props.id} /></section>
</section> : null}
</section>
</AccordionDetails></Accordion>

<Accordion expanded={expanded === 'hostDetails12'} onChange={handleChange('hostDetails12')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"hostDetails12-content"}
id={"hostDetails12-header"}
onClick={() => { getOwnDomains.refetch()}}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
Hosted Domains 
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>

{formState.domainDisplay == '' || formState.domainDisplay !== 'domainDisplay' ? <>
<section className='mb-1'>
<section className="d-flex">
<section className="mr-2"><Button onClick={() => { updateInner('domainDisplay')}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Add New</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{getOwnDomains.loading ? <LoadingSK /> : <SyncIcon onClick={() => { getOwnDomains.refetch();}} className='pointer ml-3' />} 
</section></section></section>
</section>

<section className=''>
<table className='customers1'>
<thead className='thead'>
<tr><th className='editCol'>&nbsp;</th>
<th>Domain name</th><th>Created</th><th>Web Server</th><th>Document Root</th>
</tr>
</thead>
{myDomains}
</table>
</section></> : null }

{formState.domainDisplay == 'domainDisplay' ? <section>
<section className='mb-4'><Button onClick={() => { updateInner(''); getOwnDomains.refetch(); props.menurefetch()}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
<section className='mb-3 fw-bold'>Create New</section>
<section><Form tbl={63} type="form" planid={props.id} formmode={1} viewid={63} /></section>
</section> : null }
</AccordionDetails></Accordion>



<Accordion expanded={expanded === 'useracts14'} onChange={handleChange('useracts14')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"useracts14-content"}
id={"useracts14-header"}
onClick={() => { getUserAct.refetch()}}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography variant='div' className='fw-bold px-3' sx={{ width: '100%', }}>
User Accounts
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>

{formState.domainDisplay === '' || formState.domainDisplay !== 'userAccount' ? <>
<section className='mb-1'>
  
  
  
<section className="d-flex">
<section className="mr-2"><Button onClick={() => { updateInner('userAccount')}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Add New</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{getUserAct.loading ? <LoadingSK /> : <SyncIcon onClick={() => { getUserAct.refetch();}} className='pointer ml-3' />} 
</section></section></section>

</section>
<section className=''>
<table className='customers1'>
<thead className='thead'>
<tr>
<th className='editCol'>&nbsp;</th>
<th>Username</th>
<th>Created</th>
</tr>
</thead>
{userActs}
</table>
</section></> :  null }
{formState.domainDisplay === 'userAccount' ?  
<section>
<section className='mb-4'><Button onClick={() => { updateInner(''); getUserAct.refetch();}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
<section className='mb-3 fw-bold'>Create New User Account</section>
<section><Form tbl={64} type="form" planid={props.id} rowid={props.id} formmode={1} viewid={64} /></section>
</section> : null }
</AccordionDetails></Accordion>



<Accordion expanded={expanded === 'hostDetails15'} onChange={handleChange('hostDetails15')}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={"hostDetails15-content"}
id={"hostDetails15-header"}
onClick={() => { getDatabase.refetch()}}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography variant='div' className='fw-bold px-3' sx={{ width: '100%', }}>
Databases 
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>

{formState.domainDisplay === '' || formState.domainDisplay !== 'databases' ? <>
<section className='mb-1'>
<section className="d-flex">
<section className="mr-2"> <Button onClick={() => { updateInner('databases')}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Add New</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{getDatabase.loading ? <LoadingSK /> : <SyncIcon onClick={() => { getDatabase.refetch();}} className='pointer ml-3' />} </section></section></section> 
 </section>
<section className=''>
<table className='customers1'>
<thead className='thead'>
<tr>
<th className='editCol'>&nbsp;</th>
<th>Database Name</th>
<th>Created</th>
<th>Type</th>
<th>Size</th>
</tr>
</thead>
{databases}
</table>
</section></> :  null }
{formState.domainDisplay === 'databases' ?  
<section>
<section className='mb-4'><Button onClick={() => { updateInner(''); getDatabase.refetch();}} sx={{ fontSize: 11, fontWeight:'bold', }} variant="outlined" size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
<section className='mb-3 fw-bold'>Create New Database</section>
<section><Form tbl={65} type="form" planid={props.id} formmode={1} viewid={65} rowid={props.id} /></section>
</section> : null }
</AccordionDetails></Accordion>
</section></section>);}


function GetRegion(props){
const getRegions = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getRegions'}, fetchPolicy: "cache-and-network" }); 
if(getRegions && getRegions.data){
const region = getRegions.data.getSiteList.filter(function(currItem) { return parseInt(currItem.itemId) == props.id || parseInt(currItem.inid) == props.id; });
  if(region.length > 0){ return region[0].string1; }  
  if(region.length == 0){ return ''; }}
  return (<><LoadingSK /></>);
}

export {NotFound, GetPageTitles, CarTTable, SocialConnect, LoadingSK, SideMenu,  Currency, PageTitle, Restricted, AccountSecurity, ProductFeatures, AddToCart,  DropMenu, DataTable, GetUOM, DomainMgt, ServerMgt, HostingMgt, GetRegion,  ContactInfo, SDate1, TransferStatus, SDate2}
