import React, {useEffect, useRef} from "react";
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

export default function Payment(props) {
let location = useLocation();
const [formState, setFormState] = React.useState({
mode:  "none",
paypal: 0,
paymentSucess: false,
});
var token = "none";
var apiToken = "none";
if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
} 
function usePrevious(value) {
 const ref = useRef();
 useEffect(() => { ref.current = value; });
 return ref.current;
} 

/*
function testPay(){
if(updatePayment('details.id','stringify(details)')){
props.closePayment();
  props.updateCart();
}}
*/

async function updatePayment(transid,details) {
return new Promise((resolve, reject) => {
const uri = window.apiUpload;
const xhr = new XMLHttpRequest();
const fd = new FormData();
xhr.open("POST", uri, true);
xhr.setRequestHeader("Authorization", `Bearer ${token} ${apiToken}`);
xhr.onreadystatechange = function() {
if (xhr.readyState === 4 && xhr.status === 200) {
return resolve(true);
} 
};
fd.append("transid",transid);
fd.append("formid", 31);
fd.append("invoice", props.invoice);
fd.append("amount", (props.amount).toFixed(2));
fd.append("details", details);
fd.append("invdate", props.invdate);
xhr.send(fd);
});
}


const prevPaypal = usePrevious(formState.paypal);
const prevpaymentSucess = usePrevious(formState.paymentSucess);
useEffect(() => {
if(formState.paypal === 1 && prevPaypal !== formState.paypal && window.paypal){
     window.paypal.Buttons({
        style: {
          shape: 'rect',
          color: 'gold',
          layout: 'vertical',
          label: 'pay',
          
        },

        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [
			{"description":"Invoice Number: for "+ props.invoice,
			"amount":{"currency_code":"USD","value": (props.amount).toFixed(2)}}
			],
			 "items": [
        {
          
        }
      ],
          });
        },
        onApprove: function(data, actions) {
          return actions.order.capture().then(function(details) {
        
            if(updatePayment(details.id,JSON.stringify(details))){
              props.closePayment();
              props.updateCart();
            }
		//updatePayment(details.id,JSON.stringify(details)).then(function(value) { });
          });
        },

        onError: function(err) {
			console.log('error paypal');
        }
      }).render('#paypal-con');
//}// end load payment for subscription
} else {if(document.getElementById("paypal-con")){
	document.getElementById("paypal-con").innerHTML = `
	<section class="alert alert-danger mb-4" role="alert">ERROR! Loading payment form failed.</section>
	<center><button id="retryPaypal" type="button" class="btn btn-primary btn-sm centerText">Try again</button></center>
	`;
	document.getElementById("retryPaypal").addEventListener("click", retryPayment);

	}
} });
function retryPayment(){window.location.reload();}
function continuePayment(){
if(parseFloat(props.amount)){ setFormState({...formState, paypal: 1 }); }
}

return (
        <>
         {(formState.paypal == 0 && formState.paymentSucess == false) ? 
			<section className='mt-3'>
			<Button variant='contained' onClick={continuePayment}>Continue to payment</Button>
</section> : (formState.paypal == 1 && formState.paymentSucess == false) ? 

<section className='pb-5 pt-5' style={{minWidth:'60%'}}>	
<section className="my-4" id="paypal-con"></section>	
</section> : null}		

{formState.paymentSucess == true ? <section className="mt-5 mb-5 mx-5 alert alert-success" role="alert"><p>Thank you for your order; it might take some minutes for your purchase(s) to be ready</p></section> : null}
</>
);
}