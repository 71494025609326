import {useState} from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink} from "@apollo/client";
//import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { setContext } from '@apollo/client/link/context';
import Container from './components/container/container';
import {jwtDecode} from "jwt-decode";
import { Country } from './components/context/context';



window.version = "1.0";
var token = "none";
var apiToken = "none";

if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
} 



const cache = new InMemoryCache();
// await before instantiating ApolloClient, else queries might run before the cache is persisted
//await persistCache({ cache, storage: new LocalStorageWrapper(window.localStorage),});

const httpLink = createHttpLink({ uri: window.apiServer+'/graphql',});
const authLink = setContext((_, { headers }) => {
  return {
    headers: { ...headers,
      authorization: token ? `Bearer ${token} ${apiToken}` : "",
    }
  }
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  connectToDevTools: true
})


window.cartCookie = document.cookie.split("; ").find((row) => row.startsWith("dredgeCart=")) ?.split("=")[1];
function App() { 
const [country, setCountry] = useState(130);
return (
<BrowserRouter>
<ApolloProvider client={client}>
<Country.Provider value={country}>
<Container setCountry={setCountry} client={client} />
</Country.Provider>
</ApolloProvider>
</BrowserRouter>
);
}
export default App;