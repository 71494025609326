import { useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import FreeMail from '../../assets/freeemail.png';
import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CircleIcon from '@mui/icons-material/Circle';


import Button from '@mui/material/Button';
import {AddToCart} from '../../components/utility/utility';
import BizTrans from '../../assets/biztrans.png';
import Optima from '../../assets/optima.png';
import DataCenter from '../../assets/datacenter.png';
import Cyber from '../../assets/cyber.png';
import Migration from '../../assets/migration.png';
import Analytics from '../../assets/analytics.png';
import Free from '../../assets/free.png';
import Custom from '../../assets/custom.png';
import Mobile from '../../assets/mobile.png';
import Hosting from '../../assets/hosting.png';
import Domain from '../../assets/domain.png';
//import Mgt from '../../assets/mgt.png';
import {NotFound} from '../../components/utility/utility';
//import { DEF_SITE_LIST } from '../../components/gpl/gpl';
//import {useQuery} from '@apollo/client';
import Divider from '@mui/material/Divider';
import Carousel from 'react-bootstrap/Carousel';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Home(props) { 
const numbersHosting = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117 && parseInt(currItem.itemId) != 2224 && parseInt(currItem.inid) != 2224; });
const numbersServers = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 118; });

let hostingFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 117 ||parseInt(currItem.planid) == 0; });
let serverFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 118 ||parseInt(currItem.planid) == 0; });

hostingFeatures = hostingFeatures.sort((a, b) => a.total - b.total);
serverFeatures = serverFeatures.sort((a, b) => a.total - b.total);

const [formState, setFormState] = useState({ slideState: 0, slideContent: 0, });
const numbersMainPromo = props.pages.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) == 13; });




let promos = '';
for (let i = 0; i < numbersMainPromo.length; i++) {   
promos= promos+`<Carousel.Item>
${numbersMainPromo[0].description}
</Carousel.Item>`;
}



if(numbersMainPromo.length != formState.slideContent){setFormState({ ...formState, slideContent: numbersMainPromo.length });}



function passPrdId(prdId){
let listhostingFeatures = hostingFeatures.map((number, index) => 
<section key={'listhostingFeatures3989xxjkjk'+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title} </section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
{parseInt(number.itemId) === 13 ? 'Windows' : null}
{ (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) == -1) ? 'Unlimited' : null}

{parseInt(number.itemId) !== 13 && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) == 0) ? 0 : null}
{parseInt(number.itemId) !== 13 && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) != 0)  && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) != -1)? <>{getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId)}{number.zip}</> : null}
</section>
</section>
</section>
);return listhostingFeatures;}

function passServId(prdId){
let listserverFeatures = serverFeatures.map((number, index) => 
<section key={'listserverFeatures99sds244kjk'+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
<span className='text-end'>
{parseInt(number.itemId) === 13 ? null : getFeatureValue(numbersServers,(number.fullname), serverFeatures, prdId)} {number.zip}
</span>
</section>
</section>
</section>
);
return listserverFeatures;
}

function getFeatureValue(x,y,z,productid){
let colN = y;
function findNamesByFavoriteFood(list, favoriteFood) {
let fitem = list.filter((obj) => obj[colN] != '');//.map((obj) => obj.name);
let sitem = fitem.filter((obj) => (obj.itemId == productid || obj.inid == productid)).map((obj) => obj[colN]);
return sitem;}
return (findNamesByFavoriteFood(x, y));
}

function productHeaderClass(x,y=0) {
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
if(y !== 0){return 'border border-success border-2';}
return ('bg-success text-white p-2 text-center');
case 1:  
if(y !== 0){return 'border border-primary border-2';}
return ('bg-primary text-white p-2 text-center');
case 2:  
if(y !== 0){return 'border border-info border-2';}
return ('bg-info text-white p-2 text-center');
case 3: 
if(y !== 0){return 'border border-secondary border-2';}
return ('bg-secondary text-white p-2 text-center');
default:
if(y !== 0){return 'border border-primary border-2';}
return ('bg-primary text-white p-2 text-center');
}}

function productServersClass(x,y=0) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
if(y !== 0){return 'server1B';}
return ('server1 text-white p-2 text-center');
case 1:  
if(y !== 0){return 'server2B';}
return ('server2 text-white p-2 text-center');
case 2:  
if(y !== 0){return 'server3B';}
return ('server3 text-white p-2 text-center');
case 3:  
if(y !== 0){return 'server4B';}
return ('server4 text-white p-2 text-center');
default:
if(y !== 0){return 'server5B';}
return ('server5 text-white p-2 text-center');
}}


let listHosting='';
if(numbersHosting.length > 0){
listHosting = numbersHosting.map((number, index) => 
<div className='col-sm-6 col-lg-3 px-3 mb-5' key={number.id}>
<Card>
<CardActionArea>
<CardContent>
<section className={productHeaderClass(index,'b')}>
<h5 className={`${productHeaderClass(index)} mb-3 text-center`}>{number.string1}</h5>
<section className='mx-2 text-center'>
<AddToCart cart rowid={number.inid == 0 ? number.itemId : number.inid} groupid={117} type='hosting' prdname={`${number.string1}`} uom={number.string16} price={number.string4} currency={props.currency} itemId={`${number.string1}`} updateCart={props.updateCart} string1={number.fullname} string2='' string3={number.username} string4=''  string5={`${number.gender}`} string6={`${number.country}`} string7={`${number.province}`}  /></section>
<section className='mb-3 mt-3 px-2' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3 px-2'>
{passPrdId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</section>
</CardContent>
</CardActionArea>
</Card>
</div>
);}


let listServers ='';
if(numbersServers.length > 0){
listServers = numbersServers.map((number, index) => 
<div className='col-sm-6 col-lg-3 px-3 mb-5' key={number.id}>
<Card>
<h5 className={`${productServersClass(index)}  text-center`}>{number.string1}</h5>
<section className=''>
<CardActionArea>
<CardContent>
<section className='mb-1 mt-2 text-center'>
<AddToCart cart rowid={number.inid == 0 ? number.itemId : number.inid} groupid={118} type='server' prdname={`${number.string1}`} uom={number.string16} price={number.string4} currency={props.currency} itemId={`${number.string1}`} updateCart={props.updateCart} string1={number.fullname} string2={number.address} string3={number.username} string4={number.city} /></section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passServId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</section>
</Card>
</div>
);}



return (
<section className='mt-4'>
<section className='defMrg'>
{props.NotFound ? <section className='d-flex justify-content-center'><NotFound /></section> : null}
<section className='mb-5 pt-3 pb-3'>
<section className='px-4 mb-3'>
{numbersMainPromo.length > 0 ?
<Carousel>
<Carousel.Item><section className='homeText'>
<h2 className='fw-bold homeTitle text-center mb-5'>
<Link className='homeTitle' to={{ pathname: `/cat/120/108/Custom Software Development`, }}  state={{ pageTitle : 'Domain Registration', }}>Mobile Web Design</Link></h2>
<section className='d-flex homeText'>
<section className='px-3 flex-grow-1'>
<Typography className='homeText text-center mb-2'>The number of Smart phones, PDA’s and iPhones accessing the web keeps increasing at unprecedented rate</Typography>
<Typography className='homeText text-center'>A mobile web application is become a most for any business wanting to keep up with the competition.</Typography>

<p className='fw-bold mb-3 mt-4 homeText'>Overview</p>
<ul className='homeText'>
<li className='featureList pb-1 mb-1'>Highly optimized image download</li>
<li className='featureList pb-1 mb-1'>Search Engine Visibility</li>
<li className='featureList pb-1 mb-1'>Marketing Facilities</li>
<li className='featureList pb-1 mb-1'>Ecommerce Option and much more </li>
</ul>
</section>    
<section className='px-3 homePromoSec1 d-none d-md-block'><img loading='lazy' alt="Mobile Website Design" title="Mobile Website Design" src={Mobile} className="img-fluid" /></section>
</section>     
</section>
</Carousel.Item>



<Carousel.Item><section>
<h2 className='fw-bold homeTitle text-center mb-5'>
<Link className='homeTitle' to={{ pathname: `cat/116/77/Domain registration`, }}  state={{ pageTitle : 'Domain Registration', }}>DOMAIN NAME - BEST BUY RATE</Link></h2>
<section className='d-flex'>
<section className='px-3 flex-grow-1'>
<Typography className='homeText text-center mb-5 fw-bold' sx={{fontSize:'28px'}}>Register a domain and get one (1) year free hosting</Typography>

<p className='fw-bold mb-3 mt-4 homeText'>Overview</p>
<ul className='homeText'>
<li className='featureList pb-1 mb-1'>Domain Locking</li>
<li className='featureList pb-1 mb-1'>Domain Privacy</li>
<li className='featureList pb-1 mb-1'>Full DNS Control and much more</li>
</ul>
</section>    
<section className='px-3 homePromoSec1 d-none d-md-block'><img loading='lazy' alt='Domain Registration' title="Domain Registration" src={Domain} className="img-fluid" /></section>
</section>     
</section>
</Carousel.Item>


<Carousel.Item><section>
<h2 className='fw-bold homeTitle text-center mb-5'>
<Link className='homeTitle' to={{ pathname: `/cat/117/85/hosting`, }}  state={{ pageTitle : 'Domain Registration', }}>Web Hosting  (99.999% Uptime Guaranteed)</Link></h2>
<section className='d-flex'>
<section className='px-3 flex-grow-1 homeText'>
<Typography className='homeText text-center mb-2'>Host with one of the world’s best data centers equipped with state of the art network facilities ensuring high application and website availability.
</Typography>

<p className='fw-bold mb-3 mt-4 homeText'>Overview</p>
<ul className='homeText'>
<li className='featureList pb-1 mb-1'>Unlimited bandwidth</li>
<li className='featureList pb-1 mb-1'>Unlimited MySql Databases</li>
<li className='featureList pb-1 mb-1'>Easy Ecommerce Intergration</li>
<li className='featureList pb-1 mb-1'>Unlimited Websites & Email Accounts and much more</li>
</ul>
</section>    
<section className='px-3 homePromoSec1 d-none d-md-block'><img loading='lazy' alt='100% Uptime Web Hostimg' title="100% Uptime Web Hostimg" src={Hosting} className="img-fluid" /></section>
</section>     
</section>
</Carousel.Item>
</Carousel>
: null}
</section>
</section>
</section>




<section className='defMrg'>
<h4 className='mb-3'>
<section className="d-flex align-items-center">
<span className="mx-2">Web Hosting</span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center">
<Link onClick={ () => window.topTop() } to={{ pathname: `/cat/117/85/hosting`, }}  state={{ pageTitle : 'Web Hosting', }}>
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small" endIcon={<VisibilityIcon fontSize="small" />}>View all features</Button>
</Link>
</div>
</section>
</section>
</h4>
<section className='row'>{listHosting}</section>
</section>
<section className='row m-5 mt-2 text-center'>
<section className='col-lg-6 p-4'>
<Link onClick={ () => window.topTop() } to={{ pathname: `/cat/117/2125/Free Web and Email Hosting`, }}  state={{ pageTitle : 'Servers', }}><img loading='lazy' alt='Free Web Hosting and Email Hosting' title="Free Web Hosting and Email Hosting" src={Free} className="img-fluid" />
</Link></section>
<section className='col-lg-6 p-4'>
<Link onClick={ () => window.topTop() } to={{ pathname: `/cat/117/2125/Free Web / Email Hosting`, }}  state={{ pageTitle : 'Servers', }}><img loading='lazy' alt='Free Web Hosting and Email Hosting' title="Free Web Hosting and Email Hosting" src={FreeMail} className="img-fluid" />
</Link></section>
</section>


<section className='bg-white defPad'>
<h4 className='mb-3'>
<section className="d-flex align-items-center p-1">
<span className="mx-2">Servers</span>
<section className="flex-grow-1 d-flex flex-column">
<div className="flex-fill d-flex flex-row-reverse align-items-center">
<Link onClick={ () => window.topTop() } to={{ pathname: `/cat/118/100/servers`, }}  state={{ pageTitle : 'Servers', }}>
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small" endIcon={<VisibilityIcon fontSize="small" />}>View all features</Button>
</Link>
</div>
</section>
</section>
</h4>
<section className='row'>{listServers}</section>
</section>
<Divider className='pb-5 defMrg' />




<section style={{backgroundImage: `url(${DataCenter})`, backgroundRepeat:'no-repeat', backgroundSize: 'cover', minHeight:'200px'}} className='defPad mb-5'>
<section className='equalWCon p-4'>
<section className='col35 mx-2 p-3 text-center text-white teaserHome'>
<Typography variant='h5' className='m-3 fw-bold'><u>Server Locations on Amazon Infrastructure</u></Typography>
<section className='mb-2 fw-bold'><span className='px-2'>Ohio, USA <CircleIcon className='mx-2' sx={{color:'green', fontSize:16}} />
</span><span className='px-2'>London, UK <CircleIcon className='mx-2' sx={{color:'#F4D173', fontSize:16}} /></span></section>

<section className='mb-2 fw-bold'><span className='px-2'>Oslo, Norway  <CircleIcon className='mx-2' sx={{color:'#F4D173', fontSize:16}} /></span><span className='px-2'>Lagos, Nigeria  <CircleIcon className='mx-2' sx={{color:'#F4D173', fontSize:16}} /></span></section>
</section> 
<section className='col65 mx-2 p-3 text-center text-white teaserHome'>
<Typography variant='h5' className='fw-bold mb-2'>Power. Security. Performance. 🔥</Typography>  
<Typography>Unlock <strong>ultra-reliable and high-performance hosting</strong> with our state-of-the-art <strong>data solutions</strong>. From enterprise cloud infrastructure to dedicated servers, we deliver unmatched speed, security, and scalability — so you can focus on growth while we handle the rest.</Typography> 
<Typography variant='h6' className='mb-2 mt-3'>🚀 99.99% Uptime | 🔒 Tier-4 Security | ⚡ Global Connectivity</Typography> 
</section>
</section>
</section>




<section className='consultancy defPad pt-5 pb-5'>
<h4 className='col-12 mb-3'>Information Technology Consultancy</h4>
<h6 className='mb-4'>Let us take care of your digital solutions need while you concentrate on your core business  goals</h6>


<section className='row'>
<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Digital Transformation Strategy' title="Digital Transformation Strategy" src={BizTrans} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Digital Transformation Strategy
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Unlock the full potential of your business with our Digital Transformation Strategy service. We work with you to identify key areas for digital improvement, design a tailored transformation roadmap, and implement cutting-edge technologies to streamline operations and enhance customer experiences. Our approach ensures that your organization stays ahead of the curve in a rapidly evolving digital landscape</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center>
</Card>
</section>

<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='IT Infrastructure Optimization' title="IT Infrastructure Optimization" src={Optima} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
IT Infrastructure Optimization 
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Maximize the efficiency and performance of your IT infrastructure with our Optimization service. We analyze your current systems, recommend improvements, and implement best practices to enhance scalability, security, and reliability. From server management to network enhancements, our solutions are designed to ensure your infrastructure supports your business goals effectively</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Cybersecurity Assessment and Solutions' title="Cybersecurity Assessment and Solutions" src={Cyber} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Cybersecurity Assessment and Solutions
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Protect your business from cyber threats with our comprehensive Cybersecurity Assessment and Solutions. We conduct thorough assessments to identify vulnerabilities, develop robust security protocols, and implement advanced threat detection systems. Our solutions are tailored to safeguard your data and ensure compliance with industry standards</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 col-lg-3 px-2 mb-5'>
<Card className='m-1'>
<img loading='lazy' alt='Cloud Solutions and Migration' title="Cloud Solutions and Migration" src={Migration} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Cloud Solutions and Migration
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Transition to the cloud seamlessly with our Cloud Solutions and Migration service. We provide end-to-end support, from selecting the right cloud platform to migrating your data and applications. Our solutions offer increased flexibility, cost efficiency, and scalability, ensuring that your business leverages the full potential of cloud technology</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



<section className='col-sm-6 px-2 mb-5 d-lg-none'>
<Card className='m-1'>
<img loading='lazy' alt='Business Intelligence and Analytics' title="Business Intelligence and Analytics" src={Analytics} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Business Intelligence and Analytics
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Transform your data into actionable insights with our Business Intelligence and Analytics service. We implement advanced BI tools and analytics platforms to help you make informed decisions. Our solutions include data visualization, predictive analytics, and reporting to drive strategic business growth and operational efficiency</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>


<section className='col-sm-6 px-2 mb-5 d-lg-none'>
<Card className='m-1'>
<img loading='lazy' alt='Custom Software Development' title="Custom Software Development" src={Custom} className="img-fluid" />
<CardContent>
<Typography gutterBottom variant="h5" component="div">
Custom Software Development
</Typography>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
Tailor-made solutions to meet your unique business needs with our Custom Software Development service. Our team works closely with you to design, develop, and deploy software applications that align with your operational requirements. Whether it&acute;s a bespoke application or system integration, we deliver solutions that enhance your business processes</Typography>
</CardContent>
<center className='m-3 text-center'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></center></Card>
</section>



</section>
</section>


</section>
);}