/* eslint-disable */ 
import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Form from '../../components/form/form';
import Alert from '@mui/material/Alert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SyncIcon from '@mui/icons-material/Sync';
import HomeIcon from '@mui/icons-material/Home';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import {LoadingSK, SDate1} from '../../components/utility/utility';
import {FullScreenDialog} from '../../components/utility/filemgr';
//import RestoreIcon from '@mui/icons-material/Restore';
//import ScheduleIcon from '@mui/icons-material/Schedule';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircleIcon from '@mui/icons-material/Circle';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery, useLazyQuery} from '@apollo/client';
import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
//import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';

function GetConfSettings(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getsitesettings' , arg2:props.rowid, arg4:props.domain, arg3:`${(props.webserver)[0]}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', });

function updateInner(x){ setFormState({ ...formState, display: formState.display==x ? '' : x }); }
let confSettings=<LoadingSK />
if(htfile.data){
  console.log(htfile.data)
if(htfile.data.getSiteList.length > 0){
confSettings = htfile.data.getSiteList.map((number, index) => <section className={(parseInt(number.itemId)==8) ? 'col-12 datarow1 pb-4 pt-4' : 'col-md-6 datarow pb-4 pt-4'} key={`listsettings${number.id}`}>
{parseInt(number.itemId)==21 ? <section>
<section><MoreVertIcon sx={{mr:2}} onClick={() => { updateInner(`display${number.itemId}`)}} className='pointer' /> {number.string1} {number.status} {number.string2}</section>  
{formState.display==`display${number.itemId}` ? 
<section className='mt-4'><TrackDNS webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} /></section> 
: null}</section> : null} 


{parseInt(number.itemId)==15 ? <section>
<section><MoreVertIcon sx={{mr:2}} onClick={() => { updateInner(`display${number.itemId}`)}} className='pointer' />{number.string1}</section>  
{formState.display==`display${number.itemId}` ?  
<section className='mt-4'><IPBlocker webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} fullname={number.address} /></section> : null}
</section> : null} 

{parseInt(number.itemId)==8 ? <section>
<section><MoreVertIcon sx={{mr:2}} onClick={() => { updateInner(`display${number.itemId}`)}} className='pointer' />{number.string1}</section>   
{formState.display==`display${number.itemId}` ? 
<section className='nt-4'><EnvVar webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} fullname={number.province} /></section> : null}
</section> : null} 

{parseInt(number.itemId)== 14 ? <section>
<section><MoreVertIcon sx={{mr:2}} onClick={() => { updateInner(`display${number.itemId}`)}} className='pointer' />{number.string1}</section>  
{formState.display==`display${number.itemId}` ?  
<section className='mt-4 mb-4'><AppRedirect webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} fullname={number.description} /></section> : null}
</section> : null} 

{parseInt(number.itemId)== 10 ? <section>
<section><MoreVertIcon sx={{mr:2}} onClick={() => { updateInner(`display${number.itemId}`)}} className='pointer' />{number.string1}</section>  
{formState.display==`display${number.itemId}` ?  
<section className='mt-4'>
<EntryFile webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} fullname={number.fullname} /></section> : null} 
</section> : null} 
  
{[21,15,8,14,10].includes(number.itemId) ?  null :
<section className=''><section className='mb-3'>
{Number(number.itemId) != 11 ? number.string1 : null}

{Number(number.itemId) == 11 ? <>{parseInt(number.status)!=0 ? 
<Button type="submit" sx={{ fontWeight:'bold', }} variant="contained" color='success' startIcon={<CheckCircleIcon />}>App State: Running</Button> : 
<Button type="submit" sx={{ fontWeight:'bold', }} variant="contained" color='default' startIcon={<StopIcon />}>App State: Stopped</Button>}</> : null} 

</section><section className=''>
{Number(number.itemId) == 11 ? <AppState webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} status={number.status} refetch={htfile.refetch} /> : null}


{Number(number.itemId) == 16 ? <HotLink webserver={props.webserver} id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} status={number.groupid} /> : null}
{number.title}</section></section>
}
</section>);
return (<section className='row'>{confSettings}</section>);
}} 
return (confSettings);
}


function GetBackUp(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getbackups' , arg2:props.id, arg3:`${(props.domain).toLowerCase()}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', });
function updateInner(x){ setFormState({ ...formState, display: x }); }
let details;
if(htfile.data){
if(htfile.data.getSiteList.length > 0){
  details = <>
<section className='fw-bold pageTitle mb-2'>Current .htaccess file</section><section className='ck-content codeCon'><pre className='fancy-code'><code>{htfile.data.getSiteList[0].description}</code></pre></section></>;
}}

return (
<section>
{formState.display == '' ? <>
<section><Button onClick={() => { updateInner('createbackup')}} sx={{ fontSize: 11, fontWeight:'bold', }} className='mb-1' variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Create Backup</Button>
</section> 
<table className="customers">
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Title / Created</th>
<th>Size</th>
<th>&nbsp;download</th>
<th>&nbsp;delete</th>
</tr>
</thead>
</table>
</> : null} 

{formState.display == 'createbackup' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner('');}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>

<Alert variant="filled" severity="info">This process will backup all files in the domain root directory. It might take a few moments for large backups to be ready, the backup will be available upon completion
</Alert>
<section className='mt-3'>
<Form type="form" formmode={1} tbl={73} gender={1} alert='info' savebtn='success' title={props.domain} label={`Backup Now: ${(props.domain).toUpperCase()}`} viewid={73} rowid={props.id} refetch={htfile.refetch} />
</section>
</section> : null}

{formState.display == 'schedulebackup' ? <section>ths is the <section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner('');}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
<Alert variant="filled" severity="info">This process will backup all files in the domain root directory
</Alert>
<section className='mt-3'>
<Form type="form" formmode={1} tbl={74} alert='info' savebtn='success' title={props.domain} label={`Save`} viewid={74} rowid={props.id} /></section></section> : null}
</section>);}




function GetCron(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 57, arg1:'crondata' , arg2:props.planid, arg3:`${props.rowid}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', form:'' });
function updateInner(x){ setFormState({ ...formState, form: x }); }
function updateDisplay(x){
setFormState({ ...formState, display: (formState.display == x ? '' : x)});
}
let listItems='';
if(htfile.data){
if(htfile.data.getSiteList.length > 0){
listItems = htfile.data.getSiteList.map((number, index) => <tbody key={`${number.id}cronlist`}><tr>
  <td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon className='pointer' onClick={() => { updateDisplay(`${number.id}cronlist`)}} /></Tooltip></td><td>{number.fullname}</td><td> {parseInt(number.total) == 1 ? 'Minutes': null} {parseInt(number.total) == 2 ? 'Hourly': null} {parseInt(number.total) == 3 ? 'Daily': null} {parseInt(number.total) == 4 ? 'Weekly': null}  {parseInt(number.total) == 5 ? 'Monthly': null} / {number.string2}</td><td style={{width:'30px'}}>
  {parseInt(number.broken) == 2 ? <CircleIcon sx={{fontSize:'13px'}} /> : <CircleIcon sx={{color:'green', fontSize:'13px'}} />}
  
</td></tr>
{formState.display === `${number.id}cronlist` ? <tr><td colSpan={4}>
{parseInt(number.remove) === 1 ? <Alert className='mb-3 mx-3' variant="filled" severity="warning">Pending Delete
  </Alert> : <section className='m-3 mb-5'>
<section className='mb-3'>Enable:   <Form type="form" formmode={1} tbl={101} rowid={Number(number.inid) == 0 ? number.itemId : number.inid} viewid={101} itemstatus={number.broken} refetch={htfile.refetch} /></section>
<Divider />
<section className='mt-1 mb-4'><Form type="form" formmode={1} tbl={100} rowid={Number(number.inid) == 0 ? number.itemId : number.inid} viewid={32} refetch={htfile.refetch} label='Delete Cron' /></section>
</section>}
</td></tr> : null}
</tbody>);
}}

return (<>

{formState.form == '' ? 
<><section className="d-flex">
<section className="mr-2"><Button onClick={() => { updateInner('createcron')}} sx={{ fontSize: 11, fontWeight:'bold', }} className='mb-1' variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Create Cron</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{htfile.loading ? <LoadingSK /> : <SyncIcon onClick={() => { htfile.refetch();}} className='pointer ml-3' />} 
</section></section></section>
<table className="customers">
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>File Path / URL Address</th><th>Frequency</th><th style={{width:'30px'}}>&nbsp;</th></tr>
</thead>{listItems}</table>
</> : null}



{formState.form == 'createcron' ? <>
<section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch();}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button>
</section><section><Form type="form" formmode={1} tbl={57} gender={2} alert='info' savebtn='success' title={props.domain} label={`Save`} planid={props.planid} viewid={57} itemstatus={1} rowid={props.rowid} string1={props.string1} refetch={htfile.refetch} /></section></>
 : null}
 </>);}

function AppState(props) { 
const [open, setOpen] = React.useState(false);
const handleClickOpen = (x,y='') => { 
setFormState({ ...formState, cmd:x, title:y });
setOpen(true); };
const handleClose = () => { setOpen(false); };


const [formState, setFormState] = useState({
pm2Address: "", pm2Status:null, pm2Msg:'', pm2Close:'', cmd:'', title:''});

function usePrevious(value) { const ref = useRef(); 
useEffect(() => { ref.current = value; }); return ref.current; } 
const prevpm2Address = usePrevious(formState.pm2Address);

useEffect(() => {
if(formState.pm2Close != ''){handleClose();} 
}, [formState.pm2Close]); // Only re-run the effect if props.rowid changes

useEffect(() => {
if(formState.pm2Address != '' && prevpm2Address != formState.pm2Address && formState.cmd == 'install'){
 } 
}, [formState.pm2Address]); // Only re-run the effect if props.rowid changes

function pm2Close(x){
if(x != formState.pm2Close){
setFormState({ ...formState, pm2Close:x });
props.refetch();
}}

let appState=(<>
<section className='d-inline mx-1 pm2Con'>
<Dialog 
fullWidth
maxWidth="md"
open={open}
aria-labelledby="alert-dialog-title"
aria-describedby="alert-dialog-description"
>
<DialogTitle id="alert-dialog-title" className='m-0 p-0'>
<AppBar position="static" className='p-3'>{formState.title}</AppBar>
</DialogTitle>
<section className='m-4'>
<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} address={formState.cmd} rowid={props.rowid} status={props.status} itemstatus={props.status} planid={props.planid} tbl={149} title={props.string1} viewid={149} />
</section>
<DialogActions onClick={(e) => e.stopPropagation()}>
<Button onClick={() => { pm2Close(Date.now())}} autoFocus>
Close
</Button>
</DialogActions>
</Dialog>

<section className='d-flex d-inline'>

{parseInt(props.status)!=0 ? <span className='pointer px-1'><Tooltip title="Stop" arrow={true}><StopIcon onClick={() => { handleClickOpen('stop','You are about to stop the app')}} /></Tooltip></span> : null}
{parseInt(props.status)==0 ? <span className='pointer px-1'><Tooltip title="Start" arrow={true}><PlayArrowIcon onClick={() => { handleClickOpen('start','You are about to start the app')}} /></Tooltip></span> : null}
{parseInt(props.status)!=0 ? <span className='pointer px-1'><Tooltip title="Restart" arrow={true}><RestartAltIcon onClick={() => { handleClickOpen('restart','You are about to restart the app')}} /></Tooltip></span> : null}
<span className='px-3'><Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} status={props.groupid} itemstatus={props.groupid} planid={props.planid} tbl={163} title={props.string1} viewid={163} switchType={true} textOn='Production' textOff='Development' /></span>
</section>
</section>
</>);
return (appState);}

function EnvVar(props) {
const getEnv = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getenv', arg2:Number(props.rowid)}, fetchPolicy: "cache-and-network" });
const [formState, setFormState] = useState({ display:0, });
function displayProductItems(x){ setFormState({ ...formState, display: x }); }
let envVar = (<tr><td><LoadingSK /></td></tr>);
if(getEnv.error){envVar=getEnv.error.message}
if(getEnv.data){
let numbers=getEnv.data.getSiteList;
envVar = numbers.map((number, index) => <tr key={`${number.id}listvar`}>
<td className='editCol'>
{parseInt(number.remove)==1 ? null : <Form refetch={getEnv.refetch} type="form" fullname={props.domain} tbl={161} string1={(props.string1)} rowid={number.itemId} viewid={32} />}</td><td>
{parseInt(number.remove)==1 ? <strike>{number.string3}</strike> : number.string3}</td>
<td>{parseInt(number.remove)==1 ? <strike>{number.string2}</strike> : number.string2}</td></tr>);}

return (
  <section className='mt-3'>
  {parseInt(formState.display)==0 ? 
  <section><table className="customers">
  <thead className='thead'><tr>
  <th className='editCol'>&nbsp;</th><th>Name</th><th>Value</th></tr></thead>
  <tbody>{envVar}</tbody></table>
  <section><Button
  variant="outlined"
  color="success"
  onClick={() => { displayProductItems(2)}}
  size="small"
  sx={{mt:2, mx:2}}
  startIcon={<AddIcon />}
  >Add new</Button></section></section>
  : null}
  
  {parseInt(formState.display)==2 ? 
  <section>
  <section><Button
  variant="outlined"
  color="success"
  onClick={() => { displayProductItems(0); getEnv.refetch()}}
  size="small"
  startIcon={<CloseIcon />}
  >Close</Button></section>
  <section className='mt-1'>
  <Form type="form" id={props.id} domain={props.domain} rowid={props.rowid} planid={props.planid} tbl={161} title={props.string1} viewid={161} />
  </section>
  </section>
  : null}
  </section>
  );
}

 
function EntryFile(props) {  
return (<>
<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} fullname={props.fullname} planid={props.planid} tbl={151} title={props.string1} viewid={151} label='Startup Script' />
</>);}
  

function TrackDNS(props) { 
return (<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} tbl={153} title={props.string1} viewid={153} btn='Lookup IP' label='Domain name to Lookup (e.g. yourname.com)' />
);}

function IPBlocker(props) { 
return (<>
<section className='mb-3'> <Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} status={props.groupid} itemstatus={props.groupid} switchType={true} textOn='Allow' textOff='Deny' planid={props.planid} tbl={160} title={props.string1} viewid={160} /> </section>
<section>
<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} planid={props.planid} tbl={158} title={props.string1} viewid={158} label='I.P Address separated by comma' />
</section>
</>
);}


function HotLink(props) {  
return (<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} switchType={true} textOn='On' textOff='Off' rowid={props.rowid} status={props.groupid} itemstatus={props.groupid} planid={props.planid} tbl={154} title={props.string1} viewid={154} />
);}
  

function AppRedirect(props) {  
return (
<>
<section className='mb-3'> <Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} status={props.groupid} itemstatus={props.groupid} planid={props.planid} tbl={159} title={props.string1} viewid={159} switchType={true} textOn='Permanent' textOff='Temporary' /> </section>
<section>
<Form type="form" id={props.id} domain={props.domain} string1={(props.string1)} rowid={props.rowid} fullname={props.fullname} planid={props.planid} tbl={152} title={props.string1} viewid={152} label='I.P Address or URL (https://yourdomain.com)' /></section>
</>);}
    
export default function HostFunction(props) {  
let functionL = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === 116; });
let functionList = functionL.sort(function(a, b){return a.title.localeCompare(b.title);});
const getList = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'hostfunctions' , arg2:176}, fetchPolicy: "cache-and-network" }); 

const [expanded, setExpanded] = React.useState(null);
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};
const [formState, setFormState] = useState({ displayAddForm: '', });
function showAddFrm(x){ setFormState({ ...formState, displayAddForm: x }); }
let listFunctions = functionList.map((number, index) => 
<Accordion key={`${number.id}listdomainfunctions`} expanded={expanded === `${index}listdnfunctions`} onChange={handleChange(`${index}listdnfunctions`)}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={`${index}listdnfunctions-content`}
id={`${index}listdnfunctions-header`}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>
{number.title}</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
> <section>  
 <GetList functionId={number.inid == 0 ? number.itemId : number.inid} productid={props.productid} title={props.title} string1={props.title} planid={props.productid} rowid={props.rowid} webserver={props.webserver} /></section>
</AccordionDetails></Accordion>);


return ( 
<section className='mt-2 mb-4 bg-white p-3'>
<Typography className='fw-bold pageTitle mb-3 pb-1'> Manage: {props.title}</Typography>
<section>
<Accordion expanded={expanded === `HostingDetails`} onChange={handleChange(`HostingDetails`)}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={`HostingDetails-content`}
id={`HostingDetails-header`}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Hosting Details
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
> <section>
<table className='customers'>
<tbody>
<tr><td>URL Address</td><td><a href={`https://${(props.title).toLowerCase()}`} rel='noopener nofollow' target='_blank'>{`https://${(props.title).toLowerCase()}`} <OpenInNewIcon /></a></td></tr>
<tr><td>Webmail Address</td><td><a href={`https://webmail.${(props.title).toLowerCase()}`} rel='noopener nofollow' target='_blank'>Open <OpenInNewIcon /></a></td></tr>
<tr><td>Web Server</td><td>{(props.webserver).toUpperCase()}</td></tr>
<tr><td>phpMyAdmin</td><td><a href={`https://phpmyadmin.${(props.title).toLowerCase()}`} rel='noopener nofollow' target='_blank'>Open <OpenInNewIcon /></a>
</td></tr>
<tr><td>Name servers</td><td><a href={`/help/con/Updating Name Servers/4050`} rel='noopener nofollow' target='_blank'>View Instruction <OpenInNewIcon /></a></td></tr>
<tr><td>SSMS Connection</td><td><a href={`/help/con/SSMS Connection/5281`} rel='noopener nofollow' target='_blank'>View Instruction <OpenInNewIcon /></a></td></tr>
<tr><td>pgAdmin4 Connection</td><td><a href={`/help/con/pgAdmin Connection/5282`} rel='noopener nofollow' target='_blank'>View Instruction <OpenInNewIcon /></a></td></tr>
<tr><td>MySql Workbench Connection</td><td><a href={`/help/con/MySql Workbench Connection/5280`} rel='noopener nofollow' target='_blank' >View Instruction <OpenInNewIcon /></a></td></tr>
<tr><td>FTP Connection</td><td><a href={`/help/con/Updating%20Name%20Servers/4050`} rel='noopener nofollow' target='_blank'>View Instruction <OpenInNewIcon /></a></td></tr>

<tr><td>Web Traffic Report</td><td><a href={`https://report.${(props.title).toLowerCase()}`} rel='noopener nofollow' target='_blank'>View Traffic <OpenInNewIcon /></a></td></tr>
</tbody></table>
</section>
</AccordionDetails></Accordion>
{listFunctions}
<Accordion expanded={expanded === `deletedomain`} onChange={handleChange(`deletedomain`)}>
<AccordionSummary 
expandIcon={<ExpandMoreIcon />} 
aria-controls={`deletedomain-content`}
id={`deletedomain-header`}
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
<Typography className='fw-bold px-3' sx={{ width: '100%', }}>Delete Domain
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
> 
<Alert variant="filled" className='mb-3' severity="warning">This action will remove the domain, associated sub domains and directories
</Alert>
<Form type="form" formmode={1} tbl={63} rowid={props.rowid} viewid={32} refetch={props.refetch} label={`Delete Domain: ${props.title}`} />
</AccordionDetails> 
</Accordion> 
</section>
</section> 
);
}



function DBFns(props) {  
  let functionList = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) === parseInt(props.planid); });
  const getList = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'176' , arg2:176}, fetchPolicy: "cache-and-network" }); 
  const [expanded, setExpanded] = React.useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
  setExpanded(newExpanded ? panel : false);
  };
  const [formState, setFormState] = useState({ displayAddForm: '', });
  function showAddFrm(x){ setFormState({ ...formState, displayAddForm: x }); }
  
  let listItems = functionList.map((number, index) => 
  <Accordion key={`${number.id}listdomainfunctions`} expanded={expanded === `${index}listdnfunctions`} onChange={handleChange(`${index}listdnfunctions`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`${index}listdnfunctions-content`}
  id={`${index}listdnfunctions-header`}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>
  {number.title} {number.inid == 0 ? number.itemId : number.inid} okay
  </Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  >
  
inid {number.inid} itemid {number.itemId}
  {formState.displayAddForm == `${index}functionCon` ? <section className='mb-2'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { showAddFrm(''); getList.refetch()}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
   : <><section className='mb-1'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { showAddFrm(`${index}functionCon`);}}  variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Add New</Button></section>
   <GetList functionId={number.inid == 0 ? number.itemId : number.inid} productId={props.productid} title={props.title} rowid={number.inid == 0 ? number.itemId : number.inid} planid={props.productid} string1={props.title} />
   </>}
   </AccordionDetails></Accordion>);
  return ( 
  <section className='mt-2 mb-4 bg-white p-3'>
  <Accordion expanded={expanded === `deletedb`} onChange={handleChange(`deletedb`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`deletedb-content`}
  id={`deletedb-header`}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>Drop Database: {props.title}</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  ><Form type="form" formmode={1} tbl={76} title={props.title} label={`Drop Database: ${props.title}`} zip={props.type} rowid={props.productid} viewid={76} id={props.productid} refetch={props.refetch} /></AccordionDetails></Accordion>
  </section> 
  );
  }



function USRFns(props) {  
const [formState, setFormState] = useState({ selectedDB:0, dbType:'', homedirectory:'' });
const userRigths = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getRights', arg2:Number(props.userid), arg3:String(formState.selectedDB)}, fetchPolicy: "network-only" });  
const userHMDir = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getHMDir', arg2:parseInt(props.userid)}, fetchPolicy: "network-only" }); 
const getDB = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getDB', arg2:props.planid}, fetchPolicy: "cache-and-network" });
const [expanded, setExpanded] = React.useState(null);
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);
};

function reset(){ userHMDir.refetch(); }
if(userHMDir && userHMDir.data &&  userHMDir.data.getSiteList.length > 0){
if(formState.homedirectory != userHMDir.data.getSiteList[0].address){setFormState({ ...formState, homedirectory: userHMDir.data.getSiteList[0].address});}}

function selectDB(e){
let value = e.target.value;
let name = e.target.name;
DBRights=<section className='p-3'><LoadingSK /></section>;
DBRightsMSSql=<section className='p-3'><LoadingSK /></section>;
DBRightsPgSql=<section className='p-3'><LoadingSK /></section>;
if(e.target.type === 'selected'){ value = e.target.selected.value; }  
setFormState({ ...formState, selectedDB: value, dbType:name });
}

function iniselectDB(x){ 
getDB.refetch();
setFormState({ ...formState, selectedDB: 0 }); }
function getDBA(x){
let listItems;
if(getDB.data && getDB.data.getSiteList.length > 0){
const numbers = getDB.data.getSiteList.filter(function(currItem) { return currItem.zip === x; });
listItems = numbers.map(number => <option key={`listdb${number.id}`} value={parseInt(number.inid) != 0 ? number.inid : number.itemId}>{number.fullname}</option>);}
return listItems;
}

let FTPRights;
 
let DBRights=<tr><td colSpan={2} className='p-3'><LoadingSK /></td></tr>;
let DBRightsPgSql=<tr><td colSpan={2} className='p-3'><LoadingSK /></td></tr>;
let DBRightsMSSql=<tr><td colSpan={2} className='p-3'><LoadingSK /></td></tr>;
if(userRigths.data && userRigths.data.getSiteList.length > 0){
let sortFTPR = userRigths.data.getSiteList.filter(function(currItem) { return parseInt(currItem.groupid) === 72; }).sort(function(a, b){return a.fdorder - b.fdorder});

let sortDBR = userRigths.data.getSiteList.filter(function(currItem) { return parseInt(currItem.groupid) === 65; }).sort(function(a, b){return a.fdorder - b.fdorder});

let sortDBRPgSql = userRigths.data.getSiteList.filter(function(currItem) { return parseInt(currItem.groupid) === 66; }).sort(function(a, b){return a.fdorder - b.fdorder});

let sortDBRMSSql = userRigths.data.getSiteList.filter(function(currItem) { return parseInt(currItem.groupid) === 67; }).sort(function(a, b){return a.fdorder - b.fdorder});

DBRights= sortDBR.map(number =>  <tr key={`${window.uuidv4()}ftprigths${number.id}`}><td>{number.title}</td><td>
    <SValue type={1} inputid={number.id} zip={formState.dbType} settingid={number.inid == 0 ? number.itemId : number.inid} rowid={number.inid == 0 ? number.itemId : number.inid} planid={parseInt(formState.selectedDB)} useractid={parseInt(props.userid)} status={number.status} refetch={userRigths.refetch} />
    </td></tr> );
DBRightsPgSql= sortDBRPgSql.map(number =>  <tr key={`${window.uuidv4()}ftprigths${number.id}`}><td>{number.title}</td><td>
    <SValue type={1} inputid={number.id} zip={formState.dbType} settingid={number.inid == 0 ? number.itemId : number.inid} rowid={number.inid == 0 ? number.itemId : number.inid} planid={parseInt(formState.selectedDB)} useractid={parseInt(props.userid)} status={number.status} refetch={userRigths.refetch} />
    </td></tr> );

DBRightsMSSql= sortDBRMSSql.map(number =>  <tr key={`${window.uuidv4()}ftprigths${number.id}`}><td>{number.title}</td><td>
    <SValue type={1} inputid={number.id} zip={formState.dbType} settingid={number.inid == 0 ? number.itemId : number.inid} rowid={number.inid == 0 ? number.itemId : number.inid} planid={parseInt(formState.selectedDB)} useractid={parseInt(props.userid)} status={number.status} refetch={userRigths.refetch} />
    </td></tr> );

FTPRights= sortFTPR.map(number =>  <tr key={`${window.uuidv4()}ftprigths${number.id}`}><td>
{number.title}</td><td>
<SValue type={1} zip={formState.dbType} settingid={number.inid == 0 ? number.itemId : number.inid} rowid={number.inid == 0 ? number.itemId : number.inid} planid={parseInt(formState.selectedDB)} useractid={parseInt(props.userid)} status={number.status} refetch={userRigths.refetch} /></td></tr> );

}

return ( 
  <section className='mt-2 mb-4 bg-white p-3'>
  <Typography className='fw-bold pageTitle mb-3 pb-1'> Manage User: {props.title}</Typography>
  <Accordion expanded={expanded === `dbusers`} onChange={handleChange(`dbusers`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`dbusers-content`}
  id={`dbusers-header`}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>Change Password</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  > 
<Form type="form" formmode={2} tbl={64} rowid={props.userid} id={props.userid} viewid={64} /></AccordionDetails></Accordion>

<Accordion expanded={expanded === `dbmysqlrigths`} onChange={handleChange(`dbmysqlrigths`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`dbmysqlrigths-content`}
  id={`dbmysqlrigths-header`}
  onClick={() => { iniselectDB(0); userRigths.refetch();}}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>MySQL  Database Privileges</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
>   
<section className='mb-1 mt-3 fw-bold'>Select Database</section>
<section className='mb-2'>
<select className="form-control" id='mysql' name='mysql' value={formState.selectedDB} onChange={selectDB} ><option value={0}> --Select-- </option>
{getDBA('mysql')}
</select>
</section>

  {formState.selectedDB != 0 ? 
  <><table className='customers1'>
  <thead className='thead'>
  <tr>
  <th>Privilege</th>
  <th>Enable</th>
  </tr>
  </thead>
  <tbody>{DBRights}</tbody>
  </table></>
  : null}</AccordionDetails></Accordion>

  
<Accordion expanded={expanded === `dbpgrigths`} onChange={handleChange(`dbpgrigths`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`dbpgrigths-content`}
  id={`dbpgrigths-header`}
  onClick={() => { iniselectDB(0); userRigths.refetch();}}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>PostgreSQL Database Privileges</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  >   
   
<section className='mb-1 mt-3 fw-bold'>Select Database</section>
<section className='mb-2'>
<select className="form-control" id='mysql' name='pgsql' value={formState.selectedDB} onChange={selectDB} ><option value={0}> --Select-- </option>
{getDBA('pgsql')}
</select>
</section>

  {formState.selectedDB != 0 ? 
  <table className='customers1'>
  <thead className='thead'>
  <tr>
  <th>Privilege</th>
  <th>Enable</th>
  </tr>
  </thead>
  <tbody>{DBRightsPgSql}</tbody>
  </table>
  : null}
  </AccordionDetails></Accordion>
<Accordion expanded={expanded === `dbmsrigths`} onChange={handleChange(`dbmsrigths`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`dbmsrigths-content`}
  id={`dbmsrigths-header`}
  onClick={() => { iniselectDB(0); userRigths.refetch();}}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>MSSQL Database Privileges</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  >   

 
<section className='mb-1 mt-3 fw-bold'>Select Database</section>
<section>
<select className="form-control" id='mysql' name='mssql' value={formState.selectedDB} onChange={selectDB} ><option value={0}> --Select-- </option>
{getDBA('mssql')}
</select>
</section>

  {formState.selectedDB != 0 ? 
  <table className='customers1'>
  <thead className='thead'>
  <tr>
  <th>Role</th>
  <th>Enable</th>
  </tr>
  </thead>
  <tbody>{DBRightsMSSql}</tbody>
  </table>
  : null}
  </AccordionDetails></Accordion>


<Accordion expanded={expanded === `ftprights`} onChange={handleChange(`ftprights`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`ftprights-content`}
  id={`ftprights-header`}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>FTP Privileges</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  >
<section className='mb-3'>
<section className='mb-3'><HomeIcon /> Home Directory:  {formState.homedirectory == '' ? '/' : null}
{(formState.homedirectory).replace(new RegExp([`hst${props.planid}`].join('|'), 'gi'), '').replace(/\\/g, "/")}
</section>
<section className='d-inline'>
<FullScreenDialog rowid={props.userid} id={props.planid} reset={reset} type='btn' label='Change Home Directory' rootAddress={'hst'+props.planid} formid={58} title="Change Home Directory" realAddress={'hst'+props.planid} typeid={58} displayAddress={'hst'+props.planid} /></section></section>  
<table className='customers1'>
<thead className='thead'>
<tr><th>Privilege</th>
<th>Enable</th></tr></thead><tbody>{FTPRights}</tbody>
</table></AccordionDetails></Accordion>

  
<Accordion expanded={expanded === `delteuseract`} onChange={handleChange(`delteuseract`)}>
  <AccordionSummary 
  expandIcon={<ExpandMoreIcon />} 
  aria-controls={`delteuseract-content`}
  id={`delteuseract-header`}
  sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}>
  <Typography className='fw-bold px-3' sx={{ width: '100%', }}>Delete Account</Typography>
  </AccordionSummary>
  <AccordionDetails 	    
  sx={{ backgroundColor:'#ffffff', m:2, p:0, }}
  >
<section>
<Form type="form" formmode={1} tbl={80} title={props.title} label={`Delete User: ${props.title}`} rowid={props.userid} viewid={80} id={props.userid} refetch={props.refetch} />
</section>
</AccordionDetails></Accordion>
</section> 
);
}


function GetList(props) { 
return ( 
<section className='mt-2 mb-4 bg-white'>
{parseInt(props.functionId) === 73 ? <><GetBackUp id={props.productid} domain={(props.title)} /></> : null}
{parseInt(props.functionId) === 57 ? <GetCron id={props.productid} domain={(props.title)} string1={(props.title)} rowid={props.rowid} planid={props.productid} /> : null}
{parseInt(props.functionId) === 146 ? <GetConfSettings webserver={props.webserver} id={props.productid} domain={(props.title)} string1={(props.title)} rowid={props.rowid} planid={props.productid} /> : null}
{parseInt(props.functionId) === 67 ? <GetEmail id={props.productid} domain={(props.title)} /> : null}
{parseInt(props.functionId) === 68 ? <GetDNS id={props.productid} domain={(props.title).toLowerCase()} planid={props.productid} rowid={props.rowid} /> : null}
{parseInt(props.functionId) === 69 ? <GetCert domain={(props.title).toLowerCase()}  id={props.productid} type={63} string1={(props.title)} rowid={props.rowid} planid={props.productid} /> : null}

{parseInt(props.functionId) === 88 ? <>
<GetSubDomain id={props.productid} fullname={(props.title)} domain={(props.title)} string1={(props.title)} rowid={props.rowid} planid={props.productid} /></> : null}
</section> );}


function GetDNS(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getdns' , arg2:props.rowid, arg3:`${(props.domain).toLowerCase()}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', displayProductItems:'', });
function updateInner(x){ setFormState({ ...formState, display: x }); }
function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }


let details;
if(htfile.data){
if(htfile.data.getSiteList.length > 0){
const dnsArray = JSON.parse(htfile.data.getSiteList[0].description);
details = dnsArray.map(number => <tbody key={`listdns${number.id}`}><tr>
<td className='editCol'>
  {parseInt(number.Type)!=2 && parseInt(number.Type)!=6 ? <Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`listdnsCon${number.id}`)}} className='pointer' /></Tooltip> : null}
  </td><td>{number.HostName} </td><td>{number.RecordType}</td><td>{number.RecordData}</td><td>{number.TimeToLive}</td></tr>

{formState.displayProductItems == `listdnsCon${number.id}` ? <tr><td colSpan={5}><section className='m-2 mb-5'>
<section className='mb-3'></section>
<section className='mb-3'><Form type="form" formmode={1} tbl={102} rowid={Number(number.inid) == 0 ? number.itemId : number.inid} viewid={32} refetch={htfile.refetch} label={`Delete ${number.RecordType} Record (${number.HostName}): ${number.RecordData}`} string1={number.HostName} string2={number.RecordType} string3={(props.domain).toLowerCase()} />
</section>
</section></td></tr> : null}</tbody> 
);
}}
  
  return (
  <section>
  {formState.display == '' ? <>
  <section>
    
    
    
  <section className="d-flex">
<section className="mr-2"><Button onClick={() => { updateInner('addnewdns')}} sx={{ fontSize: 11, fontWeight:'bold', }} className='mb-1' variant="outlined" color='success' size="small" startIcon={<AddIcon fontSize="small" />}>Add Record</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{htfile.loading ? <LoadingSK /> : <SyncIcon onClick={() => { htfile.refetch();}} className='pointer ml-3' />} 
</section></section></section>


</section> 
  
  <table className="customers">
  <thead><tr>
  <th className='editCol'>&nbsp;</th>
  <th>Host</th>
  <th>Type</th>
  <th>Value</th>
  <th>TTL</th>
  </tr></thead>{details}
  </table>
  </> : null} 
  
  {formState.display == 'addnewdns' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch()}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
  <section className='mt-3'>
  <Form type="form" formmode={1} formid={68} tbl={68} title={props.domain} viewid={68} rowid={props.rowid} planid={props.planid} />
  </section>
  </section> : null}
  
  {formState.display == 'schedulebackup' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner('');}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
  <Alert variant="filled" severity="info">This process will backup all files in the domain root directory
</Alert>
<section className='mt-3'>
<Form type="form" formmode={1} tbl={73} gender={2} alert='info' savebtn='success' title={props.domain} label={`Save`} viewid={73} rowid={props.id} refetch={htfile.refetch} /></section></section> : null}</section>);}




function GetCert(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getcert' , arg2:props.id, arg3:`${(props.domain).toLowerCase()}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', });
function updateInner(x){ setFormState({ ...formState, display: x }); }

let details;
if(htfile.data){
if(htfile.data.getSiteList.length > 0){
details = htfile.data.getSiteList.map(number => <tbody key={`listcerts${number.id}`}><tr><td>Valid from:</td><td>
{parseInt(number.inid)===0 ? 'Invalid / No certificate found' : null}
{parseInt(number.inid)===2 ? 'Pending' : null}
{parseInt(number.inid)===1 ? <SDate1 item={number.title} /> : null}</td></tr>
<tr><td>Valid to:</td><td>
{parseInt(number.inid)===0 ? 'Invalid / No certificate found' : null}
{parseInt(number.inid)===2 ? 'Pending' : null}
{parseInt(number.inid)===1 ? <SDate1 item={number.string1} /> : null}
</td></tr>
<tr><td>Issuer:</td><td>
{parseInt(number.inid)===0 ? 'Invalid / No certificate found' : null}
{parseInt(number.inid)===2 ? 'Pending' : null}
{parseInt(number.inid)===1 ? number.fullname : null}
</td></tr>
</tbody> 
);
}}

return (
<section>
{formState.display == '' ? <>
<section>
<section className="d-flex">
<section className="mr-2 mb-1"><Button onClick={() => { updateInner('addnewdns')}} sx={{ fontWeight:'bold', }} className='mb-1 mx-1' variant="outlined" color='success' size="small" startIcon={<AddIcon  />}>Add SSL Certificate</Button>  <Button onClick={() => { updateInner('addletsencrypt')}} sx={{ fontWeight:'bold', }} className='mb-1 mx-1' variant="contained" color='primary' size="small">Use LetsEncrypt</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{htfile.loading ? <LoadingSK /> : <SyncIcon onClick={() => { htfile.refetch();}} className='pointer ml-3' />} 
</section></section></section>
</section> 
<table className="customers">{details}
</table>
</> : null} 

{formState.display == 'addnewdns' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch()}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon />}>Back</Button></section>
<Alert className='mb-3' variant="filled" severity="info"><Typography className
='fw-bold'>Make sure to name the files as stated below.</Typography>
Certificate file: cert.pem<br />
Key file: key.pem<br />Chain file: chain.pem<br /><br />
**** Adding new certificate will overwrite any existing certificate for the domain
</Alert>

<section className='mt-3'>
<Form type="form" string1={props.domain} fileId={`${window.uuidv4()}upload69`} formmode={1} tbl={69} title={props.domain} viewid={83} rowid={props.rowid} overwriteoption={1} status={1} maxFile={3} planid={props.planid} maxfile={3} />
</section>
</section> : null}


{formState.display == 'addletsencrypt' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch()}}  variant="outlined" color='primary' size="small" startIcon={<ArrowBackIcon />}>Back</Button></section>
<Alert className='mb-3' variant="filled" severity="info"><Typography className
='fw-bold'>Issue LetEncrypt: {props.domain}</Typography>
**** Adding new certificate will overwrite any existing certificate for the domain
</Alert>

<section className='mt-3'>
<Form type="form" string1={props.domain} formmode={1} tbl={props.type} title={props.domain} viewid={70} rowid={props.rowid} overwriteoption={1} status={1} maxFile={3} planid={props.planid} maxfile={3} />
</section>
</section> : null}
</section>);}

function GetEmail(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getemails' , arg2:props.id, arg3:`${(props.domain).toLowerCase()}`}, fetchPolicy: "cache-and-network" }); 
const [formState, setFormState] = useState({ display:'', displayProductItems:'', });
function updateInner(x){ setFormState({ ...formState, display: x }); }

function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }
let details;


if(htfile.data){
if(htfile.data.getSiteList.length > 0){
details = htfile.data.getSiteList.map(number => <tbody key={`listemails${number.id}`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`listemailsCon${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.username}@{number.title}</td><td>{`${Number(number.total)} MB`}</td><td><Form type="form" formmode={1} rowid={number.inid == 0 ? number.itemId : number.inid} viewid={92} tbl={92} itemstatus={number.broken} username={`${number.username}@${number.title}`} title={number.title} /></td></tr>
{formState.displayProductItems == `listemailsCon${number.id}` ? <tr><td colSpan={5}>
  
<section className='mx-4 mb-5'>
<section className='mb-5'>
<section className='pageTitle mb-2 pb-2 fw-bold'>Change Password {`${number.username}@${number.title}`} </section> 
<Form type="form" formmode={1} username={`${number.username}@${number.title}`} tbl={93} title={props.domain} viewid={93} rowid={number.inid == 0 ? number.itemId : number.inid} /> 
</section>
<section>
<section className='pageTitle mb-2 pb-2 fw-bold'>Delete Email Address</section> 
<Alert variant="filled" className='mb-4' severity="warning">This process will delete this email account and all emails associated with it
</Alert> 
<Form type="form" label={`Delete Email ${number.username}@${number.title}`} formmode={1} tbl={94} title={number.title} viewid={94} rowid={number.inid == 0 ? number.itemId : number.inid} refetch={htfile.refetch} username={`${number.username}@${number.title}`} /> 
</section>  
</section></td></tr> : null}</tbody> 
);
}}

return (
<section>
<section className="d-flex mb-2">
<section className='flex-grow-1'>
{formState.display == '' ? <>
<Button onClick={() => { updateInner('addnewdns')}} className='mb-1' variant="outlined" color='success' sx={{ fontSize: 11, fontWeight:'bold', }} startIcon={<AddIcon  fontSize="small" />}>Add New Email</Button>
<a  rel='noopener nofollow' href={`https://webmail.${props.domain}`} target='_blank'>
<Button className='mx-2 mb-1 fw-bold' sx={{ fontSize: 11, fontWeight:'bold', }} variant="contained" color='primary'>Check Email</Button></a></> : null}

{formState.display == 'addnewdns' ? <Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch()}}  variant="outlined" color='primary' startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button> : null}
</section>

<section className="d-flex flex-column">
<section className="d-flex flex-row-reverse pointer">
{htfile.loading ? <LoadingSK /> : <SyncIcon onClick={() => { htfile.refetch();}} />}
</section>
</section>
</section>

{formState.display == '' ? <>
<table className="customers">
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Account name</th>
<th>Size</th>
<th>Enable</th>
</tr>
</thead>{details}
</table>
</> : null} 

{formState.display == 'addnewdns' ? <section>
<section className='mt-3'>
<Form type="form" formmode={1} itemstatus={1} tbl={67} title={props.domain} viewid={67} rowid={props.id} />
</section>
</section> : null}
</section>);
}






function GetSubDomain(props) {  
let htfile = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'subdomain' , arg2:parseInt(props.rowid), arg3:`${(props.string1).toLowerCase()}`}, fetchPolicy: "cache-and-network" }); 
const [value, setValue] = React.useState(0);

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const [formState, setFormState] = useState({ display:'', });
function updateInner(x){ setFormState({ ...formState, display: x }); }
function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }
let details;
  
  
  if(htfile.data){
  if(htfile.data.getSiteList.length > 0){
  details = htfile.data.getSiteList.map(number => <tbody key={`listemails${number.id}`}><tr>
  <td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`listemailsCon${number.id}`)}} className='pointer' /></Tooltip></td><td><a href={`https://${(number.fullname).toLowerCase()}.${(number.string1).toLowerCase()}`} target='_blank'  rel='noopener nofollow'>{(number.fullname).toLowerCase()}.{(number.string1).toLowerCase()}</a></td><td><SDate1 item={number.date} /></td><td>{(number.title).toUpperCase()}</td><td>/{(number.fullname).toLowerCase()}.{(number.string1).toLowerCase()}/public</td></tr>
  {formState.displayProductItems == `listemailsCon${number.id}` ? <tr><td colSpan={5}>
    
 
  {parseInt(number.remove) == 1 ? <Alert variant="filled" className='mb-4' severity="warning">Pending Removal</Alert> : 
  
<Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="SSL Certificate" {...a11yProps(0)} />
          <Tab label="Delete Sub Domain" {...a11yProps(1)} />
          <Tab label="Settings" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>        
      <section>
      <GetCert type={88} domain={`${(number.fullname).toLowerCase()}.${(number.string1).toLowerCase()}`} title={`${(number.fullname).toLowerCase()}.${(number.string1).toLowerCase()}`} string1={`${(number.fullname).toLowerCase()}.${(number.string1).toLowerCase()}`} rowid={parseInt(number.inid)==0 ? number.itemId : number.inid} id={props.id} planid={number.planid} />
</section>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
<section className='mx-4 mb-5 mt-4'>
  <section>
  <Alert variant="filled" className='mb-4' severity="warning">Delete Sub Domain: The sub domain and associated services with directories will be removed.
  </Alert>
  <Form type="form" label={`'Delete Sub Domain: '${number.fullname}.${number.string1}`} formmode={1} tbl={88} title={number.title} viewid={32} rowid={number.inid == 0 ? number.itemId : number.inid} refetch={htfile.refetch} username={`${number.username}@${number.title}`} /> 
  </section>
  </section>
      </CustomTabPanel>
      
      <CustomTabPanel value={value} index={2}>         
      <GetConfSettings webserver={(number.title).toUpperCase()} id={props.rowid} domain={`${(number.fullname).toLowerCase()}.${(number.string1).toLowerCase()}`} rowid={number.inid == 0 ? number.itemId : number.inid} planid={number.planid} />
      </CustomTabPanel>
    </Box>    
}
  
   </td></tr> : null}</tbody> 
  );
  }}
  
return (
<section>
{formState.display == '' ? <>
<section>
<section className="d-flex">
<section className="mr-2"><Button onClick={() => { updateInner('addnewdns')}} className='mb-1' variant="outlined" color='success' size="small" startIcon={<AddIcon />}>Add Sub Domain</Button></section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">{htfile.loading ? <LoadingSK /> : <SyncIcon onClick={() => { htfile.refetch();}} className='pointer ml-3' />} 
</section></section></section></section> 
<table className="customers">
<thead><tr><th className='editCol'>&nbsp;</th>
<th>Sub domain</th><th>Created</th><th>Web Server</th>
<th>Document Root</th></tr></thead>{details}</table>
</> : null} 

{formState.display == 'addnewdns' ? <section><section className='mb-3'><Button sx={{ fontSize: 11, fontWeight:'bold', }} onClick={() => { updateInner(''); htfile.refetch()}}  variant="outlined" color='primary' startIcon={<ArrowBackIcon fontSize="small" />}>Back</Button></section>
<section className='mt-3'>
<Form rowid={props.rowid} planid={props.planid} string1={props.string1} type="form" formmode={1} itemstatus={1} tbl={88} title={props.domain} viewid={88} />
</section>
</section> : null}
</section>);
}





function SValue(props) {  
let setting='';
if(parseInt(props.type) == 1){
setting=<Form type="form" inputid={props.inputid} zip={props.zip} formmode={1} tbl={58} rowid={parseInt(props.planid)} country={props.settingid} gender={parseInt(props.useractid)} viewid={58} itemstatus={props.status} refetch={props.refetch} label='Set MySQL Database Rigth' />;}
else {
setting=<Form type="form" inputid={props.inputid} zip={props.zip} formmode={1} tbl={72} rowid={parseInt(props.planid)} country={props.settingid} gender={parseInt(props.useractid)} viewid={72} itemstatus={props.status} />;}

return (setting);
}






function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export {DBFns, USRFns}
