import React, { useRef, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery, useLazyQuery} from '@apollo/client';
import {LoadingSK} from '../../components/utility/utility';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, { accordionSummaryClasses, } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
  



const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
      {
        transform: 'rotate(90deg)',
      },
    [`& .${accordionSummaryClasses.content}`]: {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

export default function Help(props) { 
let { title, id, conid } = useParams();
const articles = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getKBArticles', arg2:Number(id), arg3:String(decodeURIComponent(title)), arg4:String(decodeURIComponent(conid))}, fetchPolicy: "network-only" });  
let listArticle=<LoadingSK />;  
let lazyArticle=<LoadingSK />;

const [getSearch, { loading, data, error }] = useLazyQuery(DEF_SITE_LIST);
const handleSearch = () => {
updateDisplay(1);
getSearch({variables: { listid: 2, arg1:'searchQuery',arg4:formState.searchTerms,arg2:Number(id)}, fetchPolicy: "cache-and-network" }); 
};
const [formState, setFormState] = useState({ searchTerms: "", display:0 });
const [expanded, setExpanded] = React.useState('0panel');
const [copyContent, setcopyContent] = React.useState('');
const handleChange = (panel) => (event, newExpanded) => {
setExpanded(newExpanded ? panel : false);};


function updateSearchTerms(e){
let value = e.target.value;
const name = e.target.name;
setFormState({ ...formState, [name]: value });
}

function updateDisplay(x){
setFormState({ ...formState, display: x });
}

function copyLinkAddress(x,y) {
const anchor = document.getElementById(x);
const hrefValue = anchor.href;

navigator.clipboard.writeText(hrefValue)
.then(() => { console.log("Copied to clipboard:", hrefValue);
setcopyContent(y);})
.catch(err => console.error("Failed to copy:", err));
}

if(articles.data){
if(articles.data.getSiteList.length == 0){
listArticle =   'No content found for your search';}
else {
listArticle = articles.data.getSiteList.map((number, index) => 
<Accordion key={`${number.id}articlelist`} expanded={expanded === (index+'panel')} onChange={handleChange(index+'panel')}>
<AccordionSummary aria-controls={index+"paneld-content"} id={index+"paneld-header"}>
<Typography component="span">{number.string1}</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography component='div'>
<section dangerouslySetInnerHTML={{__html: number.string2}}>
</section>
<section className='mt-5 mb-3'>
<section><Link id={`linkid${number.id}`} to={{ pathname: `/help/con/${number.string1}/${number.inid == 0 ? number.itemId : number.inid}`}} className='sideMenu'>Content Link:</Link><span onClick={ () => copyLinkAddress(`linkid${number.id}`,`copylink${number.id}`) }  className='sideMenu pointer'>Click here to copy link</span></section>
{copyContent == `copylink${number.id}` ? <section><Alert className='mt-2' variant="filled" severity="info">Link copied</Alert></section> : null}
</section>
</Typography>
</AccordionDetails>
</Accordion>
);}}

const handleKeyDown = (event) => { if (event.key === 'Enter') { handleSearch(); }};
function usePrevious(value) { const ref = useRef(); 
useEffect(() => { ref.current = value; }); return ref.current; } 

const preQueryVal = usePrevious(formState.searchTerms);
useEffect(() => { 
if(formState.searchTerms != '' && (formState.searchTerms != preQueryVal)) { 
  //createRecord();
}}, [formState.searchTerms]);

if (error) {lazyArticle= <p>Error: {error.message}</p>;}
if(data){
if(data.getSiteList.length == 0){
lazyArticle =   'No content found for your search';}
else {
lazyArticle = data.getSiteList.map((number, index) => 
<Accordion key={`${number.id}articlelist`} expanded={expanded === (index+'panel')} onChange={handleChange(index+'panel')}>
<AccordionSummary aria-controls={index+"paneld-content"} id={index+"paneld-header"}>
<Typography component="span">{number.string1}</Typography>
</AccordionSummary>
<AccordionDetails>
<Typography component='div'>
<section dangerouslySetInnerHTML={{__html: number.string2}}>
</section>
<section className='mt-5 mb-3'>
  <section><Link id={`linkid${number.id}`} to={{ pathname: `/help/con/${number.string1}/${number.inid == 0 ? number.itemId : number.inid}`}} className='sideMenu'>Content Link:</Link><span onClick={ () => copyLinkAddress(`linkid${number.id}`,`copylink${number.id}`) }  className='sideMenu pointer'>Click here to copy link</span></section>
  {copyContent == `copylink${number.id}` ? <section><Alert className='mt-2' variant="filled" severity="info">Link copied</Alert></section> : null}
</section>
</Typography>
</AccordionDetails>
</Accordion>
);}}
return (
<section className='mt-3 mb-4 defMrg'>
<Typography variant='h5' className='mb-2 fw-bold pageTitle'>How can we help you? </Typography>
<section className='d-flex mt-3'>
<section className='sideBar fw-bold d-none d-lg-block'>
<section className='helpSidebar mb-2 pb-2'>
<Link onClick={ () => { window.topTop(); updateDisplay(0) }} to={{ pathname: "/help/116/Domain Names/0"}} className='sideMenu'>Domain Name {conid}</Link>
</section>
<section className='helpSidebar mb-2 pb-2'>
<Link onClick={ () => { window.topTop(); updateDisplay(0) }} to={{ pathname: "/help/117/Hosting/0"}} className='sideMenu'>Hosting</Link>
</section>
<section className='helpSidebar mb-2 pb-2'>
<Link onClick={ () => { window.topTop(); updateDisplay(0) }} to={{ pathname: "/help/118/Servers/0"}} className='sideMenu'>Servers</Link>
</section>
<section className='helpSidebar mb-2 pb-2'>
<Link onClick={ () => { window.topTop(); updateDisplay(0) }} to={{ pathname: "/help/"}} className='sideMenu'>All Topics</Link>
</section>
</section>
<section className='flex-grow-1 px-5'>
<Box sx={{ flexGrow: 1, backgroundColor:'#E2E7E2', color:'#000000' }}>
<AppBar position="static" color='transparent'>
<Toolbar>
<Typography
variant="h6"
noWrap
component="div"
sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
>{title != '' && title != null ? title : 'All Topics'}
</Typography>
<Search>
<SearchIconWrapper>
<SearchIcon />
</SearchIconWrapper>
<StyledInputBase
placeholder="Search…"
inputProps={{ 'aria-label': 'search' }}
onKeyDown={handleKeyDown}
name='searchTerms'
onChange={updateSearchTerms}
/>
</Search>
</Toolbar>
</AppBar>
</Box>
<section className='mt-3 mb-3'> 
{parseInt(formState.display) == 0 ? listArticle : null}
{parseInt(formState.display) == 1 ? lazyArticle : null}
</section>
</section>
</section>
</section>
);}
