import {useState } from "react";
import { Route, Routes, useLocation } from 'react-router-dom';
//import Footer from '../../components/footer/footerB';
import Home from '../../components/home/home';
import { jwtDecode } from "jwt-decode";
import Cat from '../../components/cat/cat';
import Content from '../../components/content/content';
import NotFound from '../../components/content/notfound';
import Acct from '../../components/acct/acct';
import Search from '../../components/search/search';
import Help from '../../components/help/help';
import Unsubscribe from '../../components/help/unsubscribe';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Logo from '../../assets/logo.png';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import {Helmet} from "react-helmet";

function LogOut(){
sessionStorage.clear(); 
localStorage.clear(); 
window.location.replace('/');}


export default function Container(props) {  
const [formState, setFormState] = useState({ 
cartItems: [], cartState: false, });
const getsiteInfo = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'176' , arg2:176}, fetchPolicy: "cache-and-network" }); 
const topMenu = useQuery(DEF_SITE_LIST, {variables: { listid: 7 }, fetchPolicy: "cache-and-network" }); 
const getOwnData = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'myproducts' }, fetchPolicy: "cache-and-network" }); 
const getCurrency = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'6' , arg2:6 }, fetchPolicy: "cache-and-network" }); 
const productFeatures = useQuery(DEF_SITE_LIST, {variables: { listid: 25 }, fetchPolicy: "cache-and-network" }); 
const products = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'133', arg2:133 }, fetchPolicy: "cache-and-network" }); 
//const dropDownOptions = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'11', arg2:152 }, fetchPolicy: "cache-and-network" }); 
const getPages = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(2), arg1:String(131), arg2:(131) }, fetchPolicy: "cache-first"});
const getCartItems = useQuery(DEF_SITE_LIST, {variables: { listid: 28, arg1:window.cartCookie }, fetchPolicy: "network-only" }); 


function updateCart(){
let runOnce = false;
if(runOnce == false){
runOnce = true; 
setFormState({ ...formState, cartItems: [], cartState: !formState.cartState });}
setTimeout(getCartItems.refetch, 2000);
}

if(getCartItems.data){
if(getCartItems.data.getSiteList != formState.cartItems){
setFormState({ ...formState, cartItems: getCartItems.data.getSiteList });}
}

const { pathname, state } = useLocation();
if(topMenu.error){return <section className="alert alert-warning offline" role="alert">Connection Error! {topMenu.error.message}</section>;} 

let siteInfo = '';
if(getsiteInfo.data){
siteInfo = (
<Helmet>
<meta charSet="utf-8" />
<title>{typeof state == 'undefined' || state == null ? 'Dredge Resources | Intelligent Solutions for Your Business' : typeof state.pageTitle == 'undefined' || state.pageTitle == null ? 'Dredge Resources | Intelligent Solutions for Your Business' : state.pageTitle+ ' | Dredge Resources'}</title>
<link rel="canonical" href="https://dredge.com.ng" />
<meta name="description" content={typeof state == 'undefined' || state == null ? "Discover top-notch web services including web development, SEO, and digital marketing tailored to elevate your business. Partner with us for innovative solutions that drive results." : typeof state.pageDescription == 'undefined' || state.pageDescription == null ? "Discover top-notch web services including web development, SEO, and digital marketing tailored to elevate your business. Partner with us for innovative solutions that drive results." : state.pageDescription} />
<meta name="keywords" content="web services, web development, digital marketing, SEO, e-commerce solutions, custom software, cloud services, web hosting, servers, vps hosting, dedicated servers" />
<meta name="author" content="Dredge Resources Limited" />
<meta name="robots" content="index, follow" />
<meta property="og:title" content="Leading Web Services Company | Intelligent Solutions for Your Business" />
<meta property="og:description" content="Partner with us for exceptional digital experience and digital marketing services designed to grow your business." />
<meta property="og:image" content="https://dredge.com.ng/logo512.png" />
<meta property="og:url" content="https://dredge.com.ng/" />
<meta property="og:site_name" content="Dredge Resources Limited" />



<meta name="twitter:card" content="https://dredge.com.ng/logo512.png" />
<meta name="twitter:title" content="Leading Web Services Company | Intelligent Solutions for Your Business" />
<meta name="twitter:description" content="Explore our web services tailored to your business needs. From web development to SEO, we've got you covered!" />
<meta name="twitter:image" content="https://dredge.com.ng/logo512.png" />
</Helmet>);	
	
	}
	

if(topMenu.data && getCartItems.data && productFeatures.data && getCurrency.data && products.data && getPages.data && getOwnData.data){
return (
	<section className='m-0 p-0'>
	<Header cartItems={formState.cartItems} currency={getCurrency.data} setCountry={props.setCountry} products={products.data} menu={topMenu.data} {...props} />
	
	
	<section className='mt-3'>
	<Routes> 
	<Route exact path="/logout" element={<LogOut {...props} />} />
	<Route exact path="/con/:id/:gid/:title/:menuid/*" element={<Content menu={topMenu.data} products={products.data} {...props} />} />
	<Route exact path="/help/:id/:title/:conid/*" element={<Help menu={topMenu.data} {...props} />} />	
	<Route exact path="/help/*" element={<Help menu={topMenu.data} {...props} />} />
	<Route exact path="/unsubscribe/*" element={<Unsubscribe menu={topMenu.data} {...props} />} />
	
	<Route exact path="/acct/:id/:mode/:itemId/:tbl/*" element={(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? <LogOut {...props} /> : <Acct products={products.data} productFeatures={productFeatures.data} featuresRefetch={productFeatures.refetch} menu={topMenu.data} menurefetch={topMenu.refetch} updateCart={updateCart} ownDataRefetch={getOwnData.refetch} ownData={getOwnData.data} {...props} />} />
	
	<Route exact path="/dsearch/:id/:ext/:type/*" element={<Search products={products.data} menu={topMenu.data} currency={getCurrency.data} updateCart={updateCart} {...props} />} />
	<Route exact path="/cat/:catid/:id/*" element={<Cat productFeatures={productFeatures.data} products={products.data} pages={getPages.data} currency={getCurrency.data} menu={topMenu.data} {...props}  updateCart={updateCart} />} />
	
	<Route exact path="/acct/:id/*" element={<Acct products={products.data} menu={topMenu.data} currency={getCurrency.data} menurefetch={topMenu.refetch} featuresRefetch={productFeatures.refetch} productFeatures={productFeatures.data} cartItems={formState.cartItems} updateCart={updateCart} ownData={getOwnData.data} ownDataRefetch={getOwnData.refetch} {...props} />} />
	<Route path="/" element={<Home productFeatures={productFeatures.data} pages={getPages.data} products={products.data} menu={topMenu.data} {...props} currency={getCurrency.data} updateCart={updateCart} />} />
	<Route path="*" element={<NotFound productFeatures={productFeatures.data} pages={getPages.data} products={products.data} menu={topMenu.data} NotFound={true} currency={getCurrency.data} updateCart={updateCart} {...props} />} />
	</Routes> 
	</section>
	<Footer pages={getPages.data} products={products.data} setCountry={props.setCountry} menu={topMenu.data} {...props} />
	{siteInfo}
	</section>
	
	
	
	
	
	
	
	
	);
}
//} 
//}
return (
<main className="h-100 w-100">
<div id="outer">
<section className="vertical-center" id="inner">
<section className="m-4"><img src={Logo} className="logoTopCon" alt="" title='' /></section> 
<center>
<section className="spinner-border text-success loading m-2 text-center"></section> 
<section className="iniPage loading m-2 text-center">Loading, please wait</section> 
</center>
</section>
</div>
</main>);
}