/* eslint-disable */ 
import React, {useState, useEffect, useRef} from 'react';
import * as RDom from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, gql, useQuery } from '@apollo/client';
import { jwtDecode } from "jwt-decode";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';		 
import Typography from '@mui/material/Typography';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import Switch from '@mui/material/Switch';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CEditor from '../../components/utility/editor';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import Tooltip from '@mui/material/Tooltip';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import SwitchJ from '@mui/joy/Switch';

var token = "none";
var apiToken = "none";
let curUser = null;
if(sessionStorage.getItem("signIn")){
token = sessionStorage.getItem("signIn");
apiToken = sessionStorage.getItem("apiToken");
curUser = jwtDecode(sessionStorage.getItem("signIn"));} 

async function getFingerprint() {
 /* const fingerprint = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      languages: navigator.languages,
      screenResolution: {
          width: window.screen.width,
          height: window.screen.height
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      plugins: Array.from(navigator.plugins).map(plugin => plugin.name),
      touchSupport: 'ontouchstart' in window,
      cookiesEnabled: navigator.cookieEnabled
  };
  */
let formData = new FormData();
formData.append("formid", 75);
formData.append("fingerprint", `${navigator.platform}${navigator.languages}${Intl.DateTimeFormat().resolvedOptions().timeZone}${Array.from(navigator.plugins).map(plugin => plugin.name)}${'ontouchstart' in window}${window.screen.width}${window.screen.height}${window.screen.colorDepth}${navigator.deviceMemory}${navigator.hardwareConcurrency}`);
//formData.append('fingerPrint', JSON.stringify(fingerprint));
  // Send fingerprint to the server
  const response = await fetch(window.apiUpload, {
      method: 'POST',
      headers: {
          "Authorization": `Bearer ${token} ${apiToken}`,
      },
      body: formData
  });

  const result = await response.json();
}



function SelectList(props) {
const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(props.id), section:'fieldtype' }, fetchPolicy: "cache-first"});
if (loading) {return "Loading...";}
if (error) {return `Error! ${error.message}`; }
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={"listfieldsv12"+number.id} value={number.string1}>{number.title}</option>);
return ( <><option value=""> --Select-- </option>
{listItems}</>); }}

function DropDownOptions(props) {
  const { loading, error, data } = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(props.id), arg1:props.arg1 ? String(props.arg1) : '', arg2:props.arg2 ? Number(props.arg2) : 0, arg3:props.arg3 ? String(props.arg3) : '0' }, fetchPolicy: "cache-first"});
if (loading) {return "Loading...";}
if (error) {return `Error! ${error.message}`; }
if (data) {
const numbers = data.getSiteList;
const listItems = numbers.map(number =>
<option key={'DrOptionsv4dgeewe'+number.id} value={number.inid != 0 ? number.inid : number.itemId}>
{number.string1 != '' && number.string1 != null && number.string1 != 'undefined' && number.string1 != 'null' ? number.string1 : null}</option>);
return ( 
<section className='form-floating'>
<select required={props.required} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=""> --Select-- </option>
{listItems}
</select>
{typeof props.label !== 'undefined' && props.label != '' ? <label htmlFor={props.name}>{props.label} {props.required && props.required == true ? '*' : null}</label> : null}</section>); }}

function ListDays(props){
let days=[];
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

for (let i=1; i < parseInt(props.days)+1; i++){ days.push(i); }  
let dayList = days.map(number => <option key={`${window.uuidv4()}days`} value={number}>{parseInt(props.days) != 7 ? `Day ${number}` : weekdays[number-1]}</option>);

return (
<section className='form-floating'> 
<select required className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=""> --Select-- </option>
{dayList}
</select>
<label htmlFor={props.name}>{props.label} *</label>
<small>{props.helpText}</small>
</section>
);
}
function OptionList(props){
const getData = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'getDropMasterSlave', arg2:parseInt(props.sourceId), arg3:String(props.itemId) }, fetchPolicy: "cache-first"});
const ipAddress = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'61' }, fetchPolicy: "cache-first"});

let data;
if(getData.data){ data = getData.data.getSiteList; }
if(props.ip && ipAddress.data){
  data = ipAddress.data.getSiteList.filter(function(currItem) { return parseInt(currItem.string2) === parseInt(props.sourceId) || parseInt(props.sourceId) === parseInt(currItem.string2); });
}


let listItems='';
if(getData.data && ipAddress.data){
const numbersDrop = data;
listItems = numbersDrop.map(number => <option key={`${window.uuidv4()}Optist5rr${number.id}`} value={parseInt(props.sourceId) === 152 || props.ip == true ? number.string1 : (parseInt(number.inid) !== 0 ? number.inid : number.itemId)}>{number.string1}</option>);
}
return (<>
{parseInt(props.sourceId) === 152 ? 
<select required={props.required} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value="">{props.placeholder && props.placeholder !== '' ? 
props.placeholder : '--Select--'} </option>
{listItems}
</select> : <section className='form-floating'>
<select required={props.required} className="form-control" id={props.name} name={props.name} value={props.value} onChange={props.handleChange} >
<option value=""> --Select-- </option>
{listItems}
</select>
{typeof props.label !== 'undefined' && props.label != '' ? <label htmlFor={props.name}>{props.label} {props.required && props.required == true ? '*' : null}</label> : null}
</section>}
</>);

}


const CREATE_MUTATION = gql`mutation AddRecord($input: FormInput) {
  addRecord(input: $input) {
    msg,
    status,
    itemId,
    typeid,
	address
  }
}
`;

export default function GetForm(props){
var frmname = "frm"+props.viewid+Math.random();
let { itemId, mode, id, tbl } = useParams();
var info = "";
var location = RDom.useLocation();
const navigate = useNavigate();
const [formState, setFormState] = useState({
fullname: ((typeof props.fullname !== 'undefined') ? props.fullname : ""),
gender: ((typeof props.gender !== 'undefined') ? parseInt(props.gender) : 0),
username: "",
frmtype:((typeof props.formmode !== 'undefined') ? parseInt(props.formmode) : 1),
formid:(typeof props.viewid !== 'undefined') ? parseInt(props.viewid) : 0,
status:((typeof props.status !== 'undefined') ? parseInt(props.status) : 0),
domain:((typeof props.domain !== 'undefined') ? props.domain : ''),
description:((typeof props.description !== 'undefined') ? props.description : ""),
name:((typeof props.name !== 'undefined') ? props.name : ""),
username:((typeof props.username !== 'undefined') ? props.username : ""),
password:((typeof props.password !== 'undefined') ? props.password : ""),
confirmPassword:"",
address: ((typeof props.address !== 'undefined') ? props.address : ""),
city:((typeof props.city !== 'undefined') ? props.city : window.cartCookie),
province:((typeof props.province !== 'undefined') ? props.province : ""),
zip:((typeof props.zip !== 'undefined') ? props.zip : ""),
country:((typeof props.country !== 'undefined') ? parseInt(props.country) : 0),
itemstatus:((typeof props.itemstatus != 'undefined') ? parseInt(props.itemstatus) : 0),
rowid:((typeof props.rowid !== 'undefined') ? parseInt(props.rowid) : 0),
tbl:((typeof props.tbl !== 'undefined') ? parseInt(props.tbl) : 0),
phone:((typeof props.phone !== 'undefined') ? props.phone : ""),
title:((typeof props.title !== 'undefined') ? props.title : ""),
id:((typeof props.id !== 'undefined') ? parseInt(props.id) : 0),
planid:((typeof props.planid !== 'undefined') ? parseInt(props.planid) : 0),
uuid:((typeof props.uuid !== 'undefined') ? props.uuid : ""),
total:((typeof props.total !== 'undefined') ? parseInt(props.total) : 0),
filerepos: [],
dropdownSource: false,
planstate:1,
switchState:0,
hideForm:false,
string1:((typeof props.string1 !== 'undefined') ? props.string1 : ""),
string2:((typeof props.string2 !== 'undefined') ? props.string2 : ""),
string3:((typeof props.string3 !== 'undefined') ? props.string3 : ""),
string4:((typeof props.string4 !== 'undefined') ? props.string4 : ""),
string5:((typeof props.string5 !== 'undefined') ? props.string5 : ""),
string6:((typeof props.string6 !== 'undefined') ? props.string6 : ""),
string7:((typeof props.string7 !== 'undefined') ? props.string7 : ""),
string8:((typeof props.string8 !== 'undefined') ? props.string8 : ""),
string9:((typeof props.string9 !== 'undefined') ? props.string9 : ""),
string10:((typeof props.string10 !== 'undefined') ? props.string10 : ""),
string11:((typeof props.string11 !== 'undefined') ? props.string11 : ""),
string12:((typeof props.string12 !== 'undefined') ? props.string12 : ""),
string13:((typeof props.string13 !== 'undefined') ? props.string13 : ""),
string14:((typeof props.string14 !== 'undefined') ? props.string14 : ""),
string15:((typeof props.string15 !== 'undefined') ? props.string15 : ""),
string16:((typeof props.string16 !== 'undefined') ? props.string16 : ""),
string17:((typeof props.string17 !== 'undefined') ? props.string17 : ""),
string18:((typeof props.string18 !== 'undefined') ? props.string18 : ""),
string19:((typeof props.string19 !== 'undefined') ? props.string19 : ""),
string20:((typeof props.string20 !== 'undefined') ? props.string20 : ""),
string21:((typeof props.string21 !== 'undefined') ? props.string21 : ""),
string22:((typeof props.string22 !== 'undefined') ? props.string22 : ""),
string23:((typeof props.string23 !== 'undefined') ? props.string23 : ""),
string24:((typeof props.string24 !== 'undefined') ? props.string24 : ""),
string25:((typeof props.string25 !== 'undefined') ? props.string25 : ""),
string26:((typeof props.string26 !== 'undefined') ? props.string26 : ""),
string27:(parseInt(props.viewid) == 75 ? `${navigator.platform}${navigator.languages}${Intl.DateTimeFormat().resolvedOptions().timeZone}${Array.from(navigator.plugins).map(plugin => plugin.name)}${'ontouchstart' in window}${window.screen.width}${window.screen.height}${window.screen.colorDepth}${navigator.deviceMemory}${navigator.hardwareConcurrency}` : (typeof props.string27 !== 'undefined') ? props.string27 : ""),
});

const [createRecord, { error, data, loading }] = useMutation(CREATE_MUTATION, {
variables: 
{ input: {
fullname: formState.fullname,
gender: parseInt(formState.gender),
username: formState.username,
frmtype:parseInt(formState.frmtype),
formid:parseInt(formState.formid),
status: parseInt(formState.status),
description: (parseInt(props.viewid) === 12) ? props.description : formState.description,
password:formState.password, 
confirmPassword:formState.confirmPassword,
address: (parseInt(props.viewid) === 85 || parseInt(props.viewid) === 84 || parseInt(props.viewid) === 86 || parseInt(props.viewid) === 98 || parseInt(props.viewid) === 12) ? props.address : formState.address,
city: formState.city,
province: formState.province,
//name: formState.name,
zip: formState.zip,
phone: formState.phone,
title: formState.title,
itemstatus: parseInt(formState.itemstatus),
id: parseInt(formState.id),
country: parseInt(formState.country),
tbl: formState.tbl,
rowid: parseInt(formState.rowid),    
total: parseInt(formState.total),    
planid: parseInt(formState.planid),    
domain: formState.domain,
uuid: (sessionStorage.getItem("apiToken") !== 'undefined') ? sessionStorage.getItem("apiToken") : '',
planstate:formState.planstate,
string1:formState.string1,
string2:formState.string2,
string3:formState.string3,
string4:formState.string4,
string5:formState.string5,
string6:formState.string6,
string7:formState.string7,
string8:formState.string8,
string9:formState.string9,
string10:formState.string10,
string11:formState.string11,
string12:formState.string12,
string13:formState.string13,
string14:formState.string14,
string15:formState.string15,
string16:formState.string16,
string17:formState.string17,
string18:formState.string18,
string19:formState.string19,
string20:formState.string20,
string21:formState.string21,
string22:formState.string22,
string23:formState.string23,
string24:formState.string24,
string25:formState.string25,
string26:formState.string26,
string27:formState.string27,
}},
fetchPolicy: "no-cache"
});

const [checked, setChecked] = React.useState(false);
const [passwordShow, setpasswordShow] = useState("password");
let infoStatus = -1;  
let infoMsg = "";   
let infoAddress = "";      
let initialRefRow=useRef(true);

useEffect(() => { 
if(initialRefRow.current){ initialRefRow.current = false; }
else{  setFormState({ ...formState, rowid: props.rowid, address: props.address });
}}, [props.rowid]); // Only re-run the effect if props.rowid changes

function useDefaultNS(){
setFormState({ ...formState, string1: 'ns1.dredge.com.ng', string2: 'ns2.dredge.com.ng' });
}

function useSameInfo(x){
if(parseInt(x)===1){
setFormState({ ...formState, 
string6: formState.string1, 
string7: formState.string2, 
string8: formState.string3, 
string9: formState.string4, 
string10: formState.string5,
});}

if(parseInt(x)===2){
setFormState({ ...formState, 
string11: formState.string1, 
string12: formState.string2, 
string13: formState.string3, 
string14: formState.string4, 
string15: formState.string5,
});
}
}

const prcRecord = async (x) => { 
if(document.getElementById("prcRec"+x)){document.getElementById("prcRec"+x).innerHTML = `<div class="alert alert-info d-flex" role="alert">
<span class="spinner-border text-success icon justify-content-center align-self-center"></span>  <span class='mx-3 justify-content-center align-self-center'>Processing, please wait</span></div>`;}
createRecord(); } 
function hideElem(){ if(formState.hideForm === false){setFormState({ ...formState, hideForm:true }); }}

function requireField(){ setFormState({ ...formState, itemstatus: (parseInt(formState.itemstatus) !== 1 ? 1 : 0) }); } 

function enableField(){ setFormState({ ...formState, planid: (parseInt(formState.planid) !== 1 ? 1 : 0) }); } 

if (loading){ info = <div className="alert alert-info d-flex" role="alert">
<span className="spinner-border text-success icon justify-content-center align-self-center"></span>  <span className='mx-3 justify-content-center align-self-center'>Processing, please wait……………</span></div>; }
if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};
if (data) { 
const numbers = data.addRecord; 
infoMsg = numbers.msg; 
infoStatus = numbers.status; 
infoAddress = numbers.address; 
info = <center>
<section className="spinner-border text-success"></section> 
<section className="iniPage">Loading, please wait ………….</section> 
</center>;	

if(infoStatus == 1){
info = <div className="alert alert-success" role="alert">Sign in successful, redirecting</div>;
}

if(infoStatus == 5){ 
navigate('/logout');
}
if(infoStatus === 2){ 
sessionStorage.setItem("signIn", infoMsg);
sessionStorage.setItem("apiToken", apiToken);
sessionStorage.setItem("decoded", JSON.stringify(jwtDecode(infoMsg)));
window.location.href= "/";
 }
if(parseInt(infoStatus) === 0){
   info =<div className="alert alert-danger" role="alert">{infoMsg} </div>; }
if(parseInt(infoStatus) === 1){ info =<div className="alert alert-success" role="alert"> {infoMsg}  </div>; }}

function ckEditorChange(data,name){
  setFormState({
    ...formState,
    [name]: data
  });
}

function getFileExtension(fileName) {
  if (typeof fileName !== 'string') {
      throw new Error("Input must be a string");
  }
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex === -1 || lastDotIndex === 0 || lastDotIndex === fileName.length - 1) {
      return ''; // No extension or invalid format
  }
  return fileName.slice(lastDotIndex + 1);
}

function searchDomain(){
if(formState.title != '' && formState.city != null && formState.title != null  && formState.city != ''){
navigate(`/dsearch/${formState.title}${formState.city}/${formState.city}/${formState.gender}`);
}}

async function fileUpload(e){
let userId = curUser != null ? curUser.typeId : 'none';  
let name = e.target.name; 
if(e.target.files.length > 0){
let newName = window.uuidv4()+'-user-'+userId+'-id-'+e.target.files[0].name;
setFormState({
...formState,
[name]: newName,
});

let formData = new FormData();
formData.append("newname", newName);
formData.append("formid", props.viewid);
formData.append('myFile', e.target.files[0]);

fetch(window.apiUpload, {
method: "POST",
// Set the FormData instance as the request body
body: formData,
headers: {
"Authorization": `Bearer ${token} ${apiToken}`,
},});
}}


async function countFiles(e){
document.getElementById(props.fileId).innerHTML=null;
if(e.target.files.length > 0 && e.target.files.length < 16){
for(let i = 0; i < e.target.files.length; i++){
document.getElementById(props.fileId).innerHTML+=`<tr><td>${e.target.files[i].name} </td><td id='${props.fileId}uploadProg${i}'> </td></tr>`;}}
setFormState({ ...formState, total: e.target.files.length, filerepos:e.target.files, planstate:1 }); e.preventDefault(); }

/*
async function processFile() {
return new Promise((resolve) => {
const xhr = new XMLHttpRequest();
const fd = new FormData();
fd.append('overwrite', overwrite);
fd.append("formid", formid);
fd.append("dir", dir);
fd.append('file', file);
xhr.open("POST", window.apiUpload, true);
resolve("");
});
}
*/
async function domainFiles(){
let error = '';  
let stateUpdate = 0;
if(parseInt(formState.total) > 0){
setFormState({ ...formState, planstate:-1 });
for(let i = 0; i < formState.filerepos.length; i++){
 let result = await sendFileToServer(props.viewid,formState.filerepos[i],formState.status,formState.zip,i);
}}}

async function sendFileToServer(formid,file,overwrite,dir,prog){
return new Promise((resolve) => {
const xhr = new XMLHttpRequest();
const fd = new FormData();
fd.append('overwrite', overwrite);
fd.append("formid", formid);
fd.append("tbl", formState.tbl);
fd.append("dir", dir);
fd.append('file', file);
fd.append('planid', props.planid);
fd.append('rowid', props.rowid);
fd.append('title', props.title);
xhr.open("POST", window.apiUpload, true);

///xhr.setRequestHeader("Accept", "application/json");
xhr.setRequestHeader("Authorization", `Bearer ${token} ${apiToken}`);
xhr.upload.addEventListener("progress", function(e) {
if (e.lengthComputable) { 
const percentage = Math.round((e.loaded * 100) / e.total);
if(percentage < 99){ 
if(document.getElementById(`${props.fileId}uploadProg${prog}`)){
  document.getElementById(`${props.fileId}uploadProg${prog}`).innerText=`${percentage}%`
}}
else {document.getElementById(`${props.fileId}uploadProg${prog}`).innerHTML=`<span class='fw-bold text-success'>&check; </span>`

}
 } }, false);
xhr.onreadystatechange = function() {
  if (xhr.readyState === 4) {
    let myArr = JSON.parse(xhr.responseText);
    xhr.abort(); // Ensures the connection is closed.
    return resolve(myArr.data.status);
  }
 };


// Initiate a multipart/form-data upload
xhr.send(fd);
});
}

let numbersInput;
function handleChange(e){
let value = e.target.value;
if(e.target.type === 'checkbox'){ value = e.target.checked; }
else if(e.target.type === 'radio'){ value = e.target.value; }
else if(e.target.type === 'selected'){ value = e.target.selected.value; }  
const name = e.target.name;
setFormState({ ...formState, [name]: value });
var radios = document.getElementsByName([name]);
for (var i = 0, length = radios.length; i < length; i++) {
if (radios[i].checked) { break; }}}

function getValue(x){ return formState[x]; }
let prcRecordPrg = (<div id={"prcRec"+props.viewid}></div>);

function showOptionSource(a,b){
setFormState({ ...formState, [a]: b, 
dropdownSource:(b == 'select' ? true : false),
});
}
function passwordVisibility(e) {   
e.preventDefault();    
if(passwordShow === "password"){setpasswordShow("text"); return;}
setpasswordShow("password"); return;  }

const inputs = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(1), arg1:String(props.tbl) }, fetchPolicy: "cache-first"});


const featureInputs = useQuery(DEF_SITE_LIST, { variables: { listid: parseInt(25) }, fetchPolicy: "cache-first"});
let listInputs = '';
let listFeatureInputs = '';
//if (inputs.loading) {return ("Loading... "); }
if (inputs.data && featureInputs.data) {  
let dataSource = inputs.data.getSiteList;
if(dataSource.length > 0){
const numbersFeatures = featureInputs.data.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == formState.string5 || parseInt(currItem.planid) == 0; });

listFeatureInputs = numbersFeatures.map((number) => 
<section className='mb-3' key={'listFeatureInputs43ff'+number.id}>{number.fullname}<Input placeholder={number.title} handleChange={handleChange} value={getValue(number.fullname)} id={number.fullname} name={number.fullname} type={number.province} className="form-control" aria-label={number.title} label={number.title} required={true} itemId={number.inid == 0 ? number.itemId : number.inid} arg1={props.tbl} menu={props.menu} dropDown={number.inid == 0 ? number.itemId : number.inid} />
</section>
);




if(inputs.data){
numbersInput = inputs.data.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) != 0; });
numbersInput = numbersInput.sort(function(a, b){return a.fdorder - b.fdorder});
listInputs = numbersInput.map((number) => 
<section className='mb-4' key={'listInputs212dd'+number.id}>
{(number.city === 'select' || number.city === 'image') && parseInt(formState.rowid) === -1  ? null : 
<section className=''>{number.city === 'textarea' ? <><Typography className='mt-2'>{number.title}</Typography><section>
{curUser !== null && parseInt(curUser.typeId)===0 ? <CEditor curUpdate={'numbersInputckeditor'+number.id} ckEditorChange={ckEditorChange} id={number.address} name={number.address} value={getValue(number.address)} />: <textarea rows={10} maxLength={200} placeholder='Message' id={number.address} onChange={handleChange} name={number.address}  aria-label='description' value={getValue(number.address)} className='form-control' required />}</section></>
: <>
{number.city === 'email' && parseInt(formState.tbl) === 138 && curUser !== null  ? null : <> <Input placeholder={number.title} handleFile={fileUpload} handleChange={handleChange} value={getValue(number.address)} string2={number.string2} sourceId={number.fullname} id={number.address} name={number.address} type={number.city} className="form-control" aria-label={number.title} label={number.title} required={number.broken && number.broken == 1 ? true : false} helpText= {number.description} itemId={number.inid == 0 ? number.itemId : number.inid} arg1={props.tbl} menu={props.menu} tbl={formState.tbl} dropDown={number.inid == 0 ? number.itemId : number.inid} /></>}
</>} </section>} </section>); } }}



function generateStrongPassword(length = 12) {
  // Define character sets
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const specialChars = '!@#$%^&*()_+[]{}|;:,.<>?';

  // Combine all character sets
  const allChars = upperCase + lowerCase + numbers + specialChars;

  // Ensure at least one character from each set
  let password = '';
  password += upperCase[Math.floor(Math.random() * upperCase.length)];
  password += lowerCase[Math.floor(Math.random() * lowerCase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += specialChars[Math.floor(Math.random() * specialChars.length)];

  // Fill the rest of the password length with random characters
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  // Shuffle the password to randomize character positions
  password = password.split('').sort(() => Math.random() - 0.5).join('');
if(password  != formState.password)
  { setFormState({ ...formState, password:password });}
  //return password;
}


function usePrevious(value) { const ref = useRef(); 
useEffect(() => { ref.current = value; }); return ref.current; } 

const prevSwitchState = usePrevious(formState.switchState);
useEffect(() => { 
if(formState.switchState != 0) { 
if(formState.switchState != prevSwitchState){createRecord();}}}, [formState.switchState]);



const prevTotal = usePrevious(formState.total);
useEffect(() => { 
if(parseInt(formState.total) !== 0 && parseInt(props.viewid) === 18) { 
if(parseInt(formState.total) !== parseInt(prevTotal)){createRecord();}}}, [formState.total]);

useEffect(() => { 
if(parseInt(formState.total) !== 0 && parseInt(props.viewid) === 18) { 
if((parseInt(formState.total) !== parseInt(prevTotal)) && parseInt(infoStatus) === 1){
  props.updateCart();}}}, [formState.total]);

function handleSwitchChange(e){
if(e.target.checked && parseInt(formState.itemstatus) != 1){
setFormState({ ...formState, itemstatus: 1, switchState:1 });}
if(!e.target.checked && parseInt(formState.itemstatus) != 2){
setFormState({ ...formState, itemstatus: 2, switchState:2 });
}}

function handleRadioItemUpdate(e){
//if(e.target.type === 'radio'){ value = e.target.value; }
const value = e.target.value;
//const name = e.target.name;
//if(parseInt(formState.switchState) != parseInt(value)){
setFormState({ ...formState, switchState:(parseInt(formState.switchState)==1 ? 2 : 1)});
}//}


if(props.type === "form"){
switch(parseInt(props.viewid)) {
case 1: // manage form fields
return (           
<div>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }}>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<section>{info}</section>
<Typography className='fw-bold'>{props.formmode == 1 ? 'Add New' : 'Edit'} Field</Typography>
<section className=''>
<section className='form-floating mt-2 mb-3'>
<Input placeholder="Title" handleChange={handleChange} value={formState.title} name="title" maxLength="150" type="text" className="form-control" aria-label="Title" required={1} />
</section>
<section className='form-floating mb-3'>
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) => {
//setFormState({ ...formState, [e.target.name]: e.target.value });
showOptionSource(e.target.name, e.target.value);
}} >
<SelectList arg1='fieldtype' id={9} /> 
</select>
<label htmlFor={'city'}>Field Type *</label>
</section>


{formState.dropdownSource === true ? 
<DropDownOptions id={2} arg1='getDropMaster' name='string2' value={formState.string2}  handleChange={handleChange}  />  : null }
<section className='form-floating mb-3'>
<Input placeholder="Help Text" handleChange={handleChange} value={formState.description} name="description" maxLength="100" type="text" className="form-control" aria-label="Title" />
</section>

<section className='d-flex mb-3'>
<section className='flex-fill px-2'><label htmlFor="itemstatus">Required :</label>
<span className='mx-2 switchSize'>
<Switch name='itemstatus' id='itemstatus' onChange={requireField} checked={parseInt(formState.itemstatus) === 1 ? true : false}  /></span></section>

<section className='flex-fill px-2'><label htmlFor="planid">Enable :</label>
<span className='mx-2 switchSize'>
<Switch name='planid' id='planid' onChange={enableField} checked={parseInt(formState.planid) === 1 ? true : false}  /></span></section>
</section></section>

<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input></section></form></div>
);  
case 2: // manage data
//{parseInt(infoStatus) === 1 ? hideElem() : null}
return (<>
<div className="">
<section>{info}</section>
{formState.hideForm === false ?
<><form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{mode === 'editdata' && parseInt(formState.tbl) === 138 ? <>{getValue('description')} desc {formState.description}<CEditor curUpdate={'numbersInputckeditoredit'} ckEditorChange={ckEditorChange} id='description' name='description' value={getValue('description')} /></> : null}

{mode !== 'editdata' ? <>
<section className=''>{listInputs}</section> 
{(props.tbl == 133 && formState.frmtype == 2) ? <section className=''>{listFeatureInputs}</section> : null}</> : null}

{mode === 'editdata' && parseInt(formState.tbl) !== 138 ? <>
<section className=''>{listInputs}</section> 
{(props.tbl == 133 && formState.frmtype == 2) ? <section className=''>{listFeatureInputs}</section> : null}</> : null}




<section className='my-3'>
{(inputs.data && inputs.data.getSiteList.length > 0) ? <><span><Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
{props.handleCancel ? <Button onClick={() => { props.handleCancel(1)}} variant="outlined"  color="info mx-2">Cancel</Button> : null}
</span>
</> : null}
</section></form>
</>
: null}
</div>
</>
);

/*
case 147: // change ftp dir
return ( 
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) => {
//setFormState({ ...formState, [e.target.name]: e.target.value });
showOptionSource(e.target.name, e.target.value);
}} ><option>Lisy</option>
</select>
);
*/

case 28: // add to cart form
// string1= ssd, string2=vcpu, string3=bandwidth, string4=ram, string5=mysql , string6=mssql, string7=pgsql
{parseInt(infoStatus) === 1 && props.refetch ? props.refetch() : null}
return ( 
<form className='d-inline' name={`${props.viewid}frmname`} id={`${props.viewid}frmname`} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{Number(props.viewid)==28 ? <><section>{info}</section><section className='mt-5 mb-4'>
<span onClick={(e) => { e.preventDefault(); createRecord(); props.updateCart(); }}>
<AddShoppingCartIcon sx={{ marginRight:2, fontSize:35, color:'green' }} />
</span> 
</section></> : null}
</form>
);
case 29: // delete cart item
{parseInt(infoStatus) === 1 && props.updateCart ? props.updateCart() : null}
return (        
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
<span onClick={(e) => { e.preventDefault(); createRecord();  }}>
<Tooltip title="Remove item">
<DeleteIcon sx={{color:'#AE0000'}} /></Tooltip>
</span> 
</form>
);

case 32: // delete data
case 76: // delete database 
case 80: // delete user
case 87: // delete file / dir
case 94: // delete email
//case 100: // delete cron
//case 102: // delete dns
{parseInt(infoStatus) === 1 && props.refetch ? props.refetch() : null}
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (   
<>
{parseInt(props.tbl)!=161 ? <section>{info}</section> : null}
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <>
{props.viewid == 76 ? 
  <Alert className='mb-3' variant="filled" severity="warning">Database once dropped cannot be recovered</Alert> : null}
<section>
{parseInt(props.tbl)==161 ? <span className='pointer' onClick={(e) => { e.preventDefault(); createRecord(); props.updateDelete ? props.updateDelete() : null; }}>{<DeleteIcon sx={{color:'darkred'}} />}
</span> : <span onClick={(e) => { e.preventDefault(); createRecord(); props.updateDelete ? props.updateDelete() : null; }}>
<Button variant="outlined"  color="error" startIcon={<DeleteIcon />}>{props.label ? props.label : 'Delete'}</Button>
</span>}  
</section></> : null}</form>
</>
);
case 8: 
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />

<section className='row'>
<section className='col-md-6'>
<Typography className='fw-bold'>Current Fields</Typography>
<section className=''></section>
</section>


<section className='col-md-6'>
<Typography className='fw-bold'>{props.tbl} Add New Field {formState.tbl} secons</Typography>


<section className=''>
<section className='form-floating mt-2 mb-3'>
<Input placeholder="Title" handleChange={handleChange} value={formState.title} name="title" maxLength="150" type="text" className="form-control" aria-label="Title" required={1} />
</section>


<section className='form-floating mb-3'>
<select placeholder='Field Type' required className="form-control" id="city" name="city" value={formState.city} onChange={(e) =>
setFormState({
...formState,
[e.target.name]: e.target.value
})} >
<SelectList arg1='fieldtype' id={9} /> 
</select>
<label htmlFor={'city'}>Field Type *</label>
</section>

<section className='form-floating mb-3'>
<Input placeholder="Help Text" handleChange={handleChange} value={formState.description} name="description" maxLength="100" type="text" className="form-control" aria-label="Title" />
</section>


<section className='d-flex mb-3'>
<section className='flex-fill px-2'><label htmlFor="itemstatus">Required :</label>
<span className='mx-2 switchSize'>
<Switch name='itemstatus' id='itemstatus' onChange={requireField} checked={parseInt(formState.itemstatus) === 1 ? true : false}  /></span></section>

<section className='flex-fill px-2'><label htmlFor="planid">Enable :</label>
<span className='mx-2 switchSize'>
<Switch name='planid' id='planid' onChange={enableField} checked={parseInt(formState.planid) === 1 ? true : false}  /></span></section>
</section>
</section>


<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>
</section>  
</section>  
</form>
</div>
); 

case 68: // dns management
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 68 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section className='row'>
<section className='col-md-6 mb-3'>   
<Input placeholder="Host Name" handleChange={handleChange} value={formState.username} name="username" maxLength="30" type="text" className="form-control" aria-label="Host Name" />
<Typography>{formState.username}.{formState.title}</Typography> 
</section>
<section className='col-md-6 mb-3'> 
<div className='form-floating'>
<select required className="form-control" id='city' name='city' value={formState.city} onChange={handleChange} >
<option value=""> --Select-- </option>
<option value='A'>A</option>
<option value='AAAA'>AAAA</option>
<option value='CNAME'>CNAME</option>
<option value='MX'>MX</option>
<option value='TXT'>TXT</option>
<option value='SRV'>SRV</option>
<option value='DNAME'>DNAME</option>
<option value='PTR'>PTR</option>
</select>
<label htmlFor='city'>Type *</label>
</div>
</section>
<section className='col-md-6 mb-3'>  
<Input placeholder="Value" handleChange={handleChange} value={formState.fullname} name="fullname" maxLength="150" type="text" className="form-control" aria-label="Value" required />
</section>

<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>  
</section> : null} 
</form>
</div>);     


case 138: // submit ticket
{parseInt(infoStatus) === 1 ? hideElem() : null}
{props.refetch ? props.refetch() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />

{formState.hideForm === false ? 
<><section className='row'>
{Number(props.formmode) === 1 ? <><section className='col-md-6 mb-4'>  
<Input placeholder="Subject " handleChange={handleChange} value={formState.username} name="username" maxLength="30" type="text" className="form-control" aria-label="Subject " required />
</section>
<section className='col-md-6 mb-4'>  
<OptionList sourceId={117} label='Department' required id="gender" name="gender" value={formState.gender} handleChange={handleChange} />
</section></> : null}  
</section>  
<section className='col-md-12 mb-5'>  
{curUser !== null && parseInt(curUser.typeId) !== 0 ?
<Input placeholder="Add Correspondence" handleChange={handleChange} value={formState.string1} name="string1" maxLength="150" type="textarea" className="form-control" aria-label="Add Correspondence" required /> : null}


{curUser !== null && parseInt(curUser.typeId)===0 ?
<CEditor curUpdate={'staff[lyticket'} ckEditorChange={ckEditorChange} id="string1" required name="string1" value={getValue("string1")} /> : null}
</section>


<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section></> : null} 
</form>
</div>

);     


case 67: // email accounts
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 67 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section className='row'>
<section className='col-md-6 mb-3'>  
<section>
<Input placeholder="Account name" handleChange={handleChange} value={formState.username} name="username" maxLength="30" type="text" className="form-control" aria-label="Name" required />

<section>{formState.username}@{props.title}</section>  
</section>  
</section>
<section className='col-md-6 mb-3'> 
  
  
<section className="input-group mb-3">
<Input name="password" type='text' placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
<section className='helpText'>Password should be 6-15 characters and contain lowercase, uppercase and special character</section>
<section className='mt-2'><Button onClick={() => { generateStrongPassword(); }} variant="outlined"  color="info mx-2">Generate Password</Button></section>

</section>


<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>  
</section> : null} 
</form></div>);   


case 93: // change email account password
//{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section>
  
<section className="input-group mb-3">
<Input name="password" type='text' placeholder="Password" value={formState.password} required={true} handleChange={handleChange} /> <Button onClick={() => { generateStrongPassword(); }} variant="outlined"  color="info mx-2">Generate Password</Button>
</section>
<section className='medium'>Password should be 6-15 characters and contain lowercase, uppercase and special character</section>




<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>  
</section> : null} 
</form></div>);   



case 55: // add single element
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} ><input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section>
  <section className="input-group mb-3">
<Input name="title" type='text' placeholder={props.placeholder ? props.placeholder : ''} value={formState.title} required={true} handleChange={handleChange} />
</section><section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">{props.btnTitle ? props.btnTitle : 'Save'}</Input> </section>  
</section> : null} 
</form></div>);   




case 70: // issue letsencrypt
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} ><input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section><section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">{props.btnTitle ? props.btnTitle : 'Issue Certificate'}</Input> </section>  
</section> : null} 
</form></div>);   
case 12: // add home dierctory
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section>
<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-primary btn-sm">Save</Input> 
</section>  
</section> : null} 
</form></div>);   


case 85: // copy files
case 84: // move files
case 86: // archive files
case 98: // unzip archive files
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<div className="">
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" />
{formState.hideForm === false ? <section>
  
<section className='my-3'>
<Input type="submit" name="save" id="save" className="btn btn-success px-3 text-uppercase">{props.label ? props.label : 'Save' }</Input> 
</section>  
</section> : null} 
</form></div>);  

case 33: // change password
return (        
<section>
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-2">
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="password" type={passwordShow} placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
<section className="col-sm-6 mb-2">
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="confirmPassword" type={passwordShow} placeholder="Confirm Password" value={formState.confirmPassword} handleChange={handleChange} required={true} />
</section>

</section> 
</section>
<Typography className="alert alert-info" role="alert">
Password must contain a number, special character, uppercase letter, lowercase letter and be 6 to 15 characters long
</Typography>
<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>

</section>
);


case 42: // name server
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 42 ? hideElem() : null}
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className='mb-2 mt-2'>
<Alert variant="filled" severity="info">Name server propagation might take up to 72 hours</Alert></section>
<section className='mb-4'><span>Select use defaults and click Save to use our name servers </span><span><Button onClick={() => { useDefaultNS(); }} variant="outlined"  color="info mx-2">Use Defaults</Button></span></section>
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Name Server 1" value={formState.string1} required={true} handleChange={handleChange} name='string1' label='Name Server 1' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Name Server 2" value={formState.string2} handleChange={handleChange} name='string2' label='Name Server 2' />
</section>

<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Name Server 3" value={formState.string3} handleChange={handleChange} name='string3' label='Name Server 3' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Name Server 4" value={formState.string4} handleChange={handleChange} name='string4' label='Name Server 4' />
</section>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);


case 161: // Env variable
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="mb-2">
<Input type='text' placeholder="Name" value={formState.string3} required={true} handleChange={handleChange} name='string3' label='Name' />
</section>
<section className="mb-2">
<Input type='text' placeholder="Value" value={formState.string2} required={true} handleChange={handleChange} name='string2' label='Value' />
</section>

<section>
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section> 
</form>
</section>
);


case 64: // user account
{parseInt(infoStatus) === 1 && parseInt(props.formmode) == 1 && parseInt(formState.tbl) === 64 ? hideElem() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} > 
<section className="row mx-4">
{parseInt(props.formmode) == 1 ? 
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Username" value={formState.fullname} required={true} handleChange={handleChange} name='fullname' label='Username' helpText='Username should be alphanumeric between 3 and 15 characters' />
</section> : null}
<section className="col-sm-6 mb-4">
<section className="input-group mb-3">
<Input name="password" type='text' placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
<section className='helpText'>Password should be 6-15 characters and contain lowercase, uppercase and special character</section>
<section className='mt-2'><Button onClick={() => { generateStrongPassword(); }} variant="outlined"  color="info mx-2">Generate Password</Button></section>
</section>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);



case 69: // ssl certificate
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 69 ? hideElem() : null}
return (        
<section>
<section>{info}</section>
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='file' placeholder="Certificate File" value={formState.fullname} required={true} handleChange={handleChange} handleFile={fileUpload} name='fullname' label='Certificate File' helpText='.PEM' />
</section>
<section className="col-sm-6 mb-4">
<section className="input-group mb-3">
<Input type='file' placeholder="Key File" value={formState.username} required={true} handleChange={handleChange} name='username' label='Key File' helpText='.PEM' />
</section>
</section>


<section className="col-sm-6 mb-4">
<section className="input-group mb-3">
<Input type='file' placeholder="Chain File" value={formState.zip} required={true} handleChange={handleChange} name='zip' label='Chain File' helpText=' .PEM' />
</section>
</section>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
); //end ssl certificate
case 63: // add domain
case 88: // add sub domain
{parseInt(infoStatus) === 1 && (parseInt(formState.tbl) === 63 || parseInt(formState.tbl) === 88) ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder={parseInt(props.viewid) === 88 ? 'Sub Domain Name' : 'Domain Name'} value={formState.fullname} required={true} handleChange={handleChange} name='fullname' label={parseInt(props.viewid) === 88 ? 'Sub Domain Name' : 'Domain Name'} />
{parseInt(props.viewid) === 88 ? 
<section>{formState.fullname}.{formState.string1}</section> : null}
</section>
<section className="col-sm-6 mb-4">
<div className='form-floating'>
<select required className="form-control" id='title' name='title' value={formState.title} onChange={handleChange} >
<option value=""> --Select-- </option>
<option value='iis'>IIS</option>
<option value='apache'>Apache</option>
<option value='nodejs-v22.11.0'>NodeJS - v22.11.0</option>
</select>
<label htmlFor='title'>Application Type *</label>
</div>
</section>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);

case 81: // create dir
case 82: // create file
//{parseInt(infoStatus) === 1 && (parseInt(formState.tbl) === 81 || parseInt(formState.tbl) === 82) ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder={parseInt(props.viewid) === 81 ? "Directory name" : 'File name'} value={formState.fullname} required={true} handleChange={handleChange} name='fullname' label={parseInt(props.viewid) === 81 ? "Directory name" : 'File name'} />
</section>
</section>

<section className="mx-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>
: null}
</section>
);


case 97: // rename
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 97 ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder='Name' value={formState.fullname} required={true} handleChange={handleChange} name='fullname' label='Name' />
</section>
</section>

<section className="mx-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>
: null}
</section>
);

case 83: // upload files
//{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 63 ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); domainFiles(); }} >
{formState.total != 'undefined' && parseInt(formState.total) > (parseInt(props.maxfile ? props.maxfile : 15)) ? <Alert variant="filled" severity="warning">{`Maximum ${props.maxfile ? props.maxfile : 15} files exceeded`}</Alert> : null}


{formState.total != 'undefined' && parseInt(formState.total) === 0 ? <Alert variant="filled" severity="info">No file selected</Alert> : null}

{!props.overwriteoption ? <section className='mb-2 mt-3'>Overwrite Files if exists: 
<Switch name='status' id='status' onChange={handleChange} checked={formState.status == true ? true : false} size="small" />
</section> : null}


<section className="mb-4">
<input onChange={countFiles} type="file" id="files" name="files" multiple />
</section>

<section>
<table className='customers'>
<thead className='thead'><tr><th>File name</th><th>Progress</th></tr>
</thead>
<tbody id={props.fileId}>

</tbody>
</table>
</section>

{formState.total != 'undefined' && parseInt(formState.total) > 0 && parseInt(formState.total) < (props.maxfile ? parseInt(props.maxfile)+1 : 16) && formState.planstate != -1 ? <section className="mt-3">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Upload</Input>
</section>  : null}  
</form>
: null}
</section>
);


case 46: // server setup
case 59: // hosting setup location=zip, 
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === (59 || 46) ? hideElem() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className='fw-bold display5 mb-2'>Server Setup</section>  
<section className="row mx-4">
<section className="col-md-6 mb-4">
<Input type='text' placeholder="Title" value={formState.fullname} required={true} 
handleChange={handleChange} name='fullname' />
</section>


<section className="col-md-6 mb-4">
<OptionList sourceId={2118} label='Server Location' required={true} id="zip" name="zip" value={formState.zip} handleChange={handleChange} />
</section>

{parseInt(props.viewid) === 46 ?
<section className="col-md-6 mb-4">
<div className='form-floating'>
<DropDownOptions id={2} arg1='getSelectList' arg2={11} arg3='2119' name='gender' value={formState.gender} required handleChange={handleChange} label='Server Type *' />
</div>
</section> : null } 
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);

case 153: // resolve dns
case 151: // startup script
case 152: // APP REDIRECT
case 158: // IP Blocker
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} > 
<section className='mb-2'>
<Input type='text' placeholder={props.label} value={formState.fullname} required={true} handleChange={handleChange} name='fullname' />
</section>
<section>
<Input type="submit" name="save" className="btn btn-primary btn-sm">{props.btn ? props.btn : 'Save'}</Input>
</section>  
</form>
</section>
);


case 75: // create free web and email hosting 
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 75 ? hideElem() : null}
return (        
<section>
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 75 ? <section dangerouslySetInnerHTML={{__html: infoMsg}}></section> : null} 
{formState.address}
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} > 
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm"><span style={{ fontSize: '18px', padding:'10px', textTransform: 'uppercase', color:'#ffffff', backgroundColor:'#20A120', fontWeight:'bold', }}>
Activate FREE WEB and EMAIL HOSTING for my account</span></Input>
</section>  
</form>
: null}
</section>
);

case 79: // admin update server info
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 79 ? hideElem() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-md-6 mb-4">
<OptionList sourceId={7931} label='Root username' required={true} id="string1" name="string1" value={formState.string1} handleChange={handleChange} />
</section>

<section className="col-md-6 mb-4">
<Input type='text' placeholder="Root user password" value={formState.string2} required={true} handleChange={handleChange} name='string2' />
</section>
<section className="col-md-6 mb-4">
<OptionList ip={true} sourceId={props.region} label='IP Address' required={true} id="string3" name="string3" value={formState.string3} handleChange={handleChange} />
</section>

{parseInt(props.viewid) === 46 ?
<section className="col-md-6 mb-4">
<div className='form-floating'>
<select required className="form-control" id='gender' name='gender' value={formState.gender} onChange={handleChange} >
<option value=""> --Select-- </option>
<option value='-100'>Windows</option>
<option value='-200'>Linux</option>
</select>
<label htmlFor='gender'>Server Type *</label>
</div>
</section> : null } 
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);
case 65: // add database
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 65 ? hideElem() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className='fw-bold display5 mb-2'>Server Setup</section>  
<section className="row mx-4">
<section className="col-md-6 mb-4">
<Input type='text' placeholder="Database name" value={formState.fullname} required={true} 
handleChange={handleChange} name='fullname' helpText='Database name should be alphanumeric with no spaces' />
</section>


<section className="col-md-6 mb-4">
<div className='form-floating'>
<select required className="form-control" id='zip' name='zip' value={formState.zip} onChange={handleChange} >
<option value=""> --Select-- </option>
<option value='mysql'>MySQL (MySQL Server)</option>
<option value='mssql'>MSSQL (Microsoft SQL Server)</option>
<option value='pgsql'>PgSQL (PostgreSQL)</option>
</select>
<label htmlFor='zip'>Type *</label>
</div>
</section>

{parseInt(props.viewid) === 46 ?
<section className="col-md-6 mb-4">
<div className='form-floating'>
<select required className="form-control" id='gender' name='gender' value={formState.gender} onChange={handleChange} >
<option value=""> --Select-- </option>
<option value='-410'>Windows</option>
<option value='-420'>Linux</option>
</select>
<label htmlFor='gender'>Server Type *</label>
</div>
</section> : null } 
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);


case 43: // whois information
{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 43 ? hideElem() : null}
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className='mb-2 fw-bold pageTitle pb-3 mb-3 mt-3'>Registrant (Owner) Information</section>
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Registrant Name" value={formState.string1} required={true} handleChange={handleChange} name='string1' helpText='The name of the individual or organization that owns the domain ' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Registrant Organization" value={formState.string2} handleChange={handleChange} name='string2' helpText='The name of the organization (if applicable).' />
</section>

<section className="col-sm-6 mb-4">
<Input type='email' placeholder="Registrant Email Address" value={formState.string3} handleChange={handleChange} name='string3'required={true}  helpText='The contact email of the domain owner' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Registrant Phone Number" value={formState.string4} handleChange={handleChange} required name='string4' helpText='The phone number of the domain owner (may be masked in privacy protection)' />
</section>

<section className="col-sm-12 mb-4">
<Input type='text' placeholder="Registrant Address" value={formState.string5} handleChange={handleChange} name='string5' required helpText='The postal address of the domain owner (may be hidden with privacy services)' />
</section>
</section>




<section className='mb-2 fw-bold pageTitle pb-3 mb-3 mt-3'><span>Administrative Contact Information</span><span className='px-3'><Button onClick={() => { useSameInfo(1); }} variant="outlined"  color="info mx-2">Same as registrant info</Button></span></section>
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Admin Name" value={formState.string6} required={true} handleChange={handleChange} name='string6' helpText='The name of the administrative contact (can be the same as the registrant or different).' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Admin Organization" value={formState.string7} handleChange={handleChange} name='string7' helpText="The administrative contact's organization (if applicable)."/>
</section>

<section className="col-sm-6 mb-4">
<Input type='email' required placeholder="Admin Email" value={formState.string8} handleChange={handleChange} name='string8' helpText='The contact email for the administrative contact' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' required placeholder="Admin Phone Number" value={formState.string9} handleChange={handleChange} name='string9' helpText='The phone number of the administrative contact' />
</section>

<section className="col-sm-12 mb-4">
<Input type='text' required placeholder="Admin Address" value={formState.string10} handleChange={handleChange} name='string10' helpText='The postal address of the administrative contact' />
</section>
</section>

<section className='mb-2 fw-bold pageTitle pb-3 mb-3 mt-3'><span>Technical Contact Information</span><span className='px-3'><Button onClick={() => { useSameInfo(2); }} variant="outlined"  color="info mx-2">Same as registrant info</Button></span></section>
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Tech Name" value={formState.string11} required={true} handleChange={handleChange} name='string11' helpText='The name of the technical contact (used for managing domain-related technical issues).' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Tech Organization" value={formState.string12} handleChange={handleChange} name='string12' helpText="The technical contact's organization (if applicable)."/>
</section>

<section className="col-sm-6 mb-4">
<Input type='email' placeholder="Tech Email" value={formState.string13} handleChange={handleChange} name='string13' required helpText='The contact email for the technical contact' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Tech Phone Number" value={formState.string14} handleChange={handleChange} name='string14' required helpText='The phone number of the technical contact' />
</section>

<section className="col-sm-12 mb-4">
<Input type='text' placeholder="Tech Address" value={formState.string15} handleChange={handleChange} name='string15' required label='Tech Address' helpText='The postal address of the technical contact' />
</section>
</section>



<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);


case 5: // contact info
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section className=''>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Full name" value={formState.string1} required={true} handleChange={handleChange} name='string1' helpText='Your full name' />
</section>
<section className="col-sm-6 mb-4">
<OptionList sourceId={3} label='Gender' required={true} id="string2" name="string2" value={formState.string2} handleChange={handleChange} />
</section>

<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Address" required={true} value={formState.string3} handleChange={handleChange} name='string3' />
</section>
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="City" required={true} value={formState.string4} handleChange={handleChange} name='string4' />
</section>

<section className="col-sm-6 mb-4">
<Input type='text' placeholder="State / Province" required={true} value={formState.string5} handleChange={handleChange} name='string5' />
</section>

<section className="col-sm-6 mb-4">

<OptionList sourceId={2} label='Country' required={true} id="string6" name="string6" value={formState.string6} handleChange={handleChange} />
</section>
<section className="col-sm-6 mb-4">
<Input type='tel' required placeholder="Phone" value={formState.string7} handleChange={handleChange} name='string7' />
</section>
</section>


<section className="mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);

case 136: // organization info
//{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 42 ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className="row mx-4">
<section className="col-sm-6 mb-4">
<Input type='text' placeholder="Name" value={formState.string1} required={true} handleChange={handleChange} name='string1' helpText='Your organization name' />
</section>


<section className="col-sm-6 mb-4">
<Input type='tel' placeholder="Phone" value={formState.string3} handleChange={handleChange} name='string3' />
</section>

<section className="col-sm-6 mb-4">
<Input type='email' placeholder="Email" required={true} value={formState.string2} handleChange={handleChange} name='string2' />
</section>

<section className="col-sm-6 mb-4">
<Input type='url' placeholder="Website" value={formState.string4} handleChange={handleChange} name='string4' helpText='https://yoursite.com' />
</section>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
: null}
</section>
);
case 52: // glue records
//{parseInt(infoStatus) === 1 && parseInt(formState.tbl) === 42 ? hideElem() : null}
//{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section className='consultancy'>
<section>{info}</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />

<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section className='d-flex align-items-center justify-content-center mb-3'>
<span className='mx-1'><Input type='text' placeholder="I.P Address" value={formState.string1} required={true} handleChange={handleChange} name='string1' label='I.P Address' /></span>

<span className='mx-1'><Input type='text' placeholder="Host" value={formState.string2} handleChange={handleChange} name='string2' label='Host' required={true} /></span>

<span className='mx-1'>{props.displayDomain}</span>
</section>

<section className='d-flex align-items-center justify-content-center mb-3'>
<span className='mx-1'><Input type='text' placeholder="I.P Address" value={formState.string3} handleChange={handleChange} name='string3' label='I.P Address' /></span>

<span className='mx-1'><Input type='text' placeholder="Host" value={formState.string4} handleChange={handleChange} name='string4' label='Host' /></span>

<span className='mx-1'>{props.displayDomain}</span>
</section>

<section className='d-flex align-items-center justify-content-center mb-3'>
<span className='mx-1'><Input type='text' placeholder="I.P Address" value={formState.string5} handleChange={handleChange} name='string5' label='I.P Address' /></span>

<span className='mx-1'><Input type='text' placeholder="Host" value={formState.string6} handleChange={handleChange} name='string6' label='Host' /></span>

<span className='mx-1'>{props.displayDomain}</span>
</section>


<section className='d-flex align-items-center justify-content-center mb-3'>
<span className='mx-1'><Input type='text' placeholder="I.P Address" value={formState.string7} handleChange={handleChange} name='string7' label='I.P Address' /></span>

<span className='mx-1'><Input type='text' placeholder="Host" value={formState.string8} handleChange={handleChange} name='string8' label='Host' /></span>

<span className='mx-1'>{props.displayDomain}</span>
</section>

<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</section>
</form>
</section>
);
case 36: // cancel aCCOUNT
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section style={{maxWidth:'100%',}}>
<section>{info}</section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>
<section className="input-group mb-3">
<span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
<Input name="password" type={passwordShow} placeholder="Password" value={formState.password} required={true} handleChange={handleChange} />
</section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
</section>
<section className='d-flex flex-wrap'>
<div className="wrap">
<Alert variant="filled" severity="warning">
<p>All products associated with your account will be terminated and any data you have with us will be deleted. </p>
<p>For legal requirements, we might hold your data for such length of time as may be required by law or stated in our legal documents</p>
<p>Account cancelled cannot be reactivated, please enter your password to cancel your account</p> 
</Alert>
</div>
</section>
<section className="row mx-4 mt-4">
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-danger btn-md">Cancel Account</Input>
</section>   
</section>
</form>

</section>
);


case 73: // back up
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section style={{maxWidth:'100%',}}>
<section>{info} </section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
</section>
<section className="mb-4 mt-4">
<Input type="submit" name="save" className={`btn btn-${props.savebtn} btn-md`}>{props.label}</Input></section>
</form></section>
);


case 74: // schedule backup
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (        
<section style={{maxWidth:'100%',}}>
<section>{info} </section>
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
</section>

<>
<section className='row'>
<section className="col-md-6 p-2">
<Input name="username" type="text" placeholder="Title" value={formState.username} handleChange={handleChange} required />
</section>

<section className="col-md-6 p-2">
<section className='form-floating'> 
  <select required className="form-control" id='total' name='total' value={formState.total} onChange={handleChange} >
  <option value=""> --Select-- </option>
  <option value={1}>Minutes</option>
  <option value={2}>Hourly</option>
  <option value={3}>Daily</option>
  <option value={4}>Weekly</option>
  <option value={5}>Monthly</option>
  </select>
  <label htmlFor='zip'>Frequency *</label>
  {parseInt(formState.total) === 3 ? <small>Runs at 00.00 hours every day</small> : null}
  </section>
  </section>

{parseInt(formState.total) === 1 ? <section className="col-md-6 p-2">
<Input name="gender" type="number" placeholder="Minutes interval" value={formState.gender} handleChange={handleChange} required />
</section> : null}  

{parseInt(formState.total) === 4 ? <section className="col-md-6 p-2">
<ListDays days={7} value={formState.country} name='country' label='Day of the week' required handleChange={handleChange} helpText='Runs at 00.00 hours on the selected day' />
</section> : null}

{parseInt(formState.total) === 5 ? <section className="col-md-6 p-2"><ListDays days={31} value={formState.description} name='description' helpText='Runs at 00.00 hours on the selected day' label='Day of the month' handleChange={handleChange} /></section> : null}

</section>
</> 

<section className="mb-4 mt-4">
<Input type="submit" name="save" className={`btn btn-${props.savebtn} btn-md`}>{props.label}</Input></section>
</form></section>
);

case 57: // create cron
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
{parseInt(infoStatus) === 1 ? hideElem() : null}
return (        
<section style={{maxWidth:'100%',}}>
<section>{info} </section>
{formState.hideForm === false ?
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section> 
<input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
</section>
{parseInt(props.gender) === 2 ? 
<>
<section className='row'>
<section className="col-md-6 p-2">
<section className='pb-2 mb-2'>
<Input name="fullname" type="text" placeholder="File to run" value={formState.fullname} handleChange={handleChange} helpText={`Enter a local PHP file path or a URL Address. <br />example: /${props.title}/path/to/file. or https://yourdomain.com/filepath`} required />  
</section>  
</section>

<section className="col-md-6 p-2">
<section className='form-floating'>
  <select required className="form-control" id='total' name='total' value={formState.total} onChange={handleChange} >
  <option value=""> --Select-- </option>
  <option value={1}>Minutes</option>
  <option value={2}>Hourly</option>
  <option value={3}>Daily</option>
  <option value={4}>Weekly</option>
  <option value={5}>Monthly</option>
  </select>
  <label htmlFor='total'>Frequency</label>
{parseInt(formState.string2) === 3 ? <Typography>Runs once every day</Typography> : null}
</section>
<section className='mb-3 mt-3'>
{parseInt(formState.total) === 1 ? 
<Input name="string2" type="number" placeholder="Minutes interval" value={formState.string2} handleChange={handleChange} helpText='Interval between re-runs in minutes. Minimum of 10 minutes interval' required />
: null}  

{parseInt(formState.total) === 4 ? 
<ListDays days={7} value={formState.string2} name='string2' label='Day of the week' handleChange={handleChange} helpText='Runs once on the selected day' />
 : null}

{parseInt(formState.total) === 5 ? <ListDays days={31} value={formState.string2} name='string2' helpText='Runs once on the selected day' label='Day of the month' handleChange={handleChange} /> : null}
</section>
</section>
</section>
</> 
: null}


<section className="mb-4 mt-4">
<Input type="submit" name="save" className={`btn btn-${props.savebtn} btn-md`}>{props.label}</Input></section>

</form>: null }</section>
);


case 22: // domain search form
return (        
<>
<section className="d-flex">
<span className="flex-fill px-1">
<input onChange={handleChange} type="text" name="title" maxLength="140" className="form-control" placeholder='Enter a domain name' aria-label="Domain name" value={formState.title} size='40' id='title' required />
</span>
<span className="flex-fill">
<OptionList sourceId={152} placeholder='' required={true} id="city" name="city" value={formState.city} handleChange={handleChange} />
</span>
<span className="flex-fill px-1 pointer"><TravelExploreIcon onClick={() => { searchDomain()}} />
</span>
</section>
</>);  


  
case 34: // two way authentication
case 44: // DOMAIN LOCK
case 45: // DOMAIN PRIVACY
case 47: // DOMAIN Parking
case 58: // DB, FTP Settings
case 92: // enable email
case 101: // enable cron
case 103: // enable data
case 154: // hot link
case 160: // deny / allow ip address
case 159: // redirect type: temporary / permanent
case 163: // app mode dev / production
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (<> {parseInt(props.viewid) === 44 ? <span className='px-3'>Enable Domain Locking</span> : null}
{parseInt(props.viewid) === 45 ? <span className='px-3'>Enable Domain Privacy</span> : null}

{props.switchType ? 
<FormControl
orientation="horizontal"
component='span'
sx={{ justifyContent: 'space-between' }}
>
<SwitchJ
checked={parseInt(formState.itemstatus) === 1 ? true : false}
onChange={handleSwitchChange} 
color={(parseInt(formState.itemstatus) === 1 ? true : false) ? 'success' : 'neutral'}
variant={(parseInt(formState.itemstatus) === 1 ? true : false) ? 'solid' : 'outlined'}
endDecorator={(parseInt(formState.itemstatus) === 1 ? true : false) ? (props.textOn ? props.textOn : 'On') : (props.textOff ? props.textOff : 'Off')}
slotProps={{
endDecorator: {
sx: {
minWidth: 24,
},
},
}}
/>
</FormControl> :

<Switch onChange={handleSwitchChange} aria-label={props.label ? props.label : ''} checked={parseInt(formState.itemstatus) === 1 ? true : false} size="small" />
}

</>);


case 72: // ftp actions
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
return (<>country {props.country} country fsate {formState.country} ftp actions {formState.itemstatus} props {props.itemstatus}<input type='radio' name='ftpaction' value={formState.country} id={props.inputid} onChange={handleRadioItemUpdate} className='' /></>);


case 149: // start node app
return (<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>{info}</section>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" /> 
<section className="m-4">
<Button type="submit" sx={{ fontWeight:'bold', }} variant="contained" color='success' startIcon={<CheckCircleIcon />}>Confirm</Button>
</section>   
</form>); 


case 71: // switch items wth item status
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch(formState.itemstatus) : null}
return (<> {formState.itemstatus} props {props.itemstatus}
<Switch onChange={handleSwitchChange} aria-label={props.label ? props.label : ''} checked={parseInt(formState.itemstatus) === 1 ? true : false} size="small" /></>);  

case 18: // update quantity
//{(parseInt(infoStatus) === 1 && props.updateCart) ? props.updateCart() : null}

if(parseInt(props.typeid) === 1040){ return (
  <select required id='total' className='form-control' name='total' value={formState.total} onChange={handleChange} >
  <option value={1}>1</option>
  <option value={2}>2</option>
  <option value={3}>3</option>
  </select>);}

if(parseInt(props.typeid) === 1041){ return (
  <select required id='total' className='form-control' name='total' value={formState.total} onChange={handleChange} >
  <option value={1}>1</option>
  <option value={3}>3</option>
  <option value={6}>6</option>
  <option value={12}>12 </option>
  <option value={18}>18 </option>
  <option value={24}>24 </option>
  </select>);}



case 35: // recovery email
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
{(parseInt(infoStatus) === 1) ? info = <div className="alert alert-success" role="alert">Saved</div> : null}
return (   
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>{info}</section>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" /> 
<section className="mb-4">
<Input name="username" type="email" placeholder="Enter your recovery email" value={formState.username} helpText='This email will be used to recover your account in case you lose access to the login email account' handleChange={handleChange} required={true} />
</section>
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>); 

case 37: // support pin
{(parseInt(infoStatus) === 1 && props.refetch) ? props.refetch() : null}
{(parseInt(infoStatus) === 1) ? info = <div className="alert alert-success" role="alert">Saved</div> : null}
return (   
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); createRecord(); }} >
<section>{info}</section>
<input type="hidden" value={props.formmode} id='frmtype' name="frmtype" /> 
<section className="mb-4">
<Input name="total" type="number" placeholder="Support PIN" value={formState.total} helpText='The support PIN will be required by a support staff to access your account for technical assistance' handleChange={handleChange} required={true} />
</section>
<section className="mb-4">
<Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
</section>   
</form>);   
case -12: // login
return (        
<form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); prcRecord(props.viewid); }} >
<main className="bg-white">
<section className="card bg-white">
            {info}
            <article className="card bg-white">
              <section className="card-header frmTitle">
              Sign in	
              </section>
                  <section className="card-body p-4">
                    <section className="input-group mb-3">
                      <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
                      <Input name="username" type="email" placeholder="Username" value={formState.username} 
                        handleChange={handleChange} required={true} />
                    </section>
                    <section className="input-group mb-3">
                        <span className="input-group-text"  onClick={passwordVisibility} id="basic-addon1"><VisibilityOffIcon /></span>
                        <Input name="password" type={passwordShow} placeholder="Password" value={formState.password} 
                        handleChange={handleChange} required={true} />
                    </section>
                    <section className='mb-2 card-title'>
                      <Input name="continueBtn" className="btn btn-success form-control" type="submit">
                        Continue
                      </Input>
                    </section>
                    <section className='mb-2'>
                    <RDom.Link to="/acct/112/password"  className="btn btn-light form-control">
                      Reset Password
                    </RDom.Link>
                    </section>  
                     <section className='mb-2'>
                    <RDom.Link to="/acct/108/Create Account"  className="btn btn-light form-control">
                      Create Account
                    </RDom.Link>
                    </section>  
                  
                  </section>
              </article>
            </section>
        </main>
		</form>
 ); // end login
      case -1:
      return (        
        <section>
    <section>
    {prcRecordPrg}
          {info}
        </section>
          <form name={frmname} id={frmname} onSubmit={(e) => { e.preventDefault(); prcRecord(props.viewid);  }} >
          <section className="row mx-4">
                <section className="col-sm-6 mb-2">
                <label htmlFor="username">New Password *</label><br />
                <section className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><i onClick={passwordVisibility} className="fas fa-eye-slash"></i></span>
                    <Input required="required" name="password" type={passwordShow} placeholder="Password" value={formState.password} 
                    handleChange={handleChange} 
                    title="Password must contain a number, special character, uppercase letter, lowercase letter and be 7 to 15 characters long"   />
                </section>
                </section>
                <input type="hidden" value={props.formmode} name="frmtype" onChange={handleChange} />
                <section className="col-sm-6 mb-2">
                <label htmlFor="username">Confirm New Password *</label><br />
                <section className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><i onClick={passwordVisibility} className="fas fa-eye-slash"></i></span>
                    <Input required="required" name="confirmPassword" type={passwordShow} placeholder="Confirm Password" value={formState.confirmPassword} 
                    handleChange={handleChange} />
                </section>
               
                </section> 
              </section>
              <section className="alert alert-info" role="alert">
                Password must contain a number, special character, uppercase letter, lowercase letter and be 6 to 15 characters long
                </section>
              <section className="row mx-4">
                <section className="mb-4">
                    <Input type="submit" name="save" className="btn btn-primary btn-sm">Save</Input>
                  </section>   
              </section>
            </form>
          
        </section>
        );
       //-4 is for user profile container
    
   
   
     
        default:
          return null;
      } 
   }
        return null;
    }
  


function Input(props){
var label = "";
switch(props.type) {
case "submit":
case "button":
return (
<button type={props.type} className={props.className}>{props.children}</button>
);

case "text":
case "email":
case "password":
case "tel":
case "decimal":
case "date":
case "number":
case "url":
case "tel":
case "time":
case "date-time":
case "month":
case "week":
case "password":


return (<Typography className='form-floating'>
<input type={(props.type == 'decimal') ? 'number' : props.type} maxLength={props.maxLength !== 'undefined' ? props.maxLength : '100'} placeholder={props.title !== 'undefined' ? props.title : null} onChange={props.handleChange} name={props.name} id={props.name} className={'form-control '+props.className} value={props.value ? props.value : ''} required={(props.required !== 'undefined' && (parseInt(props.required) == 1 || props.required == true)) ? true : false} onFocus={(props.handleFocus !== 'undefined') ? props.handleFocus : null} min={(props.min !== 'undefined') ? props.min : null} max={(props.max !== 'undefined') ? props.max : null} step='0.01' />
<label htmlFor={props.name}>{(props.placeholder !== 'undefined') ? props.placeholder : null} {(props.required !== 'undefined' && (parseInt(props.required) === 1 || props.required === true)) ? '*' : null}</label><span className=''></span><small className="helpText" dangerouslySetInnerHTML={{__html: (props.helpText !== 'undefined') ? props.helpText : ''}}></small></Typography>
); 
case "select":
return (<>
<OptionList sourceId={typeof props.sourceId !== 'undefined' && props.sourceId != null && props.sourceId != '' ? props.sourceId : props.string2} placeholder={props.placeholder} label={props.placeholder} required={props.required} id={props.name} itemId={props.itemId} name={props.name} value={props.value} handleChange={props.handleChange} /></>
);

case "image":
case "file":
return (
<div>
  <label htmlFor={props.name}>{(props.placeholder !== 'undefined' ) ? props.placeholder : 'Select File'}</label><br />
  <input id={props.name} name={props.name} onChange={props.handleFile} type="file" />
</div>
); 

case "textarea":
return (<>
<span className='form-floating'>
<textarea style={{width:'100%', height:'300px'}}
rows={10}
value={props.value}
maxLength={props.maxLength !== 'undefined' ? props.maxLength : '1000'}
aria-label={props.title}  
required={(props.required !== 'undefined' || (parseInt(props.required) === 1 || props.required === true)) ? true : false}
name={props.name} 
id={props.name}
onChange={props.handleChange}
className='form-control'
/>
<label htmlFor={props.name}>{(props.placeholder !== 'undefined') ? props.placeholder : 'Details'} {(props.required !== 'undefined'  && props.required === true) ? '*' : null}</label></span>
</>
); 

default:
return (null); 
} 
}



export {Input}