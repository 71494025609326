import { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Form from '../../components/form/form';
import { ProductFeatures } from '../../components/utility/utility';
import Button from '@mui/material/Button';
import FreeMail from '../../assets/freeemail.png';
import Free from '../../assets/free.png';


import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
// AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Card from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import {AddToCart} from '../../components/utility/utility';

export default function App(props) { 
const {catid, id} = useParams();
const theme = useTheme();
const [value, setValue] = useState(0);
const handleChange = (event, newValue) => {
setValue(newValue);
};

const numbersHosting = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117 && parseInt(currItem.itemId) != 2224 && parseInt(currItem.inid) != 2224; });
//const numbersHosting = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 117; });
const numbersServers = props.products.getSiteList.filter(function(currItem) { return parseInt(currItem.string5) == 118; });
let listServers='';

let hostingFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 117 || parseInt(currItem.planid) == 0; });
let serverFeatures = props.productFeatures.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 118 || parseInt(currItem.planid) == 0; });

hostingFeatures = hostingFeatures.sort((a, b) => a.total - b.total);
serverFeatures = serverFeatures.sort((a, b) => a.total - b.total);

function passPrdId(prdId){
let listhostingFeatures='';
if(hostingFeatures.length > 0){
listhostingFeatures = hostingFeatures.map((number, index) => 
<section key={'listhostingFeaturessgsig'+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title} {number.address}</section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
{parseInt(number.itemId) === 13 ? number.zip : null}
{ (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) == -1) ? 'Unlimited' : null}
{parseInt(number.itemId) !== 13 && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) == 0) ? 0 : null}
{parseInt(number.itemId) !== 13 && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) != 0)  && (getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId) != -1)? <>{getFeatureValue(numbersHosting,(number.fullname), hostingFeatures, prdId)}{number.zip}</> : null}

</section>
</section>
</section>
);}return listhostingFeatures;}

function passServId(prdId){
let listserverFeatures='';
if(serverFeatures.length > 0){
listserverFeatures = serverFeatures.map((number, index) => 
<section key={'listserverFeatureswr5742'+number.id} className="d-flex featureList align-items-center p-1">
<section className="flex-fill col-7">{number.title} </section>
<section className="col-5 d-flex flex-column">
<section className="flex-fill d-flex flex-row-reverse">
<span className='text-end'>{parseInt(number.itemId) !== 13 ? getFeatureValue(numbersServers,(number.fullname), serverFeatures, prdId) : null} {number.zip}</span>
</section>
</section>
</section>
);}
return listserverFeatures;
}

function getFeatureValue(x,y,z,productid){
let colN = y;
function findNamesByFavoriteFood(list, favoriteFood) {
let fitem = list.filter((obj) => obj[colN] != '');//.map((obj) => obj.name);
let sitem = fitem.filter((obj) => (obj.itemId == productid || obj.inid == productid)).map((obj) => obj[colN]);
return sitem;
}return (findNamesByFavoriteFood(x, y));}

function productHeaderClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('bg-success text-white p-2 text-center');
case 1:  
return ('bg-primary text-white p-2 text-center');
case 2:  
return ('bg-info text-white p-2 text-center');
case 3: 
return ('bg-secondary text-white p-2 text-center');
default:
return ('bg-primary text-white p-2 text-center');
}
}

function productServersClass(x) { 
switch(parseInt(x) ? parseInt(x) : 0) {
case 0: 
return ('server1 text-white p-2 text-center');
case 1:  
return ('server2 text-white p-2 text-center');
case 2:  
return ('server3 text-white p-2 text-center');
case 3: 
return ('server4 text-white p-2 text-center');
default:
return ('server5 text-white p-2 text-center');
}
}

let useId = 0;
if(parseInt(catid) == 116){useId=209}
if(parseInt(catid) == 117){useId=215}
if(parseInt(catid) == 118){useId=216}
if(parseInt(catid) == 7){useId=217}
if(parseInt(catid) == 120){useId=357}
const numbersDomain = props.pages.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid == 0 ? currItem.itemId : currItem.inid) == useId) ; });
const numbersProducts = props.products.getSiteList.filter(function(currItem) { return (parseInt(currItem.string5) == parseInt(catid)) ; });

let listItems='';
if(numbersProducts.length > 0){
listItems = numbersProducts.map((number, index) =>
// Correct! Key should be specified inside the array. 
<Tab key={'productHeader'+number.id} label={number.string1} {...a11yProps(index)} />
);}

function getDetails(id=0,type='title'){
let details = props.products.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid == 0 ? currItem.itemId : currItem.inid) == parseInt(id)) ; });
if(details.length > 0){
if(type=='teaser'){return (details[0].string2);}
if(type=='body'){return (details[0].string3);}
return (details[0].string1);
}
return '';
}

let listDetails='';
if(numbersProducts.length > 0){
listDetails = numbersProducts.map((number, index) =>
// Correct! Key should be specified inside the array. 
<TabPanel key={'productDetails'+number.id} value={value} index={index} dir={theme.direction}>
<section className='mb-3 pageTitle fw-bold'>{getDetails((number.inid == 0 ? number.itemId : number.inid))}</section>
<section className='mb-3' dangerouslySetInnerHTML={{__html: getDetails((number.inid == 0 ? number.itemId : number.inid), 'body')}}></section>
<section className='mb-3' dangerouslySetInnerHTML={{__html: getDetails((number.inid == 0 ? number.itemId : number.inid), 'teaser')}}></section>
{parseInt(catid) == 120 ? <section className='d-flex justify-content-center m-5 p-4'><Link onClick={ () => window.topTop() } to={{ pathname: "/con/136/131/Contact us/126",}} className="secondaryMenu">
<Button sx={{ fontSize: 10, fontWeight:'bold', }} variant="outlined" size="small">Talk to us</Button></Link></section> : null}
</TabPanel>
);}

let listHosting='';
if(numbersHosting.length > 0){
listHosting = numbersHosting.map((number, index) => 
<div className='col-sm-6 col-lg-3 px-3 mb-5' key={number.id}>
<Card>
<CardActionArea>
<h5 className={productHeaderClass(index)}>{number.string1}</h5>
<CardContent className={`${(parseInt(number.inid == 0 ? number.itemId : number.inid) == parseInt(id)) ? ' activeProduct' : null}`}>
<section className='mb-3 mt-2 text-center'>
<AddToCart cart rowid={number.inid == 0 ? number.itemId : number.inid} groupid={117} type='hosting' prdname={`${number.string1}`} uom={number.string16} price={number.string4} currency={props.currency} itemId={`${number.string1}`} updateCart={props.updateCart} string1={number.fullname} string2='' string3={number.username} string4='' string5={`${number.gender}`} string6={`${number.country}`} string7={`${number.province}`} />
</section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passPrdId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</div>
);}


if(numbersServers.length > 0){
listServers = numbersServers.map((number, index) => 
<div className='col-sm-6 col-lg-3 px-3 mb-5' key={number.id}>
<Card>
<CardActionArea>
<h5 className={productServersClass(index)}>{number.string1}</h5>
<CardContent className={`${(parseInt(number.inid == 0 ? number.itemId : number.inid) == parseInt(id)) ? ' activeProduct' : null}`}>
<section className='mb-3 mt-2 text-center'>
<AddToCart cart rowid={number.inid == 0 ? number.itemId : number.inid} groupid={118} type='server' prdname={`${number.string1}`} uom={number.string16} price={number.string4} currency={props.currency} itemId={`${number.string1}`} updateCart={props.updateCart} string1={number.fullname} string2={number.address} string3={number.username} string4={number.city} />
</section>
<section className='mb-3 mt-3' dangerouslySetInnerHTML={{__html: number.string2}}></section>
<ul className='no-bullets mt-3'>
{passServId(number.inid == 0 ? number.itemId : number.inid)}
</ul>
</CardContent>
</CardActionArea>
</Card>
</div>
);}

return (
<section className='mb-4 defMrg'>
<section className='mb-5 pb-3' dangerouslySetInnerHTML={{__html: (numbersDomain.length > 0 ? numbersDomain[0].string4 : '')}}></section>

{parseInt(id) === 2125 ? 
<section className='m-5 mt-2 text-center'>


{(sessionStorage.getItem("signIn") == null || sessionStorage.getItem("signIn") == '' || sessionStorage.getItem("signIn") === null) ? <><section>

<Link onClick={ () => window.topTop() } to={{ pathname: "/acct/109/Login",}} className="secondaryMenu"><Button sx={{ fontSize: 18, p:2, mb:3, color:'#ffffff', backgroundColor:'#0A67F3', fontWeight:'bold', }} variant="outlined" >Login to Activate Free Web / Email Hosting for your account</Button></Link>

</section><section>
<Link onClick={ () => window.topTop() } to={{ pathname: "/acct/108/register",}} className="secondaryMenu">
<Button sx={{ fontSize: 12, mb:3, fontWeight:'bold', }} variant="outlined" >Don’t have account? Create one here</Button></Link></section></> : <section className='m-3 text-center'>
<Form type="form" viewid={75} formmode={1} tbl={75} /></section>}
<section className='row mb-5 mt-2 text-center'>
<section className='col-lg-6 p-4'><img loading='lazy' alt='Free Web Hosting - Full Features' title="Free Web Hosting - DTech" src={Free} className="img-fluid" /></section>
<section className='col-lg-6 p-4'><img loading='lazy' alt='Free Email Hosting - Full Features' title="Free Email Hosting - DTech" src={FreeMail} className="img-fluid" /></section>
</section></section>
 : null}

{parseInt(catid) == 117 && parseInt(id) !== 2125 ? <section className='row'>{listHosting}</section> : null}
{parseInt(catid) == 118 && parseInt(id) !== 2125 ? <section className='row'>{listServers}</section> : null}

{parseInt(catid) == 116 ? <section className='d-flex flex-column justify-content-center align-items-center m-5'>
{parseInt(id)===81 ? <section className='lead fw-bold mb-2'>Enter domain name to transfer to us</section> : null}
<section className='d-flex justify-content-center'>
<section><Form type="form" gender={parseInt(id)===81 ? 2 : 0} viewid={22} formmode={1} /></section></section>
</section> : null}

{parseInt(catid) == 116 ? <section className=''><h4 className='mb-4 fw-bold text-center'>What you get with every domain purchase</h4>
<section className='row m-5'><ProductFeatures gId={catid} col={true} free={true} /></section>
</section> : null}

{parseInt(catid) == 118 || parseInt(catid) == 117 ? <>
<h5 className='mt-5 fw-bold pb-1'>Every package includes</h5>
<section className="row consultancy p-3">
<ProductFeatures gId={catid} col={true} />
</section>
<section></section>
<section></section>
</> : null}



{parseInt(catid) == 120 ? <section className='m-5'>
<Box>
<Tabs
value={value}
onChange={handleChange}
variant="scrollable"
scrollButtons="auto"
aria-label="scrollable auto tabs example"
className='consultancy fw-bold'
>{listItems}</Tabs>{listDetails}
</Box></section> : null}
</section>
);
}


function TabPanel(props) {
const { children, value, index, ...other } = props;

return (
<div
role="tabpanel"
hidden={value !== index}
id={`full-width-tabpanel-${index}`}
aria-labelledby={`full-width-tab-${index}`}
{...other}
>
{value === index && (
<Box sx={{ p: 3 }}>
<section>{children}</section>
</Box>
)}
</div>
);
}

TabPanel.propTypes = {
children: PropTypes.node,
index: PropTypes.number.isRequired,
value: PropTypes.number.isRequired,
};

function a11yProps(index) {
return {
id: `full-width-tab-${index}`,
'aria-controls': `full-width-tabpanel-${index}`,
};
}