import React, {useEffect} from 'react';
import {useQuery, useLazyQuery} from '@apollo/client';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';

import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import HomeIcon from '@mui/icons-material/Home';
import Form from '../../components/form/form';
import {LoadingSK} from '../../components/utility/utility';
import Alert from '@mui/material/Alert';


import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";

import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
//import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
//import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';

//import Slide from '@mui/material/Slide';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import Checkbox from '@mui/material/Checkbox';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import FolderZipOutlinedIcon from '@mui/icons-material/FolderZipOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';

import Dialog from '@mui/material/Dialog';
//import DialogActions from '@mui/material/DialogActions';
//import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
//import DialogTitle from '@mui/material/DialogTitle';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
//import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

//import ListItemText from '@mui/material/ListItemText';
//import ListItemButton from '@mui/material/ListItemButton';
//import List from '@mui/material/List';
//import Divider from '@mui/material/Divider';
import Slide from '@mui/material/Slide';
import ArchiveIcon from '@mui/icons-material/Archive';


export default function FileManager(props){
var tempId='2009';
const rootDir = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'listDir',arg4:`${tempId}`,arg3:`hst${props.id}`}, fetchPolicy: "network-only" });
const [updateDir, { loading, error, data }] = useLazyQuery(DEF_SITE_LIST);


function reloadDir(){
 setFormState({ ...formState, dirList: [],  curFTPID: '', fileList: '', realAddress:`hst${props.id}`, displayAddress:'/', showList:true, });
 return;
}
const [formState, setFormState] = React.useState({ dirList:[], fileList:[], curFTPID:'', curFTPSUBID:'', realAddress:'', displayAddress:'', selectedItems:[], showList:true });

useEffect(() => { 
setFormState({ ...formState, dirList: [],  curFTPID: '', fileList: '', realAddress:`hst${props.id}`, displayAddress:'/', });
}, [formState.showList]); // Only re-run the effect if props.rowid changes



let loadingSK = <LoadingSK />;
const [expandedItems, setExpandedItems] = React.useState([]);
const handleExpandedItemsChange = (event, itemIds) => {
  setExpandedItems(itemIds); };

const handleExpandClick = () => {
  setExpandedItems((oldExpanded) =>
    oldExpanded.length === 0
      ? [
          'grid',
          'grid-community',
          'grid-pro',
          'grid-premium',
          'pickers',
          'pickers-community',
          'pickers-pro',
          'charts',
          'charts-community',
          'tree-view',
          'tree-view-community',
        ]
      : [],
  );
};


function setLastClickedItem(address,root=0){
updateDir({variables: { listid: 2, arg1:'listDir',arg2:0,arg3:address}, fetchPolicy: "cache-and-network" }); 
if(formState.realAddress != address){
setFormState({ ...formState, realAddress: address, displayAddress:updateAddress(address), selectedItems:[], });}
handleExpandClick();
rootDir.refetch();}

function setRoot(){
updateDir({variables: { listid: 2, arg1:'listDir',arg2:0,arg3:`hst${props.id}`}, fetchPolicy: "cache-and-network" }); 
setFormState({ ...formState, realAddress: `hst${props.id}`, selectedItems:[], displayAddress:'/', showList:false, dirList: [],  curFTPID: '', fileList: '', }); 
handleExpandClick(); 
rootDir.refetch();
 }

function handleCheck(e){
//let value = e.target.name;
const index = (formState.selectedItems).indexOf(e.target.value);
if(e.target.checked){ 
if(!(formState.selectedItems).includes(e.target.value)){
(formState.selectedItems).push(e.target.value); }}
else {
if (index > -1) { // only splice array when item is found
(formState.selectedItems).splice(index, 1); // 2nd parameter means remove one item only
}} }
if(rootDir.loading){loadingSK=<LoadingSK />;}
if(rootDir.data){
 loadingSK='';
if(rootDir.data.getSiteList.length > 0 && JSON.parse(rootDir.data.getSiteList[0].description) && formState.curFTPID != rootDir.data.getSiteList[0].id){
setFormState({ ...formState, dirList: JSON.parse(rootDir.data.getSiteList[0].description),  curFTPID: rootDir.data.getSiteList[0].id, fileList: JSON.parse(rootDir.data.getSiteList[0].description), realAddress:`hst${props.id}`, displayAddress:'/' });
}}
let loadingSKL;
if(loading){
loadingSKL=<LoadingSK />;
}
if(data){
loadingSKL='';  
if(data.getSiteList.length > 0 && JSON.parse(data.getSiteList[0].description)){
if(formState.curFTPSUBID != data.getSiteList[0].id){
setFormState({ ...formState, fileList: JSON.parse(data.getSiteList[0].description), curFTPSUBID: data.getSiteList[0].id,});
}}}



let curDirFolders;
if(formState.dirList.length > 0){
const dirList = formState.dirList.filter(function(currItem) { return parseInt(currItem.typeid) === 0; });
const sortFolder = dirList.sort(function(a, b){return a.title - b.title});
curDirFolders= sortFolder.map((number, index) => <TreeItem key={`${window.uuidv4()}dirlist${index}`} itemId={number.address} label={number.title}>
<ListDir id={number.address} label={props.label} /></TreeItem>);
}



let curAllList;
if(formState.dirList.length > 0){
const allList = formState.fileList.sort(function(a, b){return a.title - b.title});
curAllList= (allList.sort(function(a, b){return a.typeid - b.typeid})).map((number, index) => <tbody key={`${window.uuidv4()}dirlist${index}`}><tr><td className='editCol'><Checkbox  id={`sele${number.id}`} onChange={handleCheck} name={`sele${number.id}`} value={number.address} color="default" /></td>
<td className='editCol'>{number.typeid == 0 ? <Tooltip title="Folder" arrow={true}><FolderOpenOutlinedIcon /></Tooltip> : number.typeid == 1 ? <Tooltip title="Archive" arrow={true} ><InsertDriveFileOutlinedIcon /></Tooltip> : <Tooltip title="File" arrow={true}><InsertDriveFileOutlinedIcon /></Tooltip>}</td>
<td>{(number.title).toLowerCase()}
</td><td className='text-end'  style={{minWidth:"120px"}}>

{parseInt(number.typeid) === 2 ? <span className='mx-1 pointer'><FullScreenDialog reset={setRoot} id={props.id} rootAddress={'hst'+props.id} formid={99} title="Edit" realAddress={number.address} typeid={number.typeid} displayAddress={formState.displayAddress} type='edit' /></span> : null}

{parseInt(number.typeid) === 1 ? <span className='mx-1 pointer'><FullScreenDialog reset={setRoot} id={props.id} rootAddress={'hst'+props.id} formid={98} title="Extract" realAddress={number.address} typeid={number.typeid} displayAddress={(number.title).toLowerCase()} type='extract' /></span> : null}

<span className='mx-1 pointer'><FullScreenDialog reset={setRoot} rootAddress={'hst'+props.id} fullname={(number.title).toLowerCase()} formid={97} id={props.id} title="Rename" realAddress={number.address} displayAddress={formState.displayAddress} typeid={number.typeid} type='rename' /></span>

{parseInt(number.typeid) !== 0 ? <span className='mx-1 pointer'>
<Tooltip title="Download" arrow={true}><a target='_blank' href={`${window.apiServer}/getfile/${(number.address).replace(/\\/g, "/")}`} download><FileDownloadOutlinedIcon /></a></Tooltip></span> : null}
</td></tr></tbody>);}



return (<>
<section className='mb-2 mt-2 pb-2 pageTitle'>
<span className='px-1 pointer'>
<FullScreenDialog reset={setRoot} rootAddress={`hst${props.id}`} formid={81} title="Create Directory" realAddress={formState.realAddress} displayAddress={formState.displayAddress} type='newfolder' /></span>
<span className='px-1 pointer'><FullScreenDialog reset={setRoot} rootAddress={'hst'+props.id}  realAddress={formState.realAddress} displayAddress={formState.displayAddress} formid={82} title="Create File" type='newfile' /></span>
<span className='px-1 pointer'><FullScreenDialog reset={setRoot} rootAddress={'hst'+props.id}  formid={83} realAddress={formState.realAddress} displayAddress={formState.displayAddress} title="Upload" type='upload' /></span>

<span className='px-1 pointer'><FullScreenDialog reset={setRoot} list={formState.selectedItems} ini={Math.random()} rootAddress={'hst'+props.id} formid={84} id={props.id} title="Move" type='move' /></span>

<span className='px-1 pointer'><FullScreenDialog list={formState.selectedItems} rootAddress={'hst'+props.id} reset={setRoot} formid={85} id={props.id} title="Copy" type='copy' /></span>

<span className='px-1 pointer'><FullScreenDialog reset={setRoot} list={formState.selectedItems} rootAddress={'hst'+props.id} formid={86} id={props.id} title="Archive" type='archive' /></span>

<span className='px-1 pointer'><FullScreenDialog reset={setRoot} list={formState.selectedItems} rootAddress={'hst'+props.id} formid={87} id={props.id} title="Delete Forever" type='delete' /></span>
</section>
<section><Typography className='mb-2 mt-2 pb-2 pageTitle'>
<Tooltip title="Go to Root" arrow={true}><span className='px-2 pointer' 
onClick={() => { reloadDir(); }}><HomeIcon />  <span className='px-2'>{formState.displayAddress}</span></span></Tooltip>
</Typography></section>
{formState.showList ? 
<section className='d-flex'> 
<section className='dirList wrap'>
{loadingSK}  
  <Box><SimpleTreeView expandedItems={expandedItems}
          onExpandedItemsChange={handleExpandedItemsChange} onItemClick={(event, itemId) => setLastClickedItem(itemId)}>{curDirFolders}</SimpleTreeView></Box>
</section>
<section className='flex-grow-1 px-2'>
  {loadingSKL}
<table className="customers">{curAllList}</table></section>
</section> : null}
</>
);
}




function ListDir(props){
const rootDir = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'listDir',arg2:0,arg3:`${props.id}`}, fetchPolicy: "cache-and-network" });
const [formState, setFormState] = React.useState({
dirList:[], realAddress:'', });

let curDirFolders;  
if(rootDir && rootDir.data){
if(rootDir.data.getSiteList.length > 0 && JSON.parse(rootDir.data.getSiteList[0].description) && formState.curFTPID != rootDir.data.getSiteList[0].id){
setFormState({ ...formState, dirList: JSON.parse(rootDir.data.getSiteList[0].description),  curFTPID: rootDir.data.getSiteList[0].id, });
}}


if(formState.dirList.length > 0){
const dirList = formState.dirList.filter(function(currItem) { return parseInt(currItem.typeid) === 0; });
const sortFolder = dirList.sort(function(a, b){return a.title - b.title});
curDirFolders= sortFolder.map((number, index) => <TreeItem key={`${window.uuidv4()}dirlist${index}`} itemId={number.address} label={number.title}>
<ListDir id={number.address} label={number.title} />
</TreeItem>);} return (curDirFolders); }  



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function FullScreenDialog(props) {
const [open, setOpen] = React.useState(false);
const handleClickOpen = () => { setOpen(true); };
const handleClose = () => { setOpen(false); };

const fileContent = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'getFileContent',arg2:99,arg3:props.realAddress}, fetchPolicy: "cache-and-network" });

const [formState, setFormState] = React.useState({ dirList:[], fileList:[], curFTPID:'', curFTPSUBID:'', realAddress:'', displayAddress:'', selectedItems:[], destination:'', formid:props.formid, fileContent:'', ini:0, });

useEffect(() => { resetState();}, [props.ini]); // Only re-run the effect if props.rowid changes
  
if(fileContent && fileContent.data && fileContent.data.getSiteList.length > 0 && formState.ini == 0){ 
setFormState({ ...formState, ini:1, fileContent: fileContent.data.getSiteList[0].description });
}

function icon(id) { 
  switch(id) {
  case 'newfolder': 
  return (<CreateNewFolderOutlinedIcon />);
  case 'newfile': 
  return (<InsertDriveFileOutlinedIcon  />);
  case 'archive': 
  return (<ArchiveIcon  />);
  case 'extract': 
  return (<MenuOpenOutlinedIcon />);
  case 'move': 
  return (<DriveFileMoveOutlinedIcon />);
  case 'copy': 
  return (<CopyAllOutlinedIcon />);
  case 'upload': 
  return (<FileUploadOutlinedIcon  />);
  case 'delete':
  return (<DeleteForeverOutlinedIcon  />);
  case 'rename':
  return (<SaveAsOutlinedIcon />);
  case 'edit':
  return (<DriveFileRenameOutlineOutlinedIcon />);
  default:
  return 'Open';
  }}


var selectedItems;  
if((parseInt(props.formid) === 87 || parseInt(props.formid) === 84 || parseInt(props.formid) === 85 || parseInt(props.formid) === 86) && props.list && parseInt(props.list.length) >0){
selectedItems = (props.list).map(number =>  
<tr className="p-2" key={`${window.uuidv4()}`}>
<td>{(number.replace(/\\/g, '/')).substring((number.replace(/\\/g, '/')).indexOf('/') + 1)} </td></tr> );}

const [expandedItems, setExpandedItems] = React.useState([]);
const handleExpandedItemsChange = (event, itemIds) => {
setExpandedItems(itemIds); };
const rootDir = useQuery(DEF_SITE_LIST, {variables: { listid: 2, arg1:'listDir',arg4:`tempgen`,arg3:`${props.rootAddress}`}, fetchPolicy: "cache-and-network" });
const [updateDir, { loading, error, data }] = useLazyQuery(DEF_SITE_LIST);

let loadingSK = <LoadingSK />;
if(rootDir.data){
loadingSK='';
if(rootDir.data.getSiteList.length > 0 && JSON.parse(rootDir.data.getSiteList[0].description) && formState.curFTPID != rootDir.data.getSiteList[0].id){
setFormState({ ...formState, dirList: JSON.parse(rootDir.data.getSiteList[0].description),  curFTPID: rootDir.data.getSiteList[0].id, fileList: JSON.parse(rootDir.data.getSiteList[0].description), realAddress:`hst${props.id}`, displayAddress:'/' });
}}

const handleExpandClick = () => {
setExpandedItems((oldExpanded) =>
oldExpanded.length === 0
? [
'grid',
'grid-community',
'grid-pro',
'grid-premium',
'pickers',
'pickers-community',
'pickers-pro',
'charts',
'charts-community',
'tree-view',
'tree-view-community',
] : [],);};

function reloadDir(){
  setFormState({ ...formState, dirList: [],  curFTPID: '', fileList: '', realAddress:`hst${props.id}`, displayAddress:'/', showList:true, });
  return;
 }

function setLastClickedItem(address,root=0){
updateDir({variables: { listid: 2, arg1:'listDir',arg4:'arg',arg3:address}, fetchPolicy: "cache-and-network" }); 
if(formState.realAddress != address){
setFormState({ ...formState, realAddress: address, displayAddress:updateAddress(address), selectedItems:[], destination:address, });}
handleExpandClick();
//rootDir.refetch();
}
var curDirFolders;
function resetState(){
selectedItems=[];
setFormState({ ...formState, realAddress: '', displayAddress:'', selectedItems:[], destination:'', dirList:'' });
fileContent.refetch();
reloadDir();
curDirFolders='';
}
  

if(formState.dirList.length > 0){
const dirList = formState.dirList.filter(function(currItem) { return parseInt(currItem.typeid) === 0; });
const sortFolder = dirList.sort(function(a, b){return a.title - b.title});
curDirFolders= sortFolder.map((number, index) => <TreeItem key={`${window.uuidv4()}dirlist${index}`} itemId={number.address} label={number.title}>
<ListDir id={number.address} label={props.label} /></TreeItem>);
}
  
function onChange(newValue) {
if(formState.fileContent != newValue){
setFormState({ ...formState, fileContent:newValue });
}}

return (
<React.Fragment>
<Tooltip title={props.title ? props.title : null} arrow={true} onClick={handleClickOpen}>{props.type == 'btn' ? <Button variant="outlined">{props.label}</Button> : icon(props.type)}
</Tooltip>
<Dialog
fullScreen
open={open}
onClose={handleClose}
TransitionComponent={Transition}
>
<AppBar sx={{ position: 'relative' }}>
<Toolbar>
<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
{props.title ? props.title : null}
</Typography>
<span className='pointer p-1' onClick={ () => { resetState();  props.reset(); handleClose(); }}><CloseIcon  fontSize="small" /></span>
</Toolbar>
</AppBar>
<section className='mx-3 mb-3 mt-3'>
{[99].includes(parseInt(props.formid)) ? <>
<section className='mb-3 pageTitle lead'>File address: 
{(props.realAddress).replace(new RegExp([`${props.rootAddress}`].join('|'), 'gi'), '').replace(/\\/g, "/")} </section>
<section className='mb-3'>
<AceEditor
    mode="java"
    theme="github"
    onChange={onChange}
    name="codeeditorcontainer"
    width='100%'
    fontSize={16}
    defaultValue={formState.fileContent}
    editorProps={{ $blockScrolling: true }}
  /></section><section>
  {formState.fileContent != 'file not supported' ? <Form address={props.realAddress} rowid={props.rowid} tbl={props.formid} type="form" formmode={1} viewid={12} description={formState.fileContent} /> : null}
  </section></> : null}


{[58].includes(parseInt(props.formid)) ? <>
<section className='mx-3'>
<Typography>Choose Directory</Typography>
{loadingSK} 
<Box>
<section><Typography className='mb-2 mt-2 pb-2 pageTitle'>
<Tooltip title="Root Directory" arrow={true}><span className='px-2 pointer' 
onClick={() => { setLastClickedItem(props.rootAddress); }}><HomeIcon /><span className='px-2'>{formState.displayAddress} {formState.realAddress}</span></span></Tooltip>
</Typography></section>
<SimpleTreeView expandedItems={expandedItems}
onExpandedItemsChange={handleExpandedItemsChange} onItemClick={(event, itemId) => setLastClickedItem(itemId)}>{curDirFolders}</SimpleTreeView></Box>
{formState.destination !== '' ? 
<Form address={formState.destination} rowid={props.rowid} tbl={props.formid} zip={props.realAddress} type="form" formmode={1} viewid={12} /> : null}
</section>
</> : null}


{parseInt(props.formid) === 98 ?   
<section className='row mx-3'>
<section className='col-md-6'> 
<section><Alert className='mt-2' variant="filled" severity="info">Archive File</Alert></section>
<section><table className="customers"><tbody>
<tr><td>{props.displayAddress}</td></tr>
</tbody></table></section>
</section>
<section className='col-md-6'>
<section className='mx-3'>
<Typography>Choose destination directory</Typography>
{loadingSK} 
<Box>
<section><Typography className='mb-2 mt-2 pb-2 pageTitle'>
<Tooltip title="Root Directory" arrow={true}><span className='px-2 pointer' 
onClick={() => { setLastClickedItem(props.rootAddress); }}><HomeIcon /> <span className='px-2'>{formState.displayAddress}</span></span></Tooltip>
</Typography></section>

<SimpleTreeView expandedItems={expandedItems}
onExpandedItemsChange={handleExpandedItemsChange} onItemClick={(event, itemId) => setLastClickedItem(itemId)}>{curDirFolders}</SimpleTreeView></Box>
{formState.destination !== '' ? 
<Form address={formState.destination} rowid={props.id} province={formState.destination} tbl={props.formid} fileId={`upload${props.formid}`} zip={props.realAddress} label='Extract' type="form" formmode={1} viewid={formState.formid} /> : null}

</section>
</section>
</section> : null}








{[82,83,81].includes(parseInt(props.formid)) ? <>
<Typography className='mb-4'>
Current Directory: {props.displayAddress}
</Typography>
<Form tbl={props.formid} province={String(props.rootAddress)} zip={props.realAddress} type="form" formmode={1} viewid={props.formid} fileId={`upload${props.formid}`} />
</> : null}


{[97].includes(parseInt(props.formid)) ? <>
<Form fullname={props.fullname} address={props.realAddress} tbl={props.formid} type="form" rowid={props.id} gender={props.typeid} fileId={`upload${props.formid}`} formmode={1} viewid={props.formid} /></> : null}

{[87, 86, 85, 84].includes(parseInt(props.formid)) ? 
<>
{!props.list || parseInt(props.list.length) === 0 ? <Alert className='mt-2 mb-2' variant="filled" severity="warning">No item selected! Select item(s)</Alert> : null} 
{props.list && parseInt(props.list.length) > 0 && parseInt(props.formid) === 87 ? 
<section>
<section><Alert className='mt-2 mb-2' variant="filled" severity="warning">Selected Items: {props.list.length}</Alert></section>
<section className='mx-5'>
<table className="customers"><tbody>
{selectedItems}
</tbody></table>
</section>
<section className='mt-4 mx-5'><Form rowid={props.id} tbl={props.formid} zip={(props.list).join()} type="form" refetch={props.refetch} formmode={1} viewid={props.formid} fileId={`upload${props.formid}`} /></section>
</section> :
null}

{props.list && parseInt(props.list.length) > 0 && (parseInt(props.formid) === 86 || parseInt(props.formid) === 85 || parseInt(props.formid) === 84) ? 
<section>
<section className='mx-3'>
<section className='row'>
<section className='col-md-6'>
<section><Alert className='mt-2 mb-4' variant="filled" severity="info">Selected Items: {props.list.length}</Alert></section>
<Typography className='pageTitle mb-3 pb-1 fw-bold'>Source File(s) / Folder(s)</Typography>
<section><table className="customers"><tbody>
{selectedItems}
</tbody></table></section>
</section>
<section className='col-md-6'>
<section className='mx-3'>
<Alert className='mt-2 mb-4' variant="filled" severity="warning">If file or directory already exists, they will be overwritten</Alert>
<Typography className='pageTitle mb-3 pb-1 fw-bold'>Destination Folder</Typography>
{[86].includes(parseInt(props.formid)) ? <><Typography>The Archive will be stored in the root directory</Typography></> : null}



{[86].includes(parseInt(props.formid)) ? <>
<Form address={props.rootAddress} tbl={props.formid} zip={(props.list).toString()} label='Archive' type="form" formmode={1} viewid={formState.formid} fileId={`upload${props.formid}`} /></> : null}
{[85,84].includes(parseInt(props.formid)) ? <>
<Typography>Choose destination directory</Typography>
<section className='mb-4'>
{loadingSK}  
<Box>
<section><Typography className='mb-2 mt-2 pb-2 pageTitle'>
<Tooltip title="Root Directory" arrow={true}><span className='px-2 pointer' 
onClick={() => { setLastClickedItem(props.rootAddress); }}><HomeIcon /> <span className='px-2'>{formState.displayAddress}</span></span></Tooltip>
</Typography></section>
<SimpleTreeView expandedItems={expandedItems}
onExpandedItemsChange={handleExpandedItemsChange} onItemClick={(event, itemId) => setLastClickedItem(itemId)}>{curDirFolders}</SimpleTreeView></Box>
</section>

<section className='mt-3'>
{formState.destination !== '' ? 
<Form address={formState.destination} fileId={`upload${props.formid}`} province={formState.destination} tbl={props.formid} zip={(props.list).toString()} label={parseInt(props.formid) === 85 ? 'Copy' : parseInt(props.formid) === 84 ? 'Move' :  parseInt(props.formid) === 86 ? 'Archive' : null} type="form" formmode={1} viewid={formState.formid} /> : null}
</section></> : null}
</section>
</section>
</section> 
</section>
</section> :
null}
      
      
      </>: null}
      </section>
      </Dialog>
      </React.Fragment>
      );
}



function updateAddress(x){
let xnew =   x.replace(/\\/g, "/");
var regularExpression = /^[^/]+/;
return (xnew.replace(regularExpression, ''));}

export {FullScreenDialog}