import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {PageTitle, LoadingSK, ContactInfo, AccountSecurity, DataTable, DomainMgt, ServerMgt, HostingMgt, GetRegion, SDate1, GetUOM, TransferStatus, SDate2} from '../../components/utility/utility';
import {CustomerAcct} from '../../components/account/signup';
import { jwtDecode } from "jwt-decode";
import SyncIcon from '@mui/icons-material/Sync';

import MenuIcon from '@mui/icons-material/Menu';
import Divider from '@mui/material/Divider';
import Form from '../../components/form/form';
import AddIcon from '@mui/icons-material/Add';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import KeyIcon from '@mui/icons-material/Key';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InventoryIcon from '@mui/icons-material/Inventory';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArticleIcon from '@mui/icons-material/Article';
import PagesIcon from '@mui/icons-material/Pages';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import GroupsIcon from '@mui/icons-material/Groups';
import DomainIcon from '@mui/icons-material/Domain';
import WebIcon from '@mui/icons-material/Web';
import StorageIcon from '@mui/icons-material/Storage';
import SecurityIcon from '@mui/icons-material/Security';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import SmsIcon from '@mui/icons-material/Sms';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import LanIcon from '@mui/icons-material/Lan';
import Alert from '@mui/material/Alert';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import EmailIcon from '@mui/icons-material/Email';
import ListIcon from '@mui/icons-material/List';
import SellIcon from '@mui/icons-material/Sell';
import ImageIcon from '@mui/icons-material/Image';
import MoneyIcon from '@mui/icons-material/Money';
import WidgetsIcon from '@mui/icons-material/Widgets';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { DEF_SITE_LIST } from '../../components/gpl/gpl';
import {useQuery} from '@apollo/client';
import PublicIcon from '@mui/icons-material/Public';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

let curUser = null;
if(sessionStorage.getItem("signIn")){
curUser = jwtDecode(sessionStorage.getItem("signIn"));}

function ListNewTickets(props) { 
const getNewTickets = useQuery(DEF_SITE_LIST, { variables: { listid: 2, arg1:'138' , arg2:0, arg3:'support'}, pollInterval: 1000*60*10, fetchPolicy: "cache-and-network"});

const [formState, setFormState] = useState({ displayProductItems: '', });
function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }


let ticketDetails;
if(getNewTickets.data && getNewTickets.data.getSiteList.length > 0){
const numbersTicketDetails = getNewTickets.data.getSiteList;
ticketDetails = numbersTicketDetails.map((number, index) => <tbody key={`staffticket${number.id}`}><tr className="p-2 pointer">
<td className='editCol px-1 pointer'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`ticketdetailsCon${number.id}`)}} className='pointer' /></Tooltip></td><td><SDate1 item={number.date} /></td><td>{number.username} {number.title}</td><td>{number.province} {number.itemId}</td></tr> 
{formState.displayProductItems === `ticketdetailsCon${number.id}` ? <tr><td colSpan={4}>
<section className='mb-4 bg-white p-3 px-5'>staff response msg1={number.string1}
<TicketDetails id={Number(number.inid) === 0 ? number.itemId : number.inid} username={`${number.username}`} msg1={number.string1} refetch={getNewTickets.refetch} address={number.address} />
</section>
</td>
</tr>
: null}
</tbody>
); 
}

return (<section className='table-container'><table className='customers'>
<thead>
<tr>
<th className='editCol'>&nbsp;</th>
<th>Date</th><th>Subject</th><th>Issue Type 9999</th></tr>
</thead>{ticketDetails}</table></section>);
}


function TicketDetails(props) { 
const getItemDetails = useQuery(DEF_SITE_LIST, { variables: { listid: 138, arg1:'ticketdetails', arg2:props.id ? parseInt(props.id) : null }, fetchPolicy: "cache-first"});
const [formState, setFormState] = React.useState({ address:props.address, });
let msgs = <LoadingSK />;
if (getItemDetails.error) {msgs = `Error! ${getItemDetails.error.message}`; }
if (getItemDetails.data) { msgs = ''; }
if (getItemDetails.data && getItemDetails.data.getSiteList != 'undefined' && getItemDetails.data.getSiteList.length > 0) { 
if(formState.address != getItemDetails.data.getSiteList[0].address){
setFormState({ ...formState, address:getItemDetails.data.getSiteList[0].address, });
}
let numbers = getItemDetails.data.getSiteList; 
msgs = numbers.map(number =>
// Correct! Key should be specified inside the array. 
<section key={'ticketmsg'+number.id}>
<section className={Number(number.groupid) !== 0 ? 'ticketme' : 'ticket mb-3 fw-bold lead p-2'}> 
{Number(number.groupid) !== 0 ? <>{curUser !== null && parseInt(curUser.typeId)===0 ? 'Customer' : 'Me'}</> : 'Support staff'}
</section>   
<section className='m-2 mx-4 mb-5'>
<section dangerouslySetInnerHTML={{__html: number.string1}}></section></section>  
</section> );}


return (<section className='mb-4'>props.id {props.id}
{props.msg1 ? 'msg yes' : 'no msg'}
{props.msg1 ?    
<section className='m-2 mx-4 mb-5'>
<section dangerouslySetInnerHTML={{__html: props.msg1}}></section></section>  : null}
<section>{msgs}</section>
<section className='m-4'>
  <section className='pageTitle mt-4 mb-2'>Add correspondence</section>
<Form type="form" username={props.username} formmode={2} rowid={props.id} id={-1} gender={2} viewid={138} tbl={curUser !== null && parseInt(curUser.typeId)===0 ? 95 : 138} address={formState.address} refetch={props.refetch ? props.refetch : getItemDetails.refetch} /> 
</section></section>);
}

function icon(id) { 
switch(parseInt(id) ? parseInt(id) : 0) {
case 131: 
return (<PagesIcon sx={{ mr: 1, }} />);
case 132: 
case 110: 
return (<ArticleIcon sx={{ mr: 1, }} />);
case 133: 
return (<InventoryIcon sx={{ mr: 1, }} />);
case 138: 
return (<ContactSupportIcon sx={{ mr: 1, }} />);
case 136: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 137: 
return (<GroupsIcon sx={{ mr: 1, }} />);
case 116: 
return (<DomainIcon sx={{ mr: 1, }} />);
case 117: 
return (<WebIcon sx={{ mr: 1, }} />);
case 118: 
return (<StorageIcon sx={{ mr: 1, }} />);
case 119: 
return (<SecurityIcon sx={{ mr: 1, }} />);
case 120: 
return (<HandshakeIcon sx={{ mr: 1, }} />);
case 38: 
return (<ReceiptIcon sx={{ mr: 1, }} />);
//case 142: 
//return (<CreditCardIcon sx={{ mr: 1, }} />);
case 141:
case 142: 
return (<PaymentsIcon sx={{ mr: 1, }} />);
case 143: 
return (<EmailIcon sx={{ mr: 1, }} />);
case 62: 
return (<PublicIcon sx={{ mr: 1, }} />);
case 61: 
return (<StorageIcon sx={{ mr: 1, }} />);
case 60: 
return (<LanIcon sx={{ mr: 1, }} />);
case 4: 
return (<KeyIcon sx={{ mr: 1, }} />);
case 5: 
return (<PermContactCalendarIcon sx={{ mr: 1, }} />);
case 11: 
return (<ListIcon sx={{ mr: 1, }} />);
case 6: 
return (<SellIcon sx={{ mr: 1, }} />);
case 8: 
return (<ImageIcon sx={{ mr: 1, }} />);
case 7: 
return (<WidgetsIcon sx={{ mr: 1, }} />);
case 145: 
return (<MenuIcon sx={{ mr: 1, }} />);
case 24: 
return (<ContactMailIcon sx={{ mr: 1, }} />);
case 23: 
return (<AccountTreeIcon sx={{ mr: 1, }} />);
case 41: 
return (<SmsIcon sx={{ mr: 1, }} />);
case 40: 
return (<LocalPostOfficeIcon sx={{ mr: 1, }} />);
default:
return null;
}}

let myPayments;
function getProductTotal(x,y){
let total = x.filter(function(currItem) { return parseInt(currItem.tbl) == parseInt(y); });
if(total.length>0){return `(${total.length})`;} else {return '';}
}


function a11yProps(index) { 
return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}`,}; }

function CustomTabPanel(props) {
const { children, value, index, ...other } = props;
return (
<div
role="tabpanel"
hidden={value !== index}
id={`simple-tabpanel-${index}`}
aria-labelledby={`simple-tab-${index}`}
{...other} >
{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
</div>); }

export default function Myacct(props) { 
let { itemId, mode, id, tbl } = useParams();
const [formState, setFormState] = useState({ display: '', displayProductItems: '', });
let buildForm = '';
const { state } = useLocation();
function displayProductItems(x){ setFormState({ ...formState, displayProductItems: (formState.displayProductItems == x ? '' : x) }); }

 
const getOwnData = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'myproducts' }, fetchPolicy: "cache-and-network" }); 
 
const getPayments = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'mypayments' }, fetchPolicy: "cache-and-network" });
const getTickets = useQuery(DEF_SITE_LIST, {variables: { listid: 138, arg1:'ticketheader'}, pollInterval: 1000*60*10, fetchPolicy: "network-only" }); 
const customerServers = useQuery(DEF_SITE_LIST, {variables: { listid: 60, arg1:''}, fetchPolicy: "network-only" }); 
const getInputValues = useQuery(DEF_SITE_LIST, {variables: { listid: (mode == 'editdata' ? 2 : 1), arg1:String(tbl), arg2:parseInt(itemId), arg3:'getcontent' }, fetchPolicy: "cache-and-network" }); 

if(getInputValues.data && getInputValues.data.getSiteList.length > 0){ 
buildForm = (<>
{mode == 'editdata' ? 2 : 1} 
<Form type="form" viewid={mode == 'editdata' ? 2 : 1} 
formmode={2} 
fullname={getInputValues.data.getSiteList[0].fullname}
gender={getInputValues.data.getSiteList[0].gender}
username={getInputValues.data.getSiteList[0].username}
frmtype={2}
formid={2}
status={getInputValues.data.getSiteList[0].status}
domain={getInputValues.data.getSiteList[0].domain}
description={getInputValues.data.getSiteList[0].description}
name={getInputValues.data.getSiteList[0].name}
password={getInputValues.data.getSiteList[0].password}
confirmPassword={getInputValues.data.getSiteList[0].confirmPassword}
address={getInputValues.data.getSiteList[0].address}
city={getInputValues.data.getSiteList[0].city}
province={getInputValues.data.getSiteList[0].province}
zip={getInputValues.data.getSiteList[0].zip}
country={getInputValues.data.getSiteList[0].country}
itemstatus={getInputValues.data.getSiteList[0].itemstatus}
rowid={(getInputValues.data.getSiteList[0].inid == 0) ? getInputValues.data.getSiteList[0].itemId : getInputValues.data.getSiteList[0].inid}
tbl={getInputValues.data.getSiteList[0].tbl}
phone={getInputValues.data.getSiteList[0].phone}
title={getInputValues.data.getSiteList[0].title}
id={getInputValues.data.getSiteList[0].itemId}
planid={getInputValues.data.getSiteList[0].planid}
uuid={getInputValues.data.getSiteList[0].uuid}
total={getInputValues.data.getSiteList[0].total}
planstate={getInputValues.data.getSiteList[0].planstate}

string1={getInputValues.data.getSiteList[0].string1}
string2={getInputValues.data.getSiteList[0].string2}
string3={getInputValues.data.getSiteList[0].string3}
string4={getInputValues.data.getSiteList[0].string4}
string5={getInputValues.data.getSiteList[0].string5}
string6={getInputValues.data.getSiteList[0].string6}
string7={getInputValues.data.getSiteList[0].string7}
string8={getInputValues.data.getSiteList[0].string8}
string9={getInputValues.data.getSiteList[0].string9}
string10={getInputValues.data.getSiteList[0].string10}

string11={getInputValues.data.getSiteList[0].string11}
string12={getInputValues.data.getSiteList[0].string12}
string13={getInputValues.data.getSiteList[0].string13}
string14={getInputValues.data.getSiteList[0].string14}
string15={getInputValues.data.getSiteList[0].string15}
string16={getInputValues.data.getSiteList[0].string16}
string17={getInputValues.data.getSiteList[0].string17}
string18={getInputValues.data.getSiteList[0].string18}
string19={getInputValues.data.getSiteList[0].string19}



string20={getInputValues.data.getSiteList[0].string20}
string21={getInputValues.data.getSiteList[0].string21}
string22={getInputValues.data.getSiteList[0].string22}
string23={getInputValues.data.getSiteList[0].string23}
string24={getInputValues.data.getSiteList[0].string24}
string25={getInputValues.data.getSiteList[0].string25}
string26={getInputValues.data.getSiteList[0].string26}
string27={getInputValues.data.getSiteList[0].string27}


 /></>);
 }
 let cusServers;
 let awaitingSetup;
 if(customerServers.data){
  const listCustomerServers = customerServers.data.getSiteList.filter(function(currItem) { return (currItem.fullname !== '' && currItem.phone !== '' && currItem.phone !== null && currItem.fullname !== null); });
  const listAwaitingSetup = customerServers.data.getSiteList.filter(function(currItem) { return (currItem.fullname != '' && currItem.fullname != null && (currItem.phone == '' || currItem.phone == null));  });

cusServers = listCustomerServers.map((number, index) => <tr className="p-2 pointer" key={`cusserversets${index}${number.id}`}>
<td className='editCol px-1 pointer'>&#9998;</td><td>{number.fullname}</td><td>{number.phone}</td><td><GetUOM id={2} arg1='UOM' arg2={number.gender} /></td><td><GetUOM id={2} arg1='UOM' arg2={number.zip} /></td></tr>);


awaitingSetup = listAwaitingSetup.map((number, index) => <tbody key={`cusserversets${number.id}`}><tr className="p-2 pointer">
<td className='editCol px-1 pointer'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { updateDisplay(`awaitingSetup${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.fullname}</td><td><GetUOM id={2} arg1='UOM' arg2={number.gender} /></td><td className='sentence'><GetRegion id={number.zip} /></td></tr>

{formState.display === `awaitingSetup${number.id}` ? <tr><td colSpan={4}>
<section className='bg-white p-3'>
<Form type="form" region={number.zip} string1={parseInt(number.gender) == -100 ? 'Administrator' : parseInt(number.gender) == -200 ? 'root' : ''} formmode={1} rowid={number.inid == 0 ? number.itemId : number.inid} planid={number.inid == 0 ? number.itemId : number.inid} viewid={79} refetch={customerServers.refetch} /> 
</section>
</td></tr> : null}
</tbody>
);
 }
let myTickets='';
if(getTickets.data && getTickets.data.getSiteList.length > 0){
const numbersTickets = getTickets.data.getSiteList.filter(function(currItem) { return (parseInt(currItem.inid) === 0); }).sort(function(a, b){return a.status - b.status});

myTickets = numbersTickets.map((number, index) => <tbody key={`b209ssssTickets${index}${number.id}`}>
<tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { displayProductItems(`ticketdetailsCon${number.id}`)}} className='pointer' /></Tooltip></td>
<td><SDate1 item={number.date} /> 1</td>
<td>{number.username} 2</td>
<td>{number.string1} 4 {number.string27} </td>
</tr>

{formState.displayProductItems === `ticketdetailsCon${number.id}` ? <tr><td colSpan={5}>
<section className='mb-4 bg-white p-3 px-5'> 
<section className='ticketme'>Me id={Number(number.inid) === 0 ? number.itemId : number.inid}</section>   
<section className='m-2 mx-4 mb-5'><section dangerouslySetInnerHTML={{__html: number.string27}}></section>
</section> Staff
<TicketDetails by='customer' id={Number(number.inid) === 0 ? number.itemId : number.inid}  username={`${number.username}`} refetch={getTickets.refetch} />
</section>
</td>
</tr>
: null}
</tbody>
);
}

const numbersPrimary = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 115 && currItem.inid != 120 && currItem.itemId != 120; });
const numbersAdmin = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 129; });
const numbersBilling = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 3; });
const numbersAccountSettings = props.menu.getSiteList.filter(function(currItem) { return parseInt(currItem.planid) == 144; });
const numbersMyHosting = getOwnData.data.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 117; });
const numbersMyDomains = getOwnData.data.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 116; });
const numbersMyServers = getOwnData.data.getSiteList.filter(function(currItem) { return parseInt(currItem.tbl) == 118; });
const [selectedIndex, setSelectedIndex] = React.useState(1);
const handleListItemClick = (
event: React.MouseEvent<HTMLDivElement, MouseEvent>,
index: number, ) => { setSelectedIndex(index); };
const [expanded, setExpanded] = React.useState(false);
const handleChange = (panel) => (event, isExpanded) => {
setExpanded(isExpanded ? panel : false);
};

function updateDisplay(x){
	setFormState({ ...formState, display: (formState.display == x ? '' : x)});
	}

const [value, setValue] = React.useState(0);
const handleChangeTab = (event, newValue) => { setValue(newValue); };
function resetDisplay(newValue){setValue(newValue);}

let adminMenu = numbersAdmin.map((number, index) => <Link key={`b933${number.id}numbe911nnmin`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid)); resetDisplay(0); }}> {icon(number.inid == 0 ? number.itemId : number.inid)}<ListItemText className='d-none d-lg-block' primary={`${number.title}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myProducts = numbersPrimary.map((number, index) => <Link key={`${number.id}numbe${window.uuidv4()}Primary`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText className='d-none d-lg-block' primary={`${number.title} ${getProductTotal((props.ownData.getSiteList),(number.inid == 0 ? number.itemId : number.inid))}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myBilling = numbersBilling.map((number, index) => <Link key={`${number.id}nu891mbersBilling`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'> <ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText className='d-none d-lg-block' primary={`${number.title}`} /></ListItemButton></ListItem><Divider />
</Link>);

let myAccountSettings = numbersAccountSettings.map((number, index) => <Link key={`${number.id}numbersAccount${window.uuidv4()}Settings`} onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/${number.inid == 0 ? number.itemId: number.inid}/${number.title}`, }}  state={{ pageTitle : number.title }} className='sideMenu'><ListItem disablePadding dense ><ListItemButton selected={selectedIndex === (number.inid == 0 ? number.itemId : number.inid)} onClick={(event) => { handleListItemClick(event, (number.inid == 0 ? number.itemId : number.inid));  resetDisplay(0);}}> {icon(number.inid == 0 ? number.itemId : number.inid)} 
<ListItemText className='d-none d-lg-block' primary={`${number.title}`} /></ListItemButton></ListItem><Divider />
</Link>);




useEffect(() => { 
if(props.menurefetch && props.ownDataRefetch && (parseInt(itemId) === 116 || parseInt(itemId) === 117 || parseInt(itemId) === 118)){
props.menurefetch();
props.ownDataRefetch();
}
if(customerServers && parseInt(itemId) === 60){ customerServers.refetch();}
}, [itemId]); // Only re-run the effect if props.rowid changes


let myDomains='';
if(numbersMyDomains.length > 0){
myDomains = numbersMyDomains.map((number, index) => <tbody key={`myDomainList12sd${number.id}`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { updateDisplay(`myDomainList${number.id}`)}} className='pointer' /></Tooltip></td><td>{number.province}</td><td><SDate2 item={number.domain} /></td><td><SDate1 item={number.expiry} /> </td></tr>
{formState.display === `myDomainList${number.id}` ? <tr><td colSpan={5}>
{parseInt(number.isready) === 1 ? 
<section>
{parseInt(number.gender)===2 ? 
<section className='m-3 mx-5'>
<h5 className='pageTitle fw-bold mb-2'>Transfer-In</h5>
<section>
<ol style={{lineHeight: 3,}}>
<li><strong>Unlock the Domain:</strong> Log into your current domain registrar and unlock the domain</li>
<li><strong>Disable Privacy Protection:</strong> If privacy protection is enabled for the domain, disable it so the transfer request can be processed</li>

<li><strong>Obtain Authorization Code:</strong> Also known as an EPP code or transfer key, you need this code from your current registrar to authorize the transfer</li>

<li><strong>Initiate the transfer:</strong> Enter the authorization code below and submit to start the transfer process
<br />
<TransferStatus id={Number(number.inid) == 0 ? number.itemId : number.inid} />
<Form type="form" viewid={55} rowid={parseInt(number.inid) === 0 ? number.itemId : number.inid} tbl={104} fullname={number.province} btnTitle='Submit' placeholder='Authorization / EPP Code' formmode={1} /></li>

<li><strong>Confirm the Transfer:</strong> The current registrar will typically send an email to the domain's administrative contact to confirm the transfer. You need to approve this request</li>

<li><strong>Wait for Transfer Completion:</strong> The transfer process may take up to 5-7 days to complete, depending on the registrars involved. Some registrars allow you to expedite this process</li>

<li><strong>Domain Settings Updates:</strong> Once the transfer is complete, ensure all domain settings (such as DNS records) are correctly configured</li>
</ol>
</section>
</section> : null}
{parseInt(number.gender)!=2 ? <DomainMgt title={number.province} menu={props.menu} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} /> : null}
</section> : <section><Alert className='mt-2 mb-2' variant="filled" severity="info">Pending Setup</Alert></section>}
</td></tr> : null}</tbody>);}

let myHosting;
if(numbersMyHosting.length > 0){
myHosting = numbersMyHosting.map((number, index) => <tbody key={`dd45${number.id}myHostingsdf`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon className='pointer' onClick={() => { updateDisplay(`${number.id}myHostingsdf`)}} /></Tooltip></td><td>
{number.fullname === ''  || number.fullname === 'null' || number.fullname === null ? number.title : number.fullname}</td><td><SDate2 item={number.domain} /></td><td><SDate1 item={number.expiry} /></td></tr>
{formState.display === `${number.id}myHostingsdf` ? <tr><td colSpan={5}>
<section>
{number.fullname === ''  || number.fullname === 'null' || number.fullname === null ? <section className='bg-white p-4'><Form refetch={props.ownDataRefetch} type="form" viewid={59} rowid={parseInt(number.inid) === 0 ? number.itemId : number.inid} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} formmode={1} /></section> : <HostingMgt ownDataRefetch={props.ownDataRefetch} menurefetch={props.menurefetch} title={number.fullname === ''  || number.fullname === 'null' || number.fullname === null ? number.province : number.fullname} menu={props.menu} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} featuresRefetch={props.featuresRefetch} productFeatures={props.productFeatures} productsDetails={numbersMyHosting} />}</section>
</td></tr> : null}</tbody>);}



if(getPayments.data){
const numbersMyPayments = getPayments.data.getSiteList;//.filter(function(currItem) { return parseInt(currItem.tbl) === 31; });
if(numbersMyPayments.length > 0){
myPayments = numbersMyPayments.map((number, index) => <tbody key={`myp${number.id}payments`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon className='pointer' onClick={() => { updateDisplay(`myp${number.id}payments`)}} /></Tooltip></td><td><SDate1 item={number.date} /> </td><td>{number.zip}</td><td>${(parseFloat(formater(number.province))).toFixed(2)}</td></tr>
{formState.display === `myp${number.id}payments` ? <tr><td colSpan={5}>
<GetPaymentDetails ownDataRefetch={props.ownDataRefetch} id={number.zip} details={props.ownData.getSiteList} />
</td></tr> : null}
</tbody>);}}

let myServers='';
if(numbersMyServers.length > 0){
myServers = numbersMyServers.map((number, index) => <tbody key={`${number.id}myServerslistk4kd`}><tr>
<td className='editCol'><Tooltip title="Manage" arrow={true}><MoreVertIcon onClick={() => { updateDisplay(`${number.id}myServerslistk4kd`)}} className='pointer' /></Tooltip></td><td>
{number.fullname === ''  || number.fullname === 'null' || number.fullname === null ?
number.title : number.fullname}</td><td><SDate2 item={number.domain} /></td><td><SDate1 item={number.expiry} /></td></tr>
{formState.display === `${number.id}myServerslistk4kd` ? <tr><td colSpan={5}>
<section>{number.fullname === ''  || number.fullname === 'null' || number.fullname === null ? <section className='bg-white p-4'><Form refetch={props.ownDataRefetch}  type="form" viewid={46} rowid={parseInt(number.inid) === 0 ? number.itemId : number.inid} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} formmode={1} /></section> : <ServerMgt ownDataRefetch={props.ownDataRefetch} title=
{number.fullname === ''  || number.fullname === 'null' || number.fullname === null ?
number.province : number.fullname} id={parseInt(number.inid) === 0 ? number.itemId : number.inid} productFeatures={props.productFeatures} menu={props.menu} productsDetails={numbersMyServers} />}</section>
</td></tr> : null}</tbody>);}


function getHeader(x){
switch(parseInt(x) ? parseInt(x) : 0) {
case 143: 
return (<thead><tr>
<th className='editCol'>&nbsp;</th><th>Created</th><th>Subject</th><th>Issue type</th></tr></thead>);
case 141: 
return (<thead><tr><th className='editCol'>&nbsp;</th><th>Date</th><th>Invoice ID</th><th>Amount</th></tr></thead>);
case 139: 
return (<thead><tr><th className='editCol'>&nbsp;</th><th>Date</th><th>Invoice ID</th><th>Amount</th><th><center>Status</center></th></tr></thead>);
//case 140: 
case 120:
case 118:
case 116: 
case 117: 
return (<thead><tr>
<th className='editCol'>&nbsp;</th><th>Title</th><th>Created</th><th>Expires</th></tr></thead>);
default:
return null;
}}

return (
<section className='mb-5'>
<PageTitle title='My Account' />
<section className='d-flex'>
<section className='sideBar'>  
<List component="nav" aria-label="secondary mailbox folder">
<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel3bh-content"
id="panel3bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<span className='px-3 d-block d-lg-none'><ManageAccountsIcon sx={{ mr: 1, }} /></span>
<Typography className='fw-bold px-3 d-none d-lg-block' sx={{ width: '100%', fontSize:15, }}>
<ManageAccountsIcon sx={{ mr: 1, }} />Account Settings
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myAccountSettings}</AccordionDetails></Accordion>



{curUser && curUser.typeId == 0 ? <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1bh-content"
id="panel1bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<span className='px-3 d-block d-lg-none'><AdminPanelSettingsIcon sx={{ mr: 1, }} /></span>
<Typography className='d-none d-lg-block fw-bold px-3' sx={{ width: '100%', fontSize:15, }}>
<AdminPanelSettingsIcon sx={{ mr: 1, }} />Admin Tasks
</Typography>
</AccordionSummary>
<AccordionDetails 	    
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>
{adminMenu}
</AccordionDetails>
</Accordion> : null}

<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel2bh-content"
id="panel2bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<span className='px-3 d-block d-lg-none'><ManageAccountsIcon sx={{ mr: 1, }} /></span>
<Typography className='fw-bold px-3 d-none d-lg-block' sx={{ width: '100%', fontSize:15, }}>
<Inventory2Icon sx={{ mr: 1, }} />My Products &amp; Services 
</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myProducts}


{curUser && parseInt(curUser.typeId) !== 0 ? 
<Link onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/143/Tickets`, }}  state={{ pageTitle : 'Tickets' }} className='sideMenu'><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === 143} onClick={(event) => { handleListItemClick(event, (143));  resetDisplay(0);}}> {icon(143)} 
<ListItemText primary='Tickets' /></ListItemButton></ListItem><Divider />
</Link> : null}
</AccordionDetails>
</Accordion>



<Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel4bh-content"
id="panel4bh-header"
sx={{ backgroundColor:'#E7EAEE', m:0, p:0, }}
>
<span className='px-3 d-block d-lg-none'><MoneyIcon sx={{ mr: 1, }} /></span>
<Typography className='fw-bold px-3 d-none d-lg-block' sx={{ width: '100%', fontSize:15, }}>
<MoneyIcon sx={{ mr: 1, }} />Billing 
</Typography>
</AccordionSummary>
<AccordionDetails
sx={{ backgroundColor:'#F2F4F7', m:0, p:0, }}
>{myBilling}</AccordionDetails>
</Accordion>
</List>






</section>
<section className='flex-grow-1 mx-3 pt-2'>
{mode == 'editdata' || mode == 'editfield' ? <>
<Typography className='innerPageTitle p-3 fw-bold'>Edit </Typography>
{buildForm}
</> : <> 
<section className='innerPageTitle p-3 fw-bold'>
<span className="d-flex align-items-center">
<span className="">{typeof state == 'undefined' || state == null ? null : typeof state.pageTitle == 'undefined' || state.pageTitle == null ? null : state.pageTitle}</span>
<span className="flex-fill d-flex flex-row-reverse align-items-center">
{(parseInt(itemId) === 116 || parseInt(itemId) === 117 || parseInt(itemId) === 118) ? <span className='px-2'>
{getOwnData.loading ? <LoadingSK /> : <SyncIcon onClick={(event) => {  getOwnData.refetch()}} className='pointer' />}
</span> : null}
{curUser && parseInt(curUser.typeId) !== 0 && parseInt(itemId) === 143 ? 
<><Link onClick={ () => window.topTop() } to={{ pathname: `/acct/114/myacct/138/143/Support Tickets`, }}  state={{ pageTitle : 'Create Ticket' }} className='sideMenu'><ListItem disablePadding dense
><ListItemButton selected={selectedIndex === 143} onClick={(event) => { handleListItemClick(event, (143));  resetDisplay(0);}}> <AddIcon sx={{ mr: 1, }} />  
<ListItemText primary='Create' /></ListItemButton></ListItem>
</Link></> : null}</span></span></section>
<>
{(curUser && curUser.typeId == 0 && parseInt(itemId) !== 116 && (!([116,4,5,141,117,118,120,143,60].includes(parseInt(itemId))))) ? 
<>
<section className=''>
<Box sx={{ width: '100%' }}>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
<Tab label="Add new" {...a11yProps(1)} /> 
<Tab label="Manage fields" {...a11yProps(2)} /> 
<Tab label="Import data" {...a11yProps(3)} /> 
<Tab label="Export data" {...a11yProps(4)} /> 
</Tabs>
</Box>
</Box>
</section>
<section>
<CustomTabPanel value={value} index={0}> 
{parseInt(itemId) == 138 ? <ListNewTickets /> : <>add tanle<DataTable tbl={itemId} support={false} /></>}
</CustomTabPanel>
<CustomTabPanel value={value} index={1}><>add customer {itemId} 137
{parseInt(itemId)===137 ? <CustomerAcct /> : null}
{parseInt(itemId)!==137 ? 
  <Form menu={props.menu} type="form" viewid={2} tbl={itemId} formmode={1} /> : null}</>
</CustomTabPanel>
<CustomTabPanel value={value} index={2}>
imports data {value}

<section className='row'>
<section className='col-md-6 mb-3'>table<DataTable field={true} tbl={itemId} /></section>
<section className='col-md-6 mb-3'><Form menu={props.menu} type="form" viewid={1} tbl={itemId} formmode={1} /></section>
</section>


</CustomTabPanel>
<CustomTabPanel value={value} index={3}>
imports data {value}
</CustomTabPanel>
<CustomTabPanel value={value} index={4}>
export data {value}
</CustomTabPanel>
</section></>
: null} 
</>


{[116,117,118,120,143,141].includes(parseInt(itemId))  ? 
<section className='table-container'><table className='customers'>
{getHeader(itemId)}
{parseInt(itemId) === 117 ? myHosting : null}
{parseInt(itemId) === 116 ? myDomains : null}
{parseInt(itemId) === 118 ? myServers : null}
{parseInt(itemId) === 143 ? myTickets : null}
{parseInt(itemId) === 141 ? myPayments : null}
</table></section>
: null}

{parseInt(itemId) === 4 ? <section><AccountSecurity /></section> : null}
{parseInt(itemId) === 5 ? <section><ContactInfo /></section> : null}
{parseInt(itemId) === 60 ? <section>
<section className=''>
<Box sx={{ width: '100%' }}>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
<Tab label="List" {...a11yProps(0)} />
<Tab label="Awaiting Setup" {...a11yProps(1)} /> 
</Tabs>
</Box>
</Box>
</section>

<section>
<CustomTabPanel value={value} index={0}> 
<section className='table-container'><table className='customers'>
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Title</th>
<th>I.P Address</th>
<th>Type</th>
<th>Location</th>
</tr>
</thead>
<tbody>{cusServers}</tbody>
</table></section>
</CustomTabPanel>
<CustomTabPanel value={value} index={1}>
<section className='table-container'>
<table className='customers'>
<thead><tr>
<th className='editCol'>&nbsp;</th>
<th>Title</th>
<th>Type</th>
<th>Location</th>
</tr>
</thead>
{awaitingSetup}
</table></section>
</CustomTabPanel>
</section></section> : null}
{parseInt(tbl) === 143 ? <section className='mt-2'>12345 <Form type="form" viewid={138} tbl={95} formmode={1} address={curUser && curUser.email ? curUser.email : ''} /></section> : null}</>}</section></section></section>);}

function GetPaymentDetails(props){
const getOwnData = useQuery(DEF_SITE_LIST, {variables: { listid: 114, arg1:'myproducts' }, fetchPolicy: "cache-and-network" }); 
//const details = props.details.filter(function(currItem) { return parseInt(props.id) === parseInt(currItem.zip); });

let getDetails=<LoadingSK />;
if(getOwnData.data){
getDetails=null;
if(getOwnData.data.getSiteList.length > 0){
const details = getOwnData.data.getSiteList.filter(function(currItem) { return parseInt(props.id) === parseInt(currItem.zip); });
getDetails = details.map((number, index) => <tr key={`pay${number.id}details`}><td className='editCol'>{index+1}</td>
<td>{number.province}</td><td>{number.total}</td><td><GetUOM id={2} arg1='UOM' arg2={number.planid} /></td><td>{formater(parseFloat(number.description))}</td><td>{formater(parseFloat(number.description)*parseFloat(number.total))}</td><td><SDate1 item={number.expiry} /></td></tr>);}}
return(<section className='m-2 mb-5'>
<section className='mb-1 pageTitle lead fw-bold'>
<section className="d-flex">
<section className="mr-2">Transaction Details</section> 
<section className="flex-grow-1 d-none d-md-block">
<section className="d-flex flex-row-reverse">
{getOwnData.loading ? <LoadingSK /> : <SyncIcon className='pointer ml-3' onClick={() => { getOwnData.refetch()}} />}
</section></section></section></section>
<section className='mb-5'>
<table className="customers1 mt-2 bg-white">
<thead className='bg-default'>
<tr className='bg-default'>
<th className='editCol'>&nbsp;</th><th>Item</th><th>Qty</th>
<th>UOM</th><th>Rate</th><th>Amount</th><th>Expiry</th></tr>
</thead><tbody>{getDetails}</tbody></table></section></section>
)}


function formater(x=0){
  const options = {
  style: 'decimal',  // Other options: 'currency', 'percent', etc.
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  };
  let formattedWithOptions = x.toLocaleString('en-US', options);
  return (formattedWithOptions);
  };