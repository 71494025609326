import { useState } from "react";
import { useMutation, gql } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Input }  from '../../components/form/form';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Alert from '@mui/material/Alert';
 

const SIGNUP = gql`mutation SetUp($username: String, $usertype: String) { 
setUp(username: $username, usertype:$usertype) { msg, status } } `;
  
export default function SignUp(props){
  const [formState, setFormState] = useState({
    username: '',
    usertype: '3',
	setUpSuccess: false,
  });

  const [createUser, { error, data, loading }] = useMutation(SIGNUP, {
    variables: formState, fetchPolicy: "no-cache"
  });
 
  var info = null; 

  if (loading){ info = <div className="alert alert-info" role="alert">Creating Account, please wait……………</div>; }
  if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};
  if (data) {
  const numbers = data.setUp; 
  if(numbers[0].status === 1){
if(formState.setUpSuccess === false){setFormState({...formState, setUpSuccess: true });}
info = <div className="alert alert-success" role="alert">{numbers[0].msg}</div>;}
  else {info = <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={createMarkup(numbers[0].msg)}></div>;}}

function createMarkup(text) { return {__html: text}; }
function handleChange (e){
const name = e.target.name
setFormState({
...formState,
[name]: e.target.value
})
}

let setUpFrm = (<section>
               <section className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
                  <Input name="username" type="email" placeholder="Useremail / Username" value={formState.username} 
                    handleChange={handleChange} required={true} />
                </section>
                
                <section className="mb-3 text-center"> <Link to={{ pathname: "/con/476/131/Terms and Legal Agreements/123", }}  state={{ pageTitle : 'Terms and Legal Agreements' }} className="navLinkSide">
    Terms amd Conditions of Service</Link> </section>
                <section className="card-title centerText">               
                  <Input name="continueBtn" className="btn btn-success form-control" type="submit" >
                  I agree to Terms of Service, Continue
                  </Input>
                </section>
				  <section>
                      <Link to="/acct/109/Login" className="btn btn-light form-control">
                      Return to Login
                      </Link>
                      </section>  
				</section>);
  
return (
 <section className='d-flex align-items-center justify-content-center'> 
<section className='loginCon'> <form name="frmdata" id="frmdata"  onSubmit={(e) => { e.preventDefault(); createUser(); }} >
<main className="bg-white" id="mainLogInFrm">
            <section className="card bg-white">
{info}
<article className="card bg-white">
<section className="card-header frmTitle centerText">
Create Account
</section>
<section className="card-body p-4">
{(typeof formState.setUpSuccess !== 'undefined' && !formState.setUpSuccess) ? setUpFrm :  <section>
<Link state={{ pageTitle : 'Set Password' }} to="/acct/112/password" className="btn btn-light form-control">Set Password</Link></section>  }
</section></article></section></main></form></section></section>);}



function CustomerAcct(props){
  const [formState, setFormState] = useState({
    username: '',
    usertype: '3',
	setUpSuccess: false,
  });

  const [createUser, { error, data, loading }] = useMutation(SIGNUP, {
    variables: formState, fetchPolicy: "no-cache"
  });
 
  var info = null; 

  if (loading){ info = <div className="alert alert-info" role="alert">Creating Account, please wait……………</div>; }
  if (error) {info = <div className="alert alert-danger" role="alert">{error.message}</div>};
  if (data) {
  const numbers = data.setUp; 
  if(numbers[0].status === 1){
if(formState.setUpSuccess === false){
  setFormState({...formState, setUpSuccess: true });}
info = <div className="alert alert-success" role="alert">Account created</div>;}
  else {info = <div className="alert alert-danger" role="alert" dangerouslySetInnerHTML={createMarkup(numbers[0].msg)}></div>;}}

function createMarkup(text) { return {__html: text}; }
function handleChange (e){
const name = e.target.name
setFormState({
...formState,
[name]: e.target.value
})
}

let setUpFrm = (<section>
               <section className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1"><AlternateEmailIcon /></span>
                  <Input name="username" type="email" placeholder="Useremail / Username" value={formState.username} 
                    handleChange={handleChange} required={true} />
                </section>
                <section className="card-title centerText">               
                  <Input name="continueBtn" className="btn btn-success form-control" type="submit" >
                  Save
                  </Input>
                </section>
				  <section>
                      </section>  
				</section>);
  
return (
 <section className='d-flex align-items-center justify-content-center'> 
<section className='loginCon'> <form name="frmdata" id="frmdata"  onSubmit={(e) => { e.preventDefault(); createUser(); }} >
<main className="bg-white" id="mainLogInFrm">
            <section className="card bg-white">
{info}
<article className="card bg-white">
<section className="card-body p-4">
{(typeof formState.setUpSuccess !== 'undefined' && !formState.setUpSuccess) ? <>
<section className="fw-bold pageTitle mb-2">
Create Account</section>{setUpFrm}
</> :  
<Alert variant="filled" severity="info">Account created, activation and password creation instructions has been send to the email</Alert> }
</section>
</article>
</section>
</main>
</form>
   </section>
</section>
 
  );
 
    
}

export {CustomerAcct}